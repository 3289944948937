import styled from 'styled-components';

export const InstructionVidOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InstructionCard = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: transparent;
  padding: 6rem 1rem;
  border-radius: 0.5rem;
  z-index: 3;
  @media (min-width: 822px) {
    margin-top: 2rem;
    /* width: 70%; */
  }
  @media (min-width: 1350px) {
    margin-top: 2.5rem;
  }
`;

export const VideoHolder = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  padding-right: 0;
  height: 0;
  overflow: hidden;
`;

export const InstructionVidCardCloseHolder = styled.div`
  position: relative;
  z-index: 4;
`;

export const InstructionVideo = styled.video`
  width: 90%;
  z-index: 1000;
`;
