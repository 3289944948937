import React, { Fragment, useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link, NavLink } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Logo from '../../assets/logo3.png';
import WalletButton from '../WalletButton';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import { MobileMenu, DesktopMenu } from './NavigationStyles';
import { useDApp } from '../../contexts/web3';
import { useLanguage } from '../../contexts/LanguageContext';

const drawerWidth = 260;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  background: '#fff',
  paddingTop: '5px',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function Navigation(props) {
  const { account, disconnect } = useDApp();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { language, getLanguageContent } = useLanguage();
  const [content, setContent] = useState(getLanguageContent('NavTrans'));

  useEffect(() => {
    setContent(getLanguageContent('NavTrans'));
  }, [language]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  async function disconnectWallet() {
    await disconnect();
  }

  const handleHoverLink = (e) => {
    e.target.style.transform = 'scale(1.05)';
  };

  const handleHoverLinkLeave = (e) => {
    e.target.style.transform = 'scale(1)';
  };

  const aboutLink = '#about';
  const faqLink = '#faqs';

  const addPadding = {
    paddingRight: open ? '17px!important' : '0px',
  };

  return (
    <>
      <Box sx={{ display: 'flex' }} id='back-to-top-anchor'>
        <CssBaseline />
        <AppBar
          position='fixed'
          open={open}
          inputProps={{ disableScrollLock: true }}
        >
          <MobileMenu>
            <Toolbar>
              <IconButton
                color='secondary'
                aria-label='open drawer'
                onClick={handleDrawerOpen}
                edge='start'
                sx={{
                  mr: 2,
                  ...(open && { display: 'none' }),
                  fontSize: 65,
                }}
              >
                <MenuIcon style={{ color: '#f08213', fontSize: '2.5rem' }} />
              </IconButton>
              <Typography
                variant='h6'
                noWrap
                component='div'
                style={{ marginLeft: '15px' }}
              >
                <Link to='/' className='brand-logo' style={{ width: '50px' }}>
                  {/* <Logo /> */}
                  <img
                    src={Logo}
                    style={{ width: '100px' }}
                    alt='I Will Fund Logo'
                  />
                </Link>
              </Typography>
            </Toolbar>
          </MobileMenu>
          <DesktopMenu>
            <Typography variant='h3' component='div'>
              <Link to='/' className='brand-logo' style={{ marginTop: '-3px' }}>
                {/* <Logo /> */}
                <img
                  src={Logo}
                  style={{ width: '100px' }}
                  alt='I Will Fund Logo'
                />
              </Link>
            </Typography>

            <Box>
              <Button>
                <NavLink
                  to='/'
                  style={{
                    letterSpacing: '2px',
                    fontSize: '1rem',
                    fontWeight: '500',
                    marginTop: '-3px',
                    color: '#f08213',
                    textDecoration: 'none',
                  }}
                  onMouseEnter={handleHoverLink}
                  onMouseLeave={handleHoverLinkLeave}
                >
                  {content.home}
                </NavLink>
              </Button>
              <Button>
                <NavLink
                  to='/campaigns'
                  style={{
                    letterSpacing: '2px',
                    fontSize: '1rem',
                    fontWeight: '500',
                    marginTop: '-3px',
                    color: '#f08213',
                    textDecoration: 'none',
                  }}
                  onMouseEnter={handleHoverLink}
                  onMouseLeave={handleHoverLinkLeave}
                >
                  {content.campaigns}
                </NavLink>
              </Button>
              <Button>
                <NavLink
                  to='/create-campaign'
                  style={{
                    letterSpacing: '2px',
                    fontSize: '1rem',
                    fontWeight: '500',
                    marginTop: '-3px',
                    color: '#f08213',
                    textDecoration: 'none',
                  }}
                  onMouseEnter={handleHoverLink}
                  onMouseLeave={handleHoverLinkLeave}
                >
                  {content.createCampaign}
                </NavLink>
              </Button>
              <Button>
                <NavLink
                  to='/organization'
                  style={{
                    letterSpacing: '2px',
                    fontSize: '1rem',
                    fontWeight: '500',
                    marginTop: '-3px',
                    color: '#f08213',
                    textDecoration: 'none',
                  }}
                  onMouseEnter={handleHoverLink}
                  onMouseLeave={handleHoverLinkLeave}
                >
                  {content.organizations}
                </NavLink>
              </Button>
              <Button>
                <HashLink
                  to={`/${aboutLink}`}
                  style={{
                    letterSpacing: '2px',
                    fontSize: '1rem',
                    fontWeight: '500',
                    marginTop: '-3px',
                    color: '#f08213',
                    textDecoration: 'none',
                  }}
                  onMouseEnter={handleHoverLink}
                  onMouseLeave={handleHoverLinkLeave}
                >
                  {content.about}
                </HashLink>
              </Button>
              <Button>
                <HashLink
                  to={`/${faqLink}`}
                  style={{
                    letterSpacing: '2px',
                    fontSize: '1rem',
                    fontWeight: '500',
                    marginTop: '-3px',
                    color: '#f08213',
                    textDecoration: 'none',
                  }}
                  onMouseEnter={handleHoverLink}
                  onMouseLeave={handleHoverLinkLeave}
                >
                  {content.faqs}
                </HashLink>{' '}
                <div className='language-select'>
                  <select
                    style={{
                      marginLeft: '5px',
                      marginTop: '-3px',
                      border: '1px solid darkorange',
                      borderRadius: '5px',
                      padding: '5px',
                      fontSize: '1rem',
                      fontWeight: '500',
                      color: '#f08213',
                    }}
                    className='custom-select'
                    value={props.language}
                    onChange={(e) => {
                      props.handleSetLanguage(e.target.value);
                    }}
                  >
                    <option value='en'>English</option>
                    <option value='es'>Español</option>
                  </select>
                </div>{' '}
              </Button>
              <div style={{ display: 'inline-block' }}>
                <WalletButton />
              </div>
            </Box>
          </DesktopMenu>
        </AppBar>

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
            zIndex: 3,
          }}
          variant='persistent'
          anchor='left'
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <ListItem>
              <NavLink
                to='/'
                style={{
                  textDecoration: 'none',
                  color: '#505050',
                }}
                onClick={handleDrawerClose}
                onMouseEnter={handleHoverLink}
                onMouseLeave={handleHoverLinkLeave}
              >
                {content.home}
              </NavLink>
            </ListItem>

            <ListItem>
              <NavLink
                to='/campaigns'
                style={{ textDecoration: 'none', color: '#505050' }}
                onClick={handleDrawerClose}
                onMouseEnter={handleHoverLink}
                onMouseLeave={handleHoverLinkLeave}
              >
                {content.campaigns}
              </NavLink>
            </ListItem>
            <ListItem>
              <NavLink
                to='/create-campaign'
                style={{ textDecoration: 'none', color: '#505050' }}
                onClick={handleDrawerClose}
                onMouseEnter={handleHoverLink}
                onMouseLeave={handleHoverLinkLeave}
              >
                {content.createCampaign}
              </NavLink>
            </ListItem>
            <ListItem>
              <NavLink
                to='/organization'
                style={{ textDecoration: 'none', color: '#505050' }}
                onClick={handleDrawerClose}
                onMouseEnter={handleHoverLink}
                onMouseLeave={handleHoverLinkLeave}
              >
                {content.organizations}
              </NavLink>
            </ListItem>
            <ListItem>
              <HashLink
                to={`/${aboutLink}`}
                style={{ textDecoration: 'none', color: '#505050' }}
                onClick={handleDrawerClose}
                onMouseEnter={handleHoverLink}
                onMouseLeave={handleHoverLinkLeave}
              >
                {content.about}
              </HashLink>
            </ListItem>
            <ListItem>
              <HashLink
                to={`/${faqLink}`}
                style={{ textDecoration: 'none', color: '#505050' }}
                onClick={handleDrawerClose}
                onMouseEnter={handleHoverLink}
                onMouseLeave={handleHoverLinkLeave}
              >
                {content.faqs}
              </HashLink>
            </ListItem>
            <ListItem>
              {' '}
              <div className='language-select'>
                <select
                  style={{
                    marginLeft: '5px',
                    marginTop: '-3px',
                    border: '1px solid darkorange',
                    borderRadius: '5px',
                    padding: '5px',
                    fontSize: '1rem',
                    fontWeight: '500',
                    color: '#f08213',
                  }}
                  className='custom-select'
                  value={props.language}
                  onChange={(e) => {
                    props.handleSetLanguage(e.target.value);
                  }}
                >
                  <option value='en'>English</option>
                  <option value='es'>Español</option>
                </select>
              </div>{' '}
            </ListItem>
          </List>

          <Divider />
          <List style={{ paddingLeft: 0 }}>
            <ListItem style={{ paddingLeft: 0 }}>
              <WalletButton />
            </ListItem>
            {account && (
              <>
                <ListItem>
                  <Link
                    to='/profile'
                    onClick={handleDrawerClose}
                    style={{ textDecoration: 'none' }}
                  >
                    Profile
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    to=''
                    onClick={disconnectWallet}
                    style={{ textDecoration: 'none' }}
                  >
                    LogOut
                  </Link>
                </ListItem>
              </>
            )}
          </List>
        </Drawer>
      </Box>
    </>
  );
}
