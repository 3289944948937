export default {
  English: {
    closeEarlyAndClaim: 'Close Early And Claim!',
    claimNow: 'Close Early and Claim Now!',
    ccEarlyText:
      'Are you sure you want to Claim your funds? Closing Early results in an additional 1% service fee. If you agree with this, click confirm below.',
    cancel: 'Cancel',
    confirm: 'Confirm',
    pleaseApprove: 'Please Approve Transaction to Continue...',
    closeAndClaim: 'Closing & Claiming Funds...',
    fundsClaimed: 'Funds Claimed!',
    claim: 'Claim',
    aboutToClose: 'About to Close',
    transactionFailed: 'Transaction Failed',
  },
  Spanish: {
    closeEarly: 'Cerrar Antes',
    claimNow: 'Cerrar y Recuperar Ahora!',
    closeEarlyText:
      '¿Estás seguro de que quieres cerrar antes? El cierre anticipado resulta en una comisión adicional del 1% de servicio. Si estás de acuerdo con esto, haz clic en confirmar.',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    pleaseApprove: 'Por favor, aproveche la transacción para continuar...',
    closeAndClaim: 'Cerrando y Recuperando Ahora...',
    fundsClaimed: '¡Los fondos han sido recuperados!',
    claim: 'Recuperar',
    aboutToClose: 'Esta por cerrar',
    transactionFailed: 'La transacción falló',
  },
};
