import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { FaqContainer, FaqHeader } from './FaqStyles';
import { useLanguage } from '../../contexts/LanguageContext';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  fontStyle: 'italic',
  fontWeight: 'bold',
  marginLeft: '1.5rem',
}));

export default function CustomizedAccordions(props) {
  const [expanded, setExpanded] = useState('panel1');
  const { language, getLanguageContent } = useLanguage();
  const [content, setContent] = useState(getLanguageContent('FaqTrans'));

  useEffect(() => {
    setContent(getLanguageContent('FaqTrans'));
  }, [language]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <FaqContainer>
        <section style={{ width: '95%', margin: '0 auto' }}>
          <FaqHeader>{content.fax}</FaqHeader>
          <Accordion
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
          >
            <AccordionSummary
              aria-controls='panel1d-content'
              id='panel1d-header'
            >
              <Typography>{content.question1}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {content.answer1} <br />
                {content.answer1b}
              </Typography>

              <ul>
                <li>{content.li1}</li>
                <li>{content.li2}</li>
                <li>{content.li3}</li>
                <li>{content.li4}</li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
          >
            <AccordionSummary
              aria-controls='panel2d-content'
              id='panel2d-header'
            >
              <Typography>{content.question2}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>{content.q2li1}</li>
                <li>{content.q2li2}</li>
                <li>{content.q2li3}</li>
                <li>{content.q2li4}</li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}
          >
            <AccordionSummary
              aria-controls='panel3d-content'
              id='panel3d-header'
            >
              <Typography>{content.question3}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{content.answer3}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'panel4'}
            onChange={handleChange('panel4')}
          >
            <AccordionSummary
              aria-controls='panel4d-content'
              id='panel4d-header'
            >
              <Typography>{content.question4}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{content.answer4}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'panel5'}
            onChange={handleChange('panel5')}
          >
            <AccordionSummary
              aria-controls='panel5d-content'
              id='panel5d-header'
            >
              <Typography>{content.question5}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {content.answer5}{' '}
                <a
                  href='https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain'
                  target='_blank'
                  title='Click Me to visit Binance Academy'
                >
                  Binance Academy
                </a>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </section>
      </FaqContainer>
    </div>
  );
}
