import React, { createContext, useState, useEffect, useContext } from 'react';
import { useDApp } from './web3';

const PriceApiContext = createContext();

export function usePriceApi() {
  return useContext(PriceApiContext);
}
// create a provider for price api context
export function PriceApiProvider({ children }) {
  const { web3R, priceConsumerContract } = useDApp();
  const [price, setPrice] = useState('0.00');

  useEffect(() => {
    if (!priceConsumerContract) return null;
    const _interval = setInterval(() => {
      getBNBPrice();
    }, 5 * 60 * 1000); //update every 5min
    getBNBPrice();
    return () => {
      clearInterval(_interval);
    };
  }, [priceConsumerContract]);

  const getBNBPrice = async () => {
    //read from priceConsumerContract
    let p = await priceConsumerContract.methods.getScaledPrice(18).call();
    //convert price from wei
    p = web3R.utils.fromWei(p, 'ether');
    // p = p / (10 ** d);
    console.log("Latest BNB price", p);
    setPrice(p);
  };

  // const getBNBPrice = async () => {
  //   const response = await fetch(
  //     'https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd'
  //   );
  //   const {
  //     binancecoin: { usd: price },
  //   } = await response.json();
  //   setPrice(price);
  // };

  return (
    <PriceApiContext.Provider value={{ price }}>
      {children}
    </PriceApiContext.Provider>
  );
}
