export default {
  English: {
    campsTopTitle: 'CAMPAIGNS',
    search: 'Search Campaigns',
    keywords: 'Search Keywords / Tags',
    cat: 'Please Select a Category',
    state: 'Select a State / Region',
    amount: 'Amount',
    closeSoon: 'Closing Soon',
    comeSoon: 'Coming Soon',
    clear: 'Clear',
    apply: 'Apply',
    general: 'General',
    tech: 'Technology',
    fire: 'Fire Damage',
    health: 'Health',
    meds: 'Medicine',
    invent: 'Invention',
    ed: 'Education',
    env: 'Environment',
    sci: 'Science',
    metaContent:
      'This page shows all the campaigns that were created on I Will Fund. You can sort by state, when the campaign was started, when the campaign will end, by min and max amounts, weather the campaign will end soon or if it is coming soon.',
  },
  Spanish: {
    campsTopTitle: 'CAMPANAS',
    search: 'Buscar Campañas',
    keywords: 'Palabras Clave / Etiquetas',
    cat: 'Seleccione una Categoría',
    state: 'Seleccione un Estado / Región',
    amount: 'Monto',
    closeSoon: 'Cerrando Pronto',
    comeSoon: 'Próximamente',
    clear: 'Limpiar',
    apply: 'Aplicar',
    general: 'General',
    tech: 'Tecnología',
    fire: 'Daño a la Fuego',
    health: 'Salud',
    meds: 'Medicina',
    invent: 'Invención',
    ed: 'Educación',
    env: 'Medio Ambiente',
    sci: 'Ciencia',
    metaContent:
      'Esta página muestra todas las campañas que se crearon en I Will Fund. Puede ordenar por estado, cuando la campaña fue iniciada, cuando la campaña terminará, por monto mínimo y máximo, si la campaña terminará pronto o si está próxima.',
  },
};
