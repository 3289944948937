// tools.js
import Embed from '@editorjs/embed';
import Table from '@editorjs/table';
import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
import AlignmentTuneTool from 'editorjs-text-alignment-blocktune';
import Warning from '@editorjs/warning';
import Code from '@editorjs/code';
import LinkTool from '@editorjs/link';
import Image from '@editorjs/image';
import Raw from '@editorjs/raw';
import Header from '@editorjs/header';
import Quote from '@editorjs/quote';
import Marker from '@editorjs/marker';
import CheckList from '@editorjs/checklist';
import Delimiter from '@editorjs/delimiter';
import InlineCode from '@editorjs/inline-code';
import SimpleImage from '@editorjs/simple-image';

export const EDITOR_JS_TOOLS = function (uploadBeforeCallback, uploadAfterCallback, uploadErrorCallback) {
  const sendToServer = async (sendToServerCallback) => {
    let ret = {
      success: false,
    };
    if (!sendToServerCallback) return ret;
    if (uploadBeforeCallback) {
      uploadBeforeCallback();
    }
    try {
      const response = await sendToServerCallback();
      const data = await response.json();
      ret = data;
    } catch (error) {
      ret.error = error.message;
      console.log(error);
      if (uploadErrorCallback) {
        uploadErrorCallback(error);
      }
    }
    if (uploadAfterCallback) {
      uploadAfterCallback();
    }
    return ret;
  };
  return {
    header: {
      class: Header,
      inlineToolbar: ['link'],
      tunes: ['anyTuneName'],
    },
    list: {
      class: List,
      inlineToolbar: ['link', 'bold'],
    },
    embed: {
      class: Embed,
      inlineToolbar: true,
    },
    image: {
      class: Image,
      tunes: ['anyTuneName'],
      config: {
        // endpoints: {
        //   byFile: '/api/image-upload',
        //   byUrl: '/api/url-upload',
        // },
        uploader: {
          async uploadByFile(file) {
            return await sendToServer(async () => {
              //build form to upload image
              const formData = new FormData();
              formData.append('image', file);
              //send via fetch
              const response = await fetch('/api/image-upload', {
                method: 'POST',
                body: formData,
              });
              return response;
            });
          },
          async uploadByUrl(url) {
            return await sendToServer(async () => {
              const response = await fetch('/api/url-upload', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  url,
                }),
              });
              return response;
            });
          }
        }
      },
    },
    paragraph: {
      class: Paragraph,
      inlineToolbar: ['link', 'bold', 'italic'],
      tunes: ['anyTuneName'],
    },
    anyTuneName: {
      class: AlignmentTuneTool,
      config: {
        default: 'left',
        blocks: {
          header: 'center',
          list: 'right',
        },
      },
    },
    quote: {
      class: Quote,
      inlineToolbar: true,
    },
    marker: {
      class: Marker,
      inlineToolbar: true,
    },
    checklist: CheckList,
    table: {
      class: Table,
      inlineToolbar: true,
      config: {
        rows: 2,
        cols: 3,
      },
    },
    warning: Warning,
    code: Code,
    linkTool: LinkTool,
    raw: Raw,
    marker: Marker,
    delimiter: Delimiter,
    inlineCode: InlineCode,
  }
};
