import styled from 'styled-components';

export const Form = styled.form`
  border-radius: 5px;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  overflow: hidden;
`;

export const ContentForm = styled.div`
  width: 100%;
  padding: 40px 30px;
`;

export const CalcContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

export const RightSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

export const IconCase = styled.span`
  width: 35px;
  float: left;
  border-radius: 8px 0px 0px 8px;
  background: #eeeeee;
  height: 42px;
  position: absolute;
  z-index: 3;
  text-align: center;
  line-height: 40px;
`;

export const AmountInput = styled.input`
  position: relative;
  border-radius: 8px 5px 5px 8px;
  border: 1px solid #eee;
  margin-bottom: 1px;
  width: 100%;
  height: 40px;
  float: left;
  padding: 0px 15px;
  font-size: 1.1rem;
  padding-left: 40px;
  color: #666;
  text-align: center;
  &:focus {
    outline: none !important;
    outline-offset: 0px !important;
  }
`;

export const AmountHolder = styled.div`
  position: relative;
  overflow: hidden;
  clear: both;
  width: 100%;
`;

export const Icon = styled.i`
  color: #555;
`;

export const CalcText = styled.p`
  font-size: 1.2rem;
  margin: 0;
  color: #666;
`;

export const CalcHeader = styled.h1`
  font-size: 2.5rem;
  font-weight: 400;
  color: #666;
  margin: 0 auto;
`;

export const HrStyle = styled.hr`
  color: #c4c4c4;
  height: 3px;
  border: none;
  background-color: #c4c4c4;
`;
