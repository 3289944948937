export default {
  English: {
    orgTopTitle: 'ORGANIZATIONS',
    organization: 'Organizations',
    orgAreYou: 'Are you an organization?',
    orgText:
      'If you are eligible, you will receive a lower service fee and your campaign contract will not have an end date. Fill out the form and once approved, we will create your perfect campaign.',
    dontMissOut: "Don't miss out on this important revenue stream",
    takeAdvantage:
      'Your organization could be missing out on large amounts of funding by excluding crypto as a funding income stream. Fill in the form if you are a registered organization',
    orgNonprofit:
      'Nonprofit organizations must be registered in their respective countries',
    likeTo: 'I would like to...',
    receive: 'Receive More Information',
    create: 'Create a campaign for my organization',
    tellAboutYourself: 'Tell us about yourself',
    firstName: 'First Name',
    lastName: 'Last Name',
    applicantEmail: 'Email',
    applicantJobTitle: 'Job Title',
    applicantPhone: 'Phone Number',
    tellAboutOrg: 'Tell us about your organization',
    oName: 'Organization Name',
    oWebsite: 'Organization Website URL',
    /* Country info */
    oCountry: 'Country',
    selectCountry: 'Select Country',
    usa: 'United States',
    afghanistan: 'Afghanistan',
    alandIslands: 'Åland Islands',
    albania: 'Albania',
    Algeria: 'Algeria',
    AmericanSamoa: 'American Samoa',
    Andorra: 'Andorra',
    Angola: 'Angola',
    Anguilla: 'Anguilla',
    Antarctica: 'Antartica',
    AntiguaandBarbuda: 'Antigua and Barbuda',
    Argentina: 'Argentina',
    Armenia: 'Armenia',
    Aruba: 'Aruba',
    Australia: 'Australia',
    Austria: 'Austria',
    Azerbaijan: 'Azerbaijan',
    Bahamas: 'Bahamas',
    Bahrain: 'Bahrain',
    Bangladesh: 'Bangladesh',
    Barbados: 'Barbados',
    Belarus: 'Belarus',
    Belgium: 'Belgium',
    Belize: 'Belize',
    Benin: 'Benin',
    Bermuda: 'Bermuda',
    Bhutan: 'Bhutan',
    Bolivia: 'Bolivia',
    BosniaandHerzegovina: 'Bosnia and Herzegovina',
    Botswana: 'Botswana',
    BouvetIsland: 'Bouvet Island',
    Brazil: 'Brazil',
    BritishIndianOceanTerritory: 'British Indian Ocean Territory',
    BruneiDarussalam: 'Brunei Darussalam',
    Bulgaria: 'Bulgaria',
    BurkinaFaso: 'Burkina Faso',
    Burundi: 'Burundi',
    Cambodia: 'Cambodia',
    Cameroon: 'Cameroon',
    Canada: 'Canada',
    CapeVerde: 'CapeVerde',
    CaymanIslands: 'Cayman Islands',
    CentralAfricanRepublic: 'Central African Republic',
    Chad: 'Chad',
    Chile: 'Chile',
    China: 'China',
    ChristmasIsland: 'Christmas Island',
    CocosKeelingIslands: 'Cocos (Keeling) Islands',
    Colombia: 'Colombia',
    Comoros: 'Comoros',
    Congo: 'Congo',
    CongoTheDemocraticRepublicofThe: 'Congo, The Democratic Republic of The',
    CookIslands: 'Cook Islands',
    CostaRica: 'Costa Rica',
    CoteDivoire: "Cote D'ivoire",
    Croatia: 'Croatia',
    Cuba: 'Cuba',
    Cyprus: 'Cyprus',
    CzechRepublic: 'Czech Republic',
    Denmark: 'Denmark',
    Djibouti: 'Djibouti',
    Dominica: 'Dominica',
    DominicanRepublic: 'Dominican Republic',
    Ecuador: 'Ecuador',
    Egypt: 'Egypt',
    ElSalvador: 'El Salvador',
    EquatorialGuinea: 'Equatorial Guinea',
    Eritrea: 'Eritrea',
    Estonia: 'Estonia',
    Ethiopia: 'Ethiopia',
    FalklandIslandsMalvinas: 'Falkland Islands Malvinas',
    FaroeIslands: 'Faroe Islands',
    Fiji: 'Fiji',
    Finland: 'Finland',
    France: 'France',
    FrenchGuiana: 'French Guiana',
    FrenchPolynesia: 'French Polynesia',
    FrenchSouthernTerritories: 'French Southern Territories',
    Gabon: 'Gabon',
    Gambia: 'Gambia',
    Georgia: 'Georgia',
    Germany: 'Germany',
    Ghana: 'Ghana',
    Gibraltar: 'Gibraltar',
    Greece: 'Greece',
    Greenland: 'Greenland',
    Grenada: 'Grenada',
    Guadeloupe: 'Guadeloupe',
    Guam: 'Guam',
    Guatemala: 'Guatemala',
    Guernsey: 'Guernsey',
    Guinea: 'Guinea',
    Guineabissau: 'Guineabissau',
    Guyana: 'Guyana',
    Haiti: 'Haiti',
    HeardIslandandMcdonaldIslands: 'Heard Island and Mcdonald Islands',
    HolySeeVaticanCityState: 'Holy See Vatican City State',
    Honduras: 'Honduras',
    HongKong: 'HongKong',
    Hungary: 'Hungary',
    Iceland: 'Iceland',
    India: 'India',
    Indonesia: 'Indonesia',
    IranIslamicRepublicof: 'Iran, Islamic Republic of',
    Iraq: 'Iraq',
    Ireland: 'Ireland',
    IsleofMan: 'Isle of Man',
    Israel: 'Israel',
    Italy: 'Italy',
    Jamaica: 'Jamaica',
    Japan: 'Japan',
    Jersey: 'Jersey',
    Jordan: 'Jordan',
    Kazakhstan: 'Kazakhstan',
    Kenya: 'Kenya',
    Kiribati: 'Kiribati',
    KoreaDemocraticPeoplesRepublicof: "Korea, Democratic People's Republic of",
    KoreaRepublicof: 'Korea, Republic of',
    Kuwait: 'Kuwait',
    Kyrgyzstan: 'Kyrgyzstan',
    LaoPeoplesDemocraticRepublic: "Lao, People's Democratic Republic",
    Latvia: 'Latvia',
    Lebanon: 'Lebanon',
    Lesotho: 'Lesotho',
    Liberia: 'Liberia',
    LibyanArabJamahiriya: 'Libyan Arab Jamahiriya',
    Liechtenstein: 'Liechtenstein',
    Lithuania: 'Lithuania',
    Luxembourg: 'Luxembourg',
    Macao: 'Macao',
    MacedoniaTheFormerYugoslavRepublicof:
      'Macedonia, The Former Yugoslav Republic of',
    Madagascar: 'Madagascar',
    Malawi: 'Malawi',
    Malaysia: 'Malaysia',
    Maldives: 'Maldives',
    Mali: 'Mali',
    Malta: 'Malta',
    MarshallIslands: 'Marshall Islands',
    Martinique: 'Martinique',
    Mauritania: 'Mauritania',
    Mauritius: 'Mauritius',
    Mayotte: 'Mayotte',
    Mexico: 'Mexico',
    MicronesiaFederatedStatesof: 'Micronesia, Federated States of',
    MoldovaRepublicof: 'Moldova, Republic of',
    Monaco: 'Monaco',
    Mongolia: 'Mongolia',
    Montenegro: 'Montenegro',
    Montserrat: 'Montserrat',
    Morocco: 'Morocco',
    Mozambique: 'Mozambique',
    Myanmar: 'Myanmar',
    Namibia: 'Namibia',
    Nauru: 'Nauru',
    Nepal: 'Nepal',
    Netherlands: 'Netherlands',
    NetherlandsAntilles: 'Netherlands Antilles',
    NewCaledonia: 'NewCaledonia',
    NewZealand: 'NewZealand',
    Nicaragua: 'Nicaragua',
    Niger: 'Niger',
    Nigeria: 'Nigeria',
    Niue: 'Niue',
    NorfolkIsland: 'Norfolk Island',
    NorthernMarianaIslands: 'Northern Mariana Islands',
    Norway: 'Norway',
    Oman: 'Oman',
    Pakistan: 'Pakistan',
    Palau: 'Palau',
    PalestinianTerritoryOccupied: 'Palestinian Territory, Occupied',
    Panama: 'Panama',
    PapuaNewGuinea: 'Papua NewGuinea',
    Paraguay: 'Paraguay',
    Peru: 'Peru',
    Philippines: 'Philippines',
    Pitcairn: 'Pitcairn',
    Poland: 'Poland',
    Portugal: 'Portugal',
    PuertoRico: 'PuertoRico',
    Qatar: 'Qatar',
    Reunion: 'Reunion',
    Romania: 'Romania',
    RussianFederation: 'Russian Federation',
    Rwanda: 'Rwanda',
    SaintHelena: 'Saint Helena',
    SaintKittsandNevis: 'Saint Kitts and Nevis',
    SaintLucia: 'Saint Lucia',
    SaintPierreandMiquelon: 'Saint Pierre and Miquelon',
    SaintVincentandTheGrenadines: 'Saint Vincent and The Grenadines',
    Samoa: 'Samoa',
    SanMarino: 'SanMarino',
    SaoTomeandPrincipe: 'Sao Tome and Principe',
    SaudiArabia: 'Saudi Arabia',
    Senegal: 'Senegal',
    Serbia: 'Serbia',
    Seychelles: 'Seychelles',
    SierraLeone: 'Sierra Leone',
    Singapore: 'Singapore',
    Slovakia: 'Slovakia',
    Slovenia: 'Slovenia',
    SolomonIslands: 'Solomon Islands',
    Somalia: 'Somalia',
    SouthAfrica: 'South Africa',
    SouthGeorgiaandTheSouthSandwichIslands:
      'South Georgia and The South Sandwich Islands',
    Spain: 'Spain',
    SriLanka: 'Sri Lanka',
    Sudan: 'Sudan',
    Suriname: 'Suriname',
    SvalbardandJanMayen: 'Svalbard and Jan Mayen',
    Swaziland: 'Swaziland',
    Sweden: 'Sweden',
    Switzerland: 'Switzerland',
    SyrianArabRepublic: 'Syrian Arab Republic',
    Taiwan: 'Taiwan',
    Tajikistan: 'Tajikistan',
    TanzaniaUnitedRepublicof: 'TanzaniaUnitedRepublicof',
    Thailand: 'Thailand',
    Timorleste: 'Timorleste',
    Togo: 'Togo',
    Tokelau: 'Tokelau',
    Tonga: 'Tonga',
    TrinidadandTobago: 'Trinidad and Tobago',
    Tunisia: 'Tunisia',
    Turkey: 'Turkey',
    Turkmenistan: 'Turkmenistan',
    TurksandCaicosIslands: 'Turks and Caicos Islands',
    Tuvalu: 'Tuvalu',
    Uganda: 'Uganda',
    Ukraine: 'Ukraine',
    UnitedArabEmirates: 'United Arab Emirates',
    UnitedKingdom: 'UnitedKingdom',
    UnitedStatesMinorOutlyingIslands: 'United States Minor Outlying Islands',
    Uruguay: 'Uruguay',
    Uzbekistan: 'Uzbekistan',
    Vanuatu: 'Vanuatu',
    Venezuela: 'Venezuela',
    VietNam: 'VietNam',
    VirginIslandsBritish: 'Virgin Islands, British',
    VirginIslandsUS: 'Virgin Islands, US',
    WallisandFutuna: 'Wallis and Futuna',
    WesternSahara: 'Western Sahara',
    Yemen: 'Yemen',
    Zambia: 'Zambia',
    Zimbabwe: 'Zimbabwe',

    /* End Country info */
    oId: 'EIN Number / Tax ID',
    anRev: 'Annual Revenue Range',
    under200k: 'Under $200K',
    between200k500k: '$200K-$500K',
    fivehundK1M: '$500K-$1M',
    oneM5M: '$1M-$5M',
    fiveM10M: '$5M-$10M',
    tenM100M: '$10M-$100M',
    oneHundMPlus: '$100M+',
    pleaseSelect: 'Please Select',
    youHearAboutUs: 'How did you hear about us?',
    searchEngine: 'Search Engine',
    socialMedia: 'Social Media',
    advertising: 'Advertising',
    news: 'News',
    newsletter: 'Newsletter',
    onlineEvent: 'Online Event',
    inPersonEvent: 'In-Person Event',
    wordOfMouth: 'Word of Mouth',
    other: 'Other',
    messaging: 'Message',
    send: 'SEND',
    requestSent: 'Request Sent!',
    toastSent: 'Organization application successfully submitted.',
    toastError: 'Please enter all required fields',
    content:
      'Funding your Dreams with Crypto Currency in I Will Fund Organization Page',
  },
  Spanish: {
    orgTopTitle: 'ORGANIZACIÓN',
    organization: 'Organizaciónes',
    orgAreYou: '¿Son una organización?',
    orgText:
      'Si cumplen con los requisitos podrán disfrutar de una reducción en la tarifa del pago de servicios además de que su campaña podrá permanecer visible el tiempo que quieran. LLenan el formato que encontrarán aquí y una vez autorizado, crearemos tu campaña perfecta.',
    dontMissOut: 'No te pierdas en este importante flujo de ingresos',
    takeAdvantage:
      'Tu organización podría estar perdiendo en ingresos porque excluyes el ingreso de crypto como flujo de ingresos. Llena el formulario si eres una organización registrada.',
    orgNonprofit:
      'Organizaciones sin fines de lucro deben estar registradas en su propio país',
    likeTo: 'Me gustaría...',
    receive: 'Recibir más información',
    create: 'Crear una campaña para mi organización',
    tellAboutYourself: 'Cuéntanos sobre ti',
    firstName: 'Nombre',
    lastName: 'Apellido',
    applicantEmail: 'Correo Electrónico',
    applicantJobTitle: 'Título Profesional',
    applicantPhone: 'Número Teléfonico',
    tellAboutOrg: 'Cuéntanos sobre tu organización',
    oName: 'Nombre de la Organización',
    oWebsite: 'URL - Sitio Web de la Organización',
    /* Country info */
    oCountry: 'País',
    selectCountry: 'Selecciona un País',
    usa: 'Estados Unidos',
    afghanistan: 'Afganistán',
    alandIslands: 'Åland',
    albania: 'Albania',
    Algeria: 'Argelia',
    AmericanSamoa: 'Samoa Americana',
    Andorra: 'Andorra',
    Angola: 'Angola',
    Anguilla: 'Anguilla',
    Antarctica: 'Antártida',
    AntiguaandBarbuda: 'Antigua y Barbuda',
    Argentina: 'Argentina',
    Armenia: 'Armenia',
    Aruba: 'Aruba',
    Australia: 'Australia',
    Austria: 'Austria',
    Azerbaijan: 'Azerbaiyán',
    Bahamas: 'Bahamas',
    Bahrain: 'Bahrein',
    Bangladesh: 'Bangladesh',
    Barbados: 'Barbados',
    Belarus: 'Bielorrusia',
    Belgium: 'Bélgica',
    Belize: 'Belice',
    Benin: 'Benin',
    Bermuda: 'Bermudas',
    Bhutan: 'Bután',
    Bolivia: 'Bolivia',
    BosniaandHerzegovina: 'Bosnia y Herzegovina',
    Botswana: 'Botswana',
    BouvetIsland: 'Isla Bouvet',
    Brazil: 'Brasil',
    BritishIndianOceanTerritory: 'Territorio Británico del Océano Índico',
    BruneiDarussalam: 'Brunei Darussalam',
    Bulgaria: 'Bulgaria',
    BurkinaFaso: 'Burkina Faso',
    Burundi: 'Burundi',
    Cambodia: 'Camboya',
    Cameroon: 'Camerún',
    Canada: 'Canadá',
    CapeVerde: 'Cabo Verde',
    CaymanIslands: 'Islas Caimán',
    CentralAfricanRepublic: 'República Centroafricana',
    Chad: 'Chad',
    Chile: 'Chile',
    China: 'China',
    ChristmasIsland: 'Isla de Navidad',
    CocosKeelingIslands: 'Islas Cocos (Keeling)',
    Colombia: 'Colombia',
    Comoros: 'Comoras',
    Congo: 'Congo',
    CongoTheDemocraticRepublicofThe: 'República Democrática del Congo',
    CookIslands: 'Islas Cook',
    CostaRica: 'Costa Rica',
    CoteDivoire: 'Costa de Marfil',
    Croatia: 'Croacia',
    Cuba: 'Cuba',
    Cyprus: 'Chipre',
    CzechRepublic: 'República Checa',
    Denmark: 'Dinamarca',
    Djibouti: 'Yibuti',
    Dominica: 'Dominica',
    DominicanRepublic: 'República Dominicana',
    Ecuador: 'Ecuador',
    Egypt: 'Egipto',
    ElSalvador: 'El Salvador',
    EquatorialGuinea: 'Guinea Ecuatorial',
    Eritrea: 'Eritrea',
    Estonia: 'Estonia',
    Ethiopia: 'Etiopía',
    FalklandIslandsMalvinas: 'Islas Malvinas',
    FaroeIslands: 'Islas Feroe',
    Fiji: 'Fiji',
    Finland: 'Finlandia',
    France: 'Francia',
    FrenchGuiana: 'Guayana Francesa',
    FrenchPolynesia: 'Polinesia Francesa',
    FrenchSouthernTerritories: 'Tierras Australes y Antárticas Francesas',
    Gabon: 'Gabón',
    Gambia: 'Gambia',
    Georgia: 'Georgia',
    Germany: 'Alemania',
    Ghana: 'Ghana',
    Gibraltar: 'Gibraltar',
    Greece: 'Grecia',
    Greenland: 'Groenlandia',
    Grenada: 'Granada',
    Guadeloupe: 'Guadalupe',
    Guam: 'Guam',
    Guatemala: 'Guatemala',
    Guernsey: 'Guernsey',
    Guinea: 'Guinea',
    Guineabissau: 'Guinea-Bissau',
    Guyana: 'Guyana',
    Haiti: 'Haití',
    HeardIslandandMcdonaldIslands: 'Islas Heard y McDonald',
    HolySeeVaticanCityState: 'Santa Sede',
    Honduras: 'Honduras',
    HongKong: 'Hong Kong',
    Hungary: 'Hungría',
    Iceland: 'Islandia',
    India: 'India',
    Indonesia: 'Indonesia',
    IranIslamicRepublicof: 'República Islámica de Irán',
    Iraq: 'Irak',
    Ireland: 'Irlanda',
    IsleofMan: 'Isla de Man',
    Israel: 'Israel',
    Italy: 'Italia',
    Jamaica: 'Jamaica',
    Japan: 'Japón',
    Jersey: 'Jersey',
    Jordan: 'Jordán',
    Kazakhstan: 'Kazajstán',
    Kenya: 'Kenia',
    Kiribati: 'Kiribati',
    KoreaDemocraticPeoplesRepublicof: 'República Democrática de Corea',
    KoreaRepublicof: 'República de Corea',
    Kuwait: 'Kuwait',
    Kyrgyzstan: 'Kirguistán',
    LaoPeoplesDemocraticRepublic: 'República Democrática de Laos',
    Latvia: 'Letonia',
    Lebanon: 'Líbano',
    Liechtenstein: 'Liechtenstein',
    Lesotho: 'Lesoto',
    Liberia: 'Liberia',
    LibyanArabJamahiriya: 'Jamahiriya Libia',
    Lithuania: 'Lituania',
    Luxembourg: 'Luxemburgo',
    Macao: 'Macao',
    MacedoniaTheFormerYugoslavRepublicof: 'República Yugoslava de Macedonia',
    Madagascar: 'Madagascar',
    Malawi: 'Malawi',
    Malaysia: 'Malasia',
    Maldives: 'Maldivas',
    Mali: 'Mali',
    Malta: 'Malta',
    MarshallIslands: 'Islas Marshall',
    Martinique: 'Martinica',
    Mauritania: 'Mauritania',
    Mauritius: 'Mauricio',
    Mayotte: 'Mayotte',
    Mexico: 'México',
    MicronesiaFederatedStatesof: 'Estados Federados de Micronesia',
    MoldovaRepublicof: 'República de Moldavia',
    Monaco: 'Mónaco',
    Mongolia: 'Mongolia',
    Montenegro: 'Montenegro',
    Montserrat: 'Montserrat',
    Morocco: 'Marruecos',
    Mozambique: 'Mozambique',
    Myanmar: 'Myanmar',
    Namibia: 'Namibia',
    Nauru: 'Nauru',
    Nepal: 'Nepal',
    Netherlands: 'Países Bajos',
    NetherlandsAntilles: 'Antillas Holandesas',
    NewCaledonia: 'Nueva Caledonia',
    NewZealand: 'Nueva Zelanda',
    Nicaragua: 'Nicaragua',
    Niger: 'Níger',
    Nigeria: 'Nigeria',
    Niue: 'Niue',
    NorfolkIsland: 'Isla Norfolk',
    NorthernMarianaIslands: 'Islas Marianas del Norte',
    Norway: 'Noruega',
    Oman: 'Omán',
    Pakistan: 'Pakistán',
    Palau: 'Palau',
    PalestinianTerritoryOccupied: 'Territorio Palestiní',
    Panama: 'Panamá',
    PapuaNewGuinea: 'Papúa Nueva Guinea',
    Paraguay: 'Paraguay',
    Peru: 'Perú',
    Philippines: 'Filipinas',
    Pitcairn: 'Pitcairn',
    Poland: 'Polonia',
    Portugal: 'Portugal',
    PuertoRico: 'Puerto Rico',
    Qatar: 'Qatar',
    Reunion: 'Reunión',
    Romania: 'Rumania',
    RussianFederation: 'Federación Rusa',
    Rwanda: 'Ruanda',
    SaintHelena: 'Santa Elena',
    SaintKittsandNevis: 'San Kitts y Nevis',
    SaintLucia: 'Santa Lucía',
    SaintPierreandMiquelon: 'San Pedro y Miquelón',
    SaintVincentandTheGrenadines: 'San Vicente y las Granadinas',
    Samoa: 'Samoa',
    SanMarino: 'San Marino',
    SaoTomeandPrincipe: 'Santo Tomé y Príncipe',
    SaudiArabia: 'Arabia Saudí',
    Senegal: 'Senegal',
    Serbia: 'Serbia',
    Seychelles: 'Seychelles',
    SierraLeone: 'Sierra Leona',
    Singapore: 'Singapur',
    Slovakia: 'Eslovaquia',
    Slovenia: 'Eslovenia',
    SolomonIslands: 'Islas Salomón',
    Somalia: 'Somalia',
    SouthAfrica: 'Sudáfrica',
    SouthGeorgiaandTheSouthSandwichIslands:
      'Islas Georgia del Sur y Sandwich del Sur',
    Spain: 'España',
    SriLanka: 'Sri Lanka',
    Sudan: 'Sudán',
    Suriname: 'Surinam',
    SvalbardandJanMayen: 'Svalbard y Jan Mayen',
    Swaziland: 'Suazilandia',
    Sweden: 'Suecia',
    Switzerland: 'Suiza',
    SyrianArabRepublic: 'República Árabe Siria',
    Taiwan: 'Taiwán',
    Tajikistan: 'Tayikistán',
    TanzaniaUnitedRepublicof: 'República Unida de Tanzania',
    Thailand: 'Tailandia',
    Timorleste: 'Timor Oriental',
    Togo: 'Togo',
    Tokelau: 'Tokelau',
    Tonga: 'Tonga',
    TrinidadandTobago: 'Trinidad y Tobago',
    Tunisia: 'Túnez',
    Turkey: 'Turquía',
    Turkmenistan: 'Turkmenistán',
    TurksandCaicosIslands: 'Islas Turcas y Caicos',
    Tuvalu: 'Tuvalu',
    Uganda: 'Uganda',
    Ukraine: 'Ucrania',
    UnitedArabEmirates: 'Emiratos Árabes Unidos',
    UnitedKingdom: 'Reino Unido',
    UnitedStatesMinorOutlyingIslands:
      'Islas Ultramarinas Menores de Estados Unidos',
    Uruguay: 'Uruguay',
    Uzbekistan: 'Uzbekistán',
    Vanuatu: 'Vanuatu',
    Venezuela: 'Venezuela',
    VietNam: 'Vietnam',
    VirginIslandsBritish: 'Islas Vírgenes Británicas',
    VirginIslandsUS: 'Islas Vírgenes de los Estados Unidos',
    WallisandFutuna: 'Wallis y Futuna',
    WesternSahara: 'Sáhara Occidental',
    Yemen: 'Yemen',
    Zambia: 'Zambia',
    Zimbabwe: 'Zimbabue',

    /* End Country Info*/
    oId: '# Identificación Fiscal / ID de Impuestos',
    anRev: 'Rango Anual de Ingresos',
    under200k: 'Menor a $200K',
    between200k500k: '$200K-$500K',
    fivehundK1M: '$500K-$1M',
    oneM5M: '$1M-$5M',
    fiveM10M: '$5M-$10M',
    tenM100M: '$10M-$100M',
    oneHundMPlus: '$100M+',
    pleaseSelect: 'Selecciona',
    youHearAboutUs: '¿Cómo te enteraste de nosotros?',
    searchEngine: 'Motor de Búsqueda',
    socialMedia: 'Redes Sociales',
    advertising: 'Publicidad',
    news: 'Noticias',
    newsletter: 'Boletín',
    onlineEvent: 'Evento en Línea',
    inPersonEvent: 'Evento presencial',
    wordOfMouth: 'Por el Boca en Boca',
    other: 'Otro',
    messaging: 'Mensaje',
    send: 'ENVIAR',
    requestSent: '¡Solicitud Enviada!',
    toastSent: 'Solicitud de Organización enviada correctamente.',
    toastError: 'Favor de llenar todos los campos requeridos.',
    content:
      'Fundando sus Sueños con Crypto Monedas en I Will Fund Página de Organizaciónes',
  },
};
