export default {
  English: {
    division: 'a division of ',
    terms: 'Terms of Service',
    privacy: 'Privacy and Cookies Policy',
  },
  Spanish: {
    division: 'una división de ',
    terms: 'Términos de Servicio',
    privacy: 'Política de Privacidad y Cookies',
  },
};
