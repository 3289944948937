import styled from 'styled-components';

export const SettingsForm = styled.form`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
`;

export const FormInput = styled.input`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0.5rem;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 95%;
  font-family: 'Roboto', sans-serif;
  color: #333;
`;

export const FormLabel = styled.label`
  font-size: 1rem;
  font-weight: 700;
  color: #333;
`;

export const AddressHolder = styled.div`
  width: 90%;
  background: #f8f9f9;
  margin-top: 5px;
  padding: 0.4rem;
  border: 1px solid #e8953c;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 556px) {
    font-size: 0.8rem;
  }
  @media (max-width: 475px) {
    font-size: 0.6rem;
    width: 100%;
  }
  @media (max-width: 360px) {
    flex-wrap: wrap;
  }
  @media (max-width: 320px) {
    border: none;
    background: transparent;
  }
`;
