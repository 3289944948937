export default {
  English: {
    riskTopTitle: 'RISK DISCLAIMER',
    metaDesc: 'Risk Disclaimer page for the protection of our users',
    riskDisclaimer: 'Risk Disclaimer',
    aboutRisks: 'About Risks and Volatility',
    updated: 'Updated April, 15 2022',
    considerFactors:
      'Please consider the following risk factors (many of which are specific and inherent to cryptographic tokens) before using any part of our website/platform and before creating and/or donating to a campaign in IWill.Fund or using any cryptographic token that the value of the tokens as well as your ability to access and transfer  the tokens could be materially and adversely affected if any of these risk factors materialize through changes in the cryptocurrency market. As a result, when you use tokens to donate or collect funds, you could potentially expose yourself to some potential financial losses due to gas prices or other service fees.',
    pleaseNote:
      'Please also note that this Risk Statement is not exhaustive. You should carry out further research (and seek professional advice) to carefully determine whether using IWill.Fund website/platform is suitable for your particular financial situation and risk tolerance.',
    subject:
      'SUBJECT TO ANY PROVISION TO THE CONTRARY SET OUT IN OUR EULA, WE SHALL NOT BE LIABLE FOR ANY LOSS INCURRED BY YOU RESULTING FROM YOUR ACCESS TO OUR PLATFORM OR FROM YOUR DONATIONS OR CAMPAIGN FUND CLAIMS.',
    tokens:
      'TOKENS ARE HIGH-RISK ASSETS AND YOU SHOULD NEVER USE FUNDS THAT YOU CANNOT AFFORD TO LOSE IN THE CAMPAIGN CREATION OR DONATION PROCESS.',
    priceVolatility: '1. PRICE VOLATILITY',
    tokenPrice:
      'The price of tokens can be subject to dramatic fluctuations and high volatility due to the rapid shifts in offer and demand resulting from events such as but not limited to: (a) good or bad publicity, (b) changes in the financial technology industry, (c) technological advancement, (d) market trends, (e) general economic and/or political conditions, (f) degree of adoption, (g) degree of institutional support, (h) regulatory measures, (i) degree of government support, (l) market dynamics, (m) trading activities, (n) hacking, and (o) events affecting large service providers, including exchanges.',
    asResult:
      'AS A RESULT OF PRICE VOLATILITY, YOUR TOKENS MAY LOSE ALL VALUE AND BECOME WORTHLESS. WE SHALL NOT BE RESPONSIBLE FOR ANY LOSS INCURRED BY YOU AS A RESULT OF THE INHERENT PRICE-VOLATILITY OF TOKENS.',
    cryptoWallets: '2. CRYPTOGRAPHIC WALLETS',
    tokensStored:
      "Tokens are stored on cryptographic wallets ('allets'). A private key (for example, a passphrase) is usually necessary to access, control and/or dispose of tokens that are stored in your Wallet. Losing access to the private key(s) associated with your Wallet may result in the permanent loss of your ability to access and dispose  of your tokens.",
    soleResponsible:
      'You are solely responsible for implementing all reasonable and appropriate measures for securing access to your private key(s) and Wallet.',
    noCopies:
      'WE DO NOT HOLD COPIES OF YOUR PRIVATE KEY(S). WE SHALL NOT BE RESPONSIBLE FOR ANY LOSS RESULTING FROM YOUR INABILITY TO ACCESS YOUR WALLET AND/OR PRIVATE KEYS.',
    protocols: '3. PROTOCOLS',
    tokensRecorded:
      'Tokens are recorded on distributed ledgers (typically shared across networks of users) which are governed by, subject to, and distinguished on the basis of certain set of rules known as protocols.',
    malfunction: '3.1. Malfunction, breakdown and/or abandonment of protocols',
    breakdown:
      'Any malfunction, breakdown, and/or abandonment of the protocols (and of any consensus mechanism, where applicable) on which the tokens are based could severely affect the price of the tokens as well as your ability to dispose of the tokens (particularly where the protocol relies on substantial participation and wide networks to      operate properly).',
    miningAttacks: '3.2 Mining attacks',
    someProtocols:
      'Some protocols integrate consensus-based mechanisms for the validation of transfers (“Consensus Protocols”). Consensus Protocols are, therefore, susceptible to attacks at the stage of validation, where the token transactions is approved by the network. This may affect the accuracy of transactions occurring on the protocol, and in your tokens being misappropriated (for example, through what is typically referred to as double spending attacks).',
    hacking: '3.3. Hacking and security weaknesses',
    expropriation:
      'Tokens may be subject to expropriation and/or theft. Bad actors (including hackers, groups and organization) may attempt to interfere with the protocols or the tokens in a variety of ways, including, but not limited to, malware attacks, denial of service attacks, consensus-based attacks, sybil attacks, smurfing and spoofing.',
    furthermore:
      'Furthermore, some protocols are based on open-source software and, as a result, subject to the risk of weakness being introduced to the protocols (either willingly or accidentally) at the development stage. Any such weakness may be exploited by bad actors for the purposes of misappropriating your tokens, or otherwise affect the functionality of the protocol and of your ability to dispose of your tokens.',
    noControl:
      'WE DO NOT HAVE CONTROL OVER THE PROTOCOLS. AS SUCH, WE SHALL NOT BE RESPONSIBLE FOR ANY LOSS ARISING OUT OF OR IN CONNECTION WITH THE PROTOCOLS.',
    laws: '4. LAWS AND REGULATIONS',
    legal:
      'The legal and/or regulatory framework surrounding tokens and distributed ledger technology is uncertain, not harmonized, and unsettled in many jurisdictions.',
    difficult:
      'It is difficult to predict what framework will become applicable to tokens in the near future and how the implementation of dedicated legal and/or regulatory frameworks will affect the price of tokens. A newly introduced legal and regulatory framework may interfere with or otherwise limit your ability to hold or dispose of your tokens, which in turn could result in a financial loss on your part.',
    notResponsibleForLoss:
      'WE ARE NOT RESPONSIBLE FOR ANY LOSS WHICH YOU MAY SUFFER AS A RESULT OF ANY NEWLY INTRODUCED LEGAL AND/OR REGULATORY FRAMEWORK.',
    tax: '5. TAXATION',
    taxCharacter:
      'The tax characterization of tokens is complex and largely uncertain. The uncertainty in the tax treatment of tokens may expose you to unforeseen future tax consequences associated with purchasing, owning, selling or otherwise using tokens. You should seek tax advice to understand what tax obligations apply to you when purchasing, holding, transferring, and utilising tokens. Failure to comply with your tax obligations could result in severe fines and even jail time.',
    liability:
      'WE ARE NOT RESPONSIBLE FOR ANY LOSS OR OTHER FORM OF LIABILITY ARISING OUT OF OR IN CONNECTION WITH YOUR FAILURE TO COMPLY WITH ANY TAX LIABILITY THAT IS OR WILL BE APPLICABLE TO YOU.',
    unanticipated: '6. UNANTICIPATED RISKS',
    additionToRisks:
      'In addition to the risks included in this document, there are other risks associated with your purchase, holding, trading, and use of tokens, some of which we cannot anticipate. Such risks may further materialize as unanticipated variations or combinations of the risks discussed in this section.',
    notExhaustive:
      'THIS RISK STATEMENT IS NOT EXHAUSTIVE AND SHALL NOT BE TAKEN TO ENCOMPASS ALL RISKS INVOLVED IN THE PURCHASE, HOLDING, TRADING AND USE OF TOKENS. SUBJECT TO THE TERMS OF THE EULA, WE SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY LOSS SUFFERED BY YOU AS A RESULT OF UNANTICIPATED RISKS.',
  },
  Spanish: {
    riskTopTitle: 'RIESGOS',
    metaDesc: 'Listado de riesgos para los usuarios',
    riskDisclaimer: 'Aviso de Riesgo',
    aboutRisks: 'Acerca de Riesgos y Volatilidad',
    updated: 'Actualizado Abril 15, 2022',
    considerFactors:
      'Por favor, considere los siguientes factores de riesgo (muchos de los cuales son específicos y inherentes a los tokens criptográficos) antes de utilizar cualquier parte de nuestro sitio web/plataforma y antes de crear y/o donar a una campaña en IWill.Fund o utilizar cualquier token criptográfico que el valor de los tokens así como su capacidad para acceder y transferir los tokens podrían ser materialmente y afectados si alguno de estos factores materializa por cambios en el mercado de las criptomonedas. Como resultado, cuando utilice tokens para donar o recaudar fondos, podría exponerse a algunas posibles pérdidas financieras debido a los precios de gas o a otras tarifas de servicio.',
    pleaseNote:
      'Tenga en cuenta también que esta Declaración de riesgo no es exhaustiva. Debe realizar más investigaciones (y buscar asesoramiento profesional) para determinar cuidadosamente si el uso del sitio web/plataforma IWill.Fund es adecuado para su situación financiera particular y tolerancia al riesgo.',
    subject:
      'SUJETO A CUALQUIER DISPOSICIÓN EN CONTRARIO ESTABLECIDA EN NUESTRO CLUF, NO SEREMOS RESPONSABLES DE NINGUNA PÉRDIDA QUE USTED SUFRA RESULTANTE DE SU ACCESO A NUESTRA PLATAFORMA O DE SUS DONACIONES O RECLAMACIONES DE FONDO DE CAMPAÑA',
    tokens:
      'LOS TOKENS SON ACTIVOS DE ALTO RIESGO Y USTED NUNCA DEBE UTILIZAR FONDOS QUE NO SE PUEDE PERMITIR PERDER EN EL PROCESO DE CREACIÓN O DONACIÓN DE LA CAMPAÑA.',
    priceVolatility: '1. VOLATILIDAD DE PRECIO',
    tokenPrice:
      'El precio de los tokens puede estar sujeto a fluctuaciones dramáticas y alta volatilidad debido a los cambios repentinos en las ofertas y demandas que resultan de eventos tales como: (a) buenas o malas publicaciones, (b) cambios en el sector financiero de la tecnología, (c) avances tecnológicos, (d) tendencias de mercado, (e) condiciones económicas y/o políticas generales, (f) grado de aprobación, (g) grado de apoyo institucional, (h) medidas reguladoras, (i) grado de apoyo al gobierno, (l) tendencias de mercado, (m) actividades de intercambio, (n) hacinamiento y (o) eventos afectando grandes proveedores de servicios, incluidos los canales de comercio electrónico.',
    asResult:
      'COMO RESULTADO DE LA VOLATILIDAD DE PRECIO, SU TOKEN PODRÍA PERDER TODO EL VALOR Y SE CONVERTIR EN INVALIBLE. NO SOMOS RESPONSABLES DE NINGUNA PÉRDIDA QUE USTED SUFRA COMO RESULTADO DE LA VOLATILIDAD INHERENTE DE LOS TOKENS.',
    cryptoWallets: '2. CARTERAS CRIPTOGRÁICAS',
    tokensStored:
      "Los tokens se almacenan en carteras criptográficas ('allets'). Una clave privada (por ejemplo, una contraseña) es usualmente necesaria para acceder, controlar y/o disolver los tokens que se almacenan en su cartera. Perder el acceso a la clave privada asociada con su cartera podría resultar en la pérdida permanente de su capacidad para acceder y disolver sus tokens.",
    soleResponsible:
      'Usted es el único responsable de implementar todas las medidas razonables y apropiadas para proteger el acceso a su clave privada y su cartera.',
    noCopies:
      'NOSOTROS NO HACEMOS COPIAS DE SU CLAVE PRIVADA. NO SOMOS RESPONSABLES DE NINGUNA PÉRDIDA QUE USTED SUFRA AL NO PODER ACCEDER A SU CARTERA Y/O CLAVE PRIVADA.',
    protocols: '3. PROTOCOLOS',
    tokensRecorded:
      "Los tokens se registran en registros distribuidos (normalmente compartidos por redes de usuarios) que son gobernados, sometidos y distinguidos en función de ciertos conjuntos de reglas conocidas como 'protocolos'.",
    malfunction: '3.1. Falla, ruptura y/o abandono de protocolos',
    breakdown:
      'Cualquier falla, ruptura y/o abandono de los protocolos (y de cualquier mecanismo de consenso, si aplicable) sobre los que los tokens se basan podría afectar gravemente el precio de los tokens así como su capacidad para disolver sus tokens (especialmente si el protocolo depende de una amplia red de usuarios para funcionar correctamente).',
    miningAttacks: '3.2. Ataques de minería',
    someProtocols:
      'Algunos protocolos integran mecanismos basados ​​en el consenso para la validación de transferencias (“Protocolos de Consenso”). Protocolos de consenso son, por lo tanto, susceptibles de ataques en la etapa de validación, donde las transacciones de token son aprobadas por la red. Esto puede afectar la precisión de las transacciones que ocurren en el protocolo, y en sus tokens siendo malversados ​​(por ejemplo, a través de lo que es normalmente denominados ataques de doble gasto).',
    hacking: '3.3. Hacking y debilidades de seguridad',
    expropriation:
      'Los tokens pueden estar sujetos a expropiación y/o robo. Los malos actores (incluidos piratas informáticos, grupos y organizaciones) pueden intentar interferir con los protocolos o los tokens en una variedad de formas, incluyendo, pero no limitado a, ataques de malware, denegación de servicio ataques, ataques basados ​​en consenso, ataques sybil, smurfing y suplantación de identidad',
    furthermore:
      'Además, algunos protocolos se basan en software de código abierto y, como resultado, sujeto al riesgo de que se introduzca una debilidad en el protocolos (ya sea voluntariamente o accidentalmente) en el desarrollo escenario. Cualquier debilidad de este tipo puede ser explotada por malos actores para el propósitos de apropiación indebida de sus tokens, o de otra manera afectar el funcionalidad del protocolo y de su capacidad para disponer de su tokens.',
    noControl:
      'NO TENEMOS CONTROL SOBRE LOS PROTOCOLOS. COMO TAL, NO SEREMOS RESPONSABLE DE CUALQUIER PÉRDIDA QUE SURJA O ESTÉ RELACIONADA CON EL PROTOCOLOS',
    laws: '4. LEYES Y REGLAMENTOS',
    legal:
      'El marco legal y/o regulatorio que rodea a los tokens y La tecnología de registros distribuidos es incierta, no está armonizada y inestable en muchas jurisdicciones.',
    difficult:
      'Es difícil predecir qué marco será aplicable a tokens en un futuro cercano y cómo la implementación de dedicado los marcos legales y/o regulatorios afectarán el precio de los tokens. Un marco legal y regulatorio recientemente introducido puede interferir con o limitar de otro modo su capacidad para retener o disponer de sus tokens, lo que a su vez podría resultar en una pérdida financiera de su parte',
    notResponsibleForLoss:
      'NO SOMOS RESPONSABLES DE NINGUNA PÉRDIDA QUE USTED SUFRA COMO RESULTADO DE LA IMPLEMENTACIÓN DE UN MARCO LEGAL O REGULATORIO',
    tax: '5. IMPUESTOS',
    taxCharacter:
      'La caracterización fiscal de los tokens es compleja y en gran parte incierta. La incertidumbre en el tratamiento fiscal de los tokens puede exponerlo a consecuencias impositivas futuras imprevistas asociadas con la compra,  poseer, vender o usar tokens de otra manera. Deberías buscar impuestos consejos para comprender qué obligaciones fiscales le corresponden cuando comprar, mantener, transferir y utilizar tokens. falta de cumplir con sus obligaciones tributarias podría resultar en severas multas y incluso tiempo en la cárcel.',
    liability:
      'NO SOMOS RESPONSABLES DE NINGUNA PÉRDIDA U OTRA FORMA DE RESPONSABILIDAD QUE SURJA DE O EN CONEXIÓN CON SU INCUMPLIMIENTO CON CUALQUIER RESPONSABILIDAD FISCAL QUE ES O SERÁ APLICABLE A USTED.',
    unanticipated: '6. POSIBLES PÉRDIDAS NO ESPERADAS',
    additionToRisks:
      'Además de los riesgos incluidos en este documento, existen otros riesgos asociados con su compra, tenencia, comercialización y uso de tokens, algunos de los cuales no podemos anticipar. Dichos riesgos pueden      materializarse como variaciones o combinaciones imprevistas de los riesgos discutido en esta sección.',
    notExhaustive:
      'ESTA DECLARACIÓN DE RIESGO NO ES EXHAUSTIVA Y NO DEBERÁ SER LLEVADA A COMPRENDER TODOS LOS RIESGOS INVOLUCRADOS EN LA COMPRA, TENENCIA, NEGOCIACIÓN Y USO DE FICHAS. SUJETOS A LOS TÉRMINOS DEL CLUF, NO ESTAREMOS RESPONSABLE O RESPONSABLE DE CUALQUIER PÉRDIDA SUFRIDA POR USTED COMO RESULTADO DE RIESGOS IMPREVISTOS',
  },
};
