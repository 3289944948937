import React, { useState, useEffect, useRef, useCallback } from 'react';
import Container from '@material-ui/core/Container';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import moment from 'moment-timezone';
import LoadingGif from '../assets/845.gif';
import { useDApp } from '../contexts/web3';
import CreationLoading from '../components/shared/CreationLoading';
import MyCustomUploadAdapterPlugin from '../Util/ImageUploadAdapter';
import { filledInputClasses } from '@mui/material';
import {
  CampaignCreateErrorMsg,
  CampaignCreateHeader,
  CampaignCreateInput,
  CampaignCreateSelect,
  CalendarLabel,
  CampaignCreateTextArea,
} from './pageStyles/CreateCampaignStyles';
// import { createReactEditorJS } from 'react-editor-js';
import ReactEditorJS from '../components/EditorJs/EditorJs';
import { EDITOR_JS_TOOLS } from '../Util/tools';
import HelpIcon from '@mui/icons-material/Help';
import EditorInstruction from '../components/InstructionVideoComponent/EditorInstruction';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../Util/ScrollToTop';
import { useLanguage } from '../contexts/LanguageContext';

const initialState = {
  title: '',
  description: '',
  category: '',
  image: '',
  planStart: '',
  shortDescription: '',
};

const Input = styled('input')({
  display: 'none',
});

const config = {
  extraPlugins: [MyCustomUploadAdapterPlugin],
};

const SITE_KEY = '6LcBl6cfAAAAAGNWBiKqXDXE3-OORvLko2-h6TcP';

const OrganizationForm = () => {
  const [formValue, setFormValue] = useState(initialState);
  const [titleErrMsg, setTitleErrMsg] = useState(null);
  const [categoryErrMsg, setCategoryErrMsg] = useState(null);
  const [shortDescErrMsg, setShortDescErrMsg] = useState(null);
  const [longDescErrMsg, setLongDescErrMsg] = useState(null);
  const [headerImageErrMsg, setHeaderImageErrMsg] = useState(null);
  const [baseImage, setBaseImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [creationLoader, setCreationLoader] = useState(false);
  const [creationMessage, setCreationMessage] = useState('');
  const [questionClicked, setQuestionClicked] = useState(false);
  // const [editor, setEditor] = useState(null);
  const { web3, account, isAdmin, connect, walletState, campaignFactory, jwt } =
    useDApp();
  const { title, category } = formValue;
  const editorCore = useRef(null);

  const { language, getLanguageContent } = useLanguage();
  const [content, setContent] = useState(getLanguageContent('CreateCampTrans'));

  useEffect(() => {
    setContent(getLanguageContent('CreateCampTrans'));
  }, [language]);

  useEffect(() => {
    //load in google recaptcha v3
    const googleRecaptchaId = 'google-recaptcha';
    const loadScriptByUrl = (id, url, callback) => {
      const existingScript = document.getElementById(id);
      if (!existingScript) {
        console.log('Creating Google Recaptcha script');
        const script = document.createElement('script');
        script.src = url;
        script.id = id;
        script.onload = () => {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
    };
    loadScriptByUrl(
      googleRecaptchaId,
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      () => {
        console.log('Google recaptcha loaded');
      }
    );
    return () => {
      document.querySelector(`#${googleRecaptchaId}`).remove();
      document.querySelector('.grecaptcha-badge').parentNode.remove();
    };
  }, []);

  const handleInitialize = useCallback((instance) => {
    editorCore.current = instance;
  }, []);

  useEffect(() => {
    setFormValue((initialState) => ({
      ...initialState,
      planStart: moment().format('YYYY-MM-DD'),
    }));
  }, []);

  const navigate = useNavigate();

  const onTitleChange = (e) => {
    setTitleErrMsg(null);
    setFormValue({ ...formValue, title: e.target.value });
  };

  const onCategoryChange = (e) => {
    setCategoryErrMsg(null);
    setFormValue({ ...formValue, category: e.target.value });
  };

  const onPlanStartChange = (e) => {
    setFormValue({ ...formValue, planStart: e.target.value });
  };

  const onShortDescriptionChange = (e) => {
    setShortDescErrMsg(null);
    setFormValue({ ...formValue, shortDescription: e.target.value });
  };

  const uploadImage = async (e) => {
    setHeaderImageErrMsg(null);
    setLoading(true);
    console.log(e.target.files);
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    const form = new FormData();
    // append file to form
    form.append('image', file);

    try {
      // send form to /api/image-upload
      const response = await fetch('/api/image-upload', {
        method: 'POST',
        body: form,
      });
      const data = await response.json();
      if (!data.error) {
        setFormValue((prev) => ({ ...prev, image: data.file.url }));
        setBaseImage(base64);
        setLoading(false);
      }
    } catch (e) {
      //
      console.log(e);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formValue);
    if (!web3) {
      await connect();
      return false;
    }

    const desc = await editorCore.current.save();
    console.log(desc);

    if (!title) {
      setTitleErrMsg(content.titleCannotBeEmpty);
      return;
    }
    if (!category) {
      setCategoryErrMsg(content.categoryCannotBeEmpty);
      return;
    }

    if (!formValue.shortDescription) {
      setShortDescErrMsg(content.provideShortDesc);
      return;
    }
    if (desc.blocks.length === 0) {
      setLongDescErrMsg(content.provideDesc);
      return;
    }
    if (!formValue.image) {
      setHeaderImageErrMsg(content.provideImage);
      return;
    }

    if (walletState === 'connected') {
      setCreationLoader(true);
    } else {
      toast.error(content.connectWallet);
      return;
    }

    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute(SITE_KEY, { action: 'create_campaign' })
        .then(async function (token) {
          handleCampaignCreation(desc, token);
        });
    });
  };

  const handleCampaignCreation = async (desc, token) => {
    let updatedCampaignData = {
      ...formValue,
      timestamp: moment.utc().toDate(),
      planStart: moment(formValue.planStart).startOf('day').utc().toDate(),
      description: desc,
    };

    let newId;

    const cleanUp = async (error) => {
      setCreationLoader(false);
      console.log(error);
      toast.error(error);
      //if declined delete campaign
      if (newId) {
        await fetch('/api/campaign/' + newId, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
      }
    };

    try {
      // Add your logic to submit to your backend server here.
      const response = await fetch('/api/campaigns', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({ ...updatedCampaignData, token, type: 1 }),
      });
      const data = await response.json();
      console.log(data);
      newId = data.insertedId;
      //now push to blockchain
      const startTime = moment(updatedCampaignData.planStart).utc().unix();

      setCreationMessage(content.waitingApproval);
      campaignFactory.methods
        .createCampaign(
          account,
          0,
          startTime,
          0,
          true,
          newId,
          1
        )
        .send({ from: account })
        .once('transactionHash', (hash) => {
          setCreationMessage(content.pleaseWait);
        })
        .once('confirmation', async (confirmationNumber, receipt) => {
          console.log(receipt);
          if (receipt.status) {
            //now push transaction & newly created campaign address to backend
            const response = await fetch('/api/campaign/' + newId, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
              },
              body: JSON.stringify({
                contractTransaction: receipt,
                contractAddress:
                  receipt.events.CampaignCreated.returnValues.campaign,
              }),
            });

            toast.success(content.campaignCreated);

            setFormValue({
              title: '',
              description: '',
              image: '',
              plan: '',
              planStart: moment().format('YYYY-MM-DD'),
              category: '',
              shortDescription: '',
            });
            navigate('/campaigns');
          } else {
            cleanUp(new Error('Status is not good.'));
          }
          setCreationLoader(false);
        })
        .once('error', (error) => {
          cleanUp(error);
        });
    } catch (error) {
      cleanUp(error);
      return false;
    }
  };

  const uploadBefore = () => {
    //disable button
    setLoading(true);
  };

  const uploadAfter = () => {
    setLoading(false);
  };

  const uploadError = (error) => {
    toast.error(error.message);
  };

  const tools = EDITOR_JS_TOOLS(uploadBefore, uploadAfter, uploadError);

  return (
    <>
      <Helmet>
        <title>I WILL FUND | ORGANIZATION CAMPAIGN CREATION</title>
        <meta name={content.description} content={content.metaContent} />
        <meta name='theme-color' content='#191970' />
      </Helmet>
      <Container maxWidth='md' style={{ marginBottom: '4rem' }}>
        <ScrollToTop />
        {!isAdmin ? (
          <div>
            <h3>{content.sorry}</h3>
          </div>
        ) : (
          <>
            <CampaignCreateHeader>{content.orgTitle}</CampaignCreateHeader>
            <div>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div>
                      {titleErrMsg && (
                        <CampaignCreateErrorMsg>
                          <p>{titleErrMsg}</p>
                        </CampaignCreateErrorMsg>
                      )}
                      <CampaignCreateInput
                        style={{ width: '100%' }}
                        type='text'
                        name='title'
                        value={title || ''}
                        onChange={onTitleChange}
                        placeholder={content.titlePlaceholder}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: '1rem' }}>
                    {headerImageErrMsg && (
                      <CampaignCreateErrorMsg>
                        <p>{headerImageErrMsg}</p>
                      </CampaignCreateErrorMsg>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <label htmlFor='contained-button-file'>
                        <Input
                          style={{ width: '100%' }}
                          accept='image/*'
                          type='file'
                          onChange={(e) => uploadImage(e)}
                          id='contained-button-file'
                        />
                        <Button
                          variant='contained'
                          component='span'
                          style={{
                            backgroundColor: '#f7931a',
                            color: '#fff',
                            letterSpacing: '1.3px',
                          }}
                        >
                          {content.upload}
                        </Button>
                      </label>
                      <label htmlFor='icon-button-file'>
                        <Input
                          accept='image/*'
                          id='icon-button-file'
                          type='file'
                        />
                        <IconButton
                          color='primary'
                          style={{ color: 'midnightblue' }}
                          aria-label='upload picture'
                          component='span'
                        >
                          <PhotoCamera />
                        </IconButton>
                      </label>
                    </div>
                    {baseImage ? (
                      ' '
                    ) : (
                      <div style={{ textAlign: 'center' }}>
                        <p>
                          <span style={{ color: 'red' }}>
                            <sup>*</sup>
                          </span>
                          {content.recommend}
                        </p>
                      </div>
                    )}

                    {loading ? (
                      <div
                        style={{
                          marginTop: '15px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <img
                          src={LoadingGif}
                          alt='loading animation on data load'
                        />
                      </div>
                    ) : (
                      ''
                    )}
                    {baseImage && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: '15px',
                        }}
                      >
                        <br />
                        <img
                          src={baseImage}
                          alt='preview'
                          style={{ width: '200px' }}
                        />
                      </div>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <div>
                      {categoryErrMsg && (
                        <CampaignCreateErrorMsg>
                          <p>{categoryErrMsg}</p>
                        </CampaignCreateErrorMsg>
                      )}
                      <CampaignCreateSelect
                        style={{ width: '100%' }}
                        onChange={onCategoryChange}
                        value={category}
                        placeholder={content.catPlaceholder}
                      >
                        <option value='' style={{ color: '#333' }}>
                          {content.catPlaceholder}
                        </option>
                        <option value='General'>{content.general}</option>
                        <option value='Technology'>{content.tech}</option>
                        <option value='Fire Damage'>{content.fire}</option>
                        <option value='Health'>{content.health}</option>
                        <option value='Medicine'>{content.meds}</option>
                        <option value='Invention'>{content.invent}</option>
                        <option value='Education'>{content.ed}</option>
                        <option value='Environment'>{content.env}</option>
                        <option value='Science'>{content.sci}</option>
                      </CampaignCreateSelect>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div>
                      <CalendarLabel>{content.planStartDate}</CalendarLabel>
                      <CampaignCreateInput
                        onChange={onPlanStartChange}
                        type='date'
                        style={{ width: '100%', marginRight: '4px' }}
                        value={formValue.planStart}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    {shortDescErrMsg && (
                      <CampaignCreateErrorMsg>
                        <p>{shortDescErrMsg}</p>
                      </CampaignCreateErrorMsg>
                    )}
                    <CampaignCreateTextArea
                      style={{ width: '100%' }}
                      onChange={onShortDescriptionChange}
                      placeholder={content.description}
                    ></CampaignCreateTextArea>
                  </Grid>
                  <Grid item xs={12}>
                    {longDescErrMsg && (
                      <CampaignCreateErrorMsg>
                        <p>{longDescErrMsg}</p>
                      </CampaignCreateErrorMsg>
                    )}

                    <p style={{ display: 'inline' }}>{content.longdescAlt}</p>
                    <div style={{ textAlign: 'right' }} title={content.howTo}>
                      <HelpIcon
                        onClick={() => {
                          setQuestionClicked(true);
                        }}
                      />
                    </div>
                    {questionClicked && (
                      <EditorInstruction
                        onClose={() => {
                          setQuestionClicked(false);
                        }}
                      />
                    )}
                    <div
                      style={{
                        border: '1px solid #c4c4c4',
                        padding: '5px',
                        borderRadius: '8px',
                      }}
                    >
                      <ReactEditorJS
                        id='editorjs'
                        onInitialize={handleInitialize}
                        tools={tools}
                      />
                    </div>
                  </Grid>
                </Grid>

                <div className='text-center'>
                  <Button
                    disabled={loading ? true : false}
                    type='submit'
                    style={{
                      width: '100%',
                      background: `${loading ? 'lightgrey' : '#191970'}`,
                      color: '#fff',
                      marginTop: '1rem',
                    }}
                    variant='contained'
                  >
                    {web3 ? (
                      <>{content.createOrgButton}</>
                    ) : (
                      <>{content.connect}</>
                    )}
                  </Button>
                </div>
              </form>
              {account && creationLoader ? (
                <CreationLoading
                  text={creationMessage ? creationMessage : ''}
                />
              ) : (
                ''
              )}
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default OrganizationForm;
