import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Container from '@mui/material/Container';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import Grid from '@material-ui/core/Grid';
import Coin from '../../assets/bnb.png';
import Dollar from '../../assets/dollar.png';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { usePriceApi } from '../../contexts/priceApi';
import { toast } from 'react-toastify';
import LinearProgress from '@mui/material/LinearProgress';
import RefreshIcon from '@mui/icons-material/Refresh';
import CampaignModel from '../../Util/Classes/CampaignModel';

import {
  useDApp,
  campaignAbi,
  network,
  explorer,
  getCampaignDetails,
} from '../../contexts/web3';
import {
  AmountInput,
  StyledModal,
  ProgressWrapper,
  ProgressDone,
  ThumbnailHolder,
} from './DialogStyles';
import moment from 'moment';
import { useLanguage } from '../../contexts/LanguageContext';

function SimpleDialog({ open, onClose, campaign, progressColor, onDonated }) {
  const [amount, setAmount] = useState('');
  const [isPopOpen, setIsPopOpen] = useState(false);
  const { price } = usePriceApi();
  const { account, web3, wrongNetwork, web3R, jwt } = useDApp();
  const [campaignContract, setCampaignContract] = useState(null);
  const [transactionHash, setTransactionHash] = useState(null);
  const [dollarValue, setDollarValue] = useState('');
  const [bnbValue, setBnbValue] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [setDisabledColor, setSetDisabledColor] = useState('#eee!important');
  const [cancelDisable, setCancelDisable] = useState('#f7931a!important');
  const [prevPrice, setPrevPrice] = useState(0);
  const [newDisabledButton, setNewDisabledButton] = useState(false);
  const { language, getLanguageContent } = useLanguage();
  const [content, setContent] = useState(getLanguageContent('DialogTrans'));

  useEffect(() => {
    setContent(getLanguageContent('DialogTrans'));
  }, [language]);

  useEffect(() => {
    //clear values on close and open
    setAmount('');
    setBnbValue('');
    setDollarValue('');
  }, [open]);

  useEffect(() => {
    if (!open) return;
    if (!campaign) return;
    if (!(campaign instanceof CampaignModel)) return;

    if (
      !campaign.contractAddress ||
      campaign.contractAddress === '0x0000000000000000000000000000000000000000'
    ) {
      toast.error(content.doesNotExist);
      handleClose();
      return;
    }
    if (!account) {
      toast.error(content.connectWallet);
      handleClose();
      return;
    }
    if (!campaign.chainInfo) {
      toast.error(content.detailsNotFound);
      handleClose();
      return;
    }
    if (wrongNetwork) {
      toast.error(content.changeNetwork);
      handleClose();
      return;
    }
    if (campaign?.creator === account) {
      toast.error(content.canNotFundOwnCampaign);
      handleClose();
      return;
    }
    // const now = moment();
    // const et = moment.unix(campaign.details.endTime);
    // const st = moment.unix(campaign.details.startTime);

    //check end timestamp
    // if (now > et) {
    // if (now.isAfter(et)) {
    if (campaign.closed) {
      toast.error(content.campaignEnded);
      handleClose();
      return;
    }
    //check start timestamp
    // else if (now.isBefore(st)) {
    else if (campaign.comingSoon) {
      toast.error(content.notStarted);
      handleClose();
      return;
    }
    if (campaign.terminated) {
      toast.error(content.campaignTerminated);
      handleClose();
      return;
    }
    const contract = new web3.eth.Contract(
      campaignAbi,
      campaign.contractAddress
    );
    setCampaignContract(contract);
  }, [open]);

  const hiddenPopModal = () => {
    setIsPopOpen(false);
  };

  const disableButtons = (v) => {
    setNewDisabledButton(v);
    setDisabled(v);
    setSetDisabledColor(!v ? '#191970!important' : '#eee!important');
    setCancelDisable(!v ? '#f7931a!important' : '#eee!important');
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      disableButtons(false);
      onClose();
    }
  };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };

  const handleDonate = async ({ language }) => {
    disableButtons(true);
    if (prevPrice !== price) {
      toast.error(content.priceChange);
      return;
    }
    if (+amount * price >= 4.99) {
      //make sure you have enough funds
      let bal = await web3R.eth.getBalance(account);
      bal = +web3R.utils.fromWei(bal);
      if (bal < +amount) {
        toast.error(content.notEnoughFunds);
        return;
      }
      // setTransactionHash(
      //   '0xba6e9d348197e1d456927ca47d1eb91c1c58e51d65c3aaa1f1e2a20fcebca23c'
      // );
      // handleClose();
      // setIsPopOpen(!isPopOpen);
      // toast.success('Thank you for your donation!');
      //disable buttons
      campaignContract.methods
        .donate()
        .send({ value: web3.utils.toWei(amount, 'ether'), from: account })
        .once('transactionHash', (transactionHash) => {
          //attach to donaters
          fetch('/api/campaign/' + campaign.id + '/donate', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${jwt}`,
            },
            body: JSON.stringify({
              amount: +amount,
              wallet: account,
              timestamp: moment.utc().unix(),
              transactionHash,
            }),
          });
          setTransactionHash(transactionHash);

          handleClose();
          setIsPopOpen(!isPopOpen);
        })
        .once('receipt', (receipt) => {
          if (receipt.status) {
            if (onDonated) onDonated(campaign, amount);
            console.log(receipt);
            toast.success(content.donationConfirmed);
          } else {
            toast.error(content.donationReverted);
          }
        })
        .once('error', (err, receipt) => {
          // toast.error(err.message);
          disableButtons(false);

          toast.error(content.donationReverted);

          console.error(err);
          console.log(receipt);
        });
    } else {
      toast.error(content.fiveDollarMin);
    }
  };

  const error = ' Positive Only ';

  const handleBnbChange = (e) => {
    setBnbValue(e.target.value);
    if (e.target.value < 0) {
      setDollarValue(error);
      setAmount(0);
    } else {
      setDisabled(false);
      setSetDisabledColor('#191970!important');
      setDollarValue((e.target.value * price).toFixed(2));
      setAmount(e.target.value);
    }
    setPrevPrice(price);
  };

  const handleUsdChange = (e) => {
    setDollarValue(e.target.value);
    if (e.target.value < 0) {
      setBnbValue(error);
      setAmount(0);
    } else {
      setDisabled(false);
      setSetDisabledColor('#191970!important');
      const v = (e.target.value / price).toFixed(8);
      setBnbValue(v);
      setAmount(v);
    }
    setPrevPrice(price);
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open} disableScrollLock={true}>
        <Container style={{ marginBottom: '2rem', position: 'relative' }}>
          <DialogTitle style={{ textAlign: 'center' }}>
            <AccountBalanceWalletIcon
              style={{ fontSize: '3rem', display: 'block', margin: '0 auto' }}
            />
            {content.howMuch}
          </DialogTitle>
          {+amount !== 0 && prevPrice !== price && (
            <>
              <div style={{ margin: '0 10px 10px 10px', textAlign: 'center' }}>
                <span
                  style={{
                    color: '#be1d15',
                    marginRight: '10px',
                    fontSize: '.75rem',
                    fontStyle: 'italic',
                  }}
                >
                  {content.priceChange}
                </span>
                <Button
                  variant='text'
                  onClick={() =>
                    handleUsdChange({ target: { value: dollarValue } })
                  }
                >
                  <span
                    style={{
                      color: 'midnightblue',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <RefreshIcon />{' '}
                    <span style={{ marginTop: '4px' }}>{content.refresh}</span>
                  </span>
                </Button>
              </div>
            </>
          )}
          <Grid container spacing={2} style={{ marginBottom: '5px' }}>
            <Grid item xs={6} style={{ position: 'relative' }}>
              <AmountInput
                onChange={handleBnbChange}
                type='number'
                placeholder={
                  bnbValue < 0 || bnbValue === '' ? '  BNB Tokens' : error
                }
                value={bnbValue}
              />
              <div className='coin-holder'>
                <img
                  src={Coin}
                  style={{
                    width: '35px',
                    position: 'absolute',
                    top: '1.1rem',
                    left: '12px',
                  }}
                  alt='coin'
                />
              </div>
            </Grid>
            <Grid item xs={6} style={{ position: 'relative' }}>
              <AmountInput
                onChange={handleUsdChange}
                type='number'
                placeholder={
                  dollarValue < 0 || dollarValue === ''
                    ? content.donatePlaceholder
                    : error
                }
                value={dollarValue}
              />
              <div className='coin-holder'>
                <img
                  src={Dollar}
                  style={{
                    width: '35px',
                    position: 'absolute',
                    top: '1.1rem',
                    left: '12px',
                  }}
                  alt='coin'
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3} style={{ textAlign: 'left' }}>
              <ThumbnailHolder>
                <img src={campaign.image} alt={campaign.title} />
              </ThumbnailHolder>
            </Grid>
            <Grid item xs={9} style={{ textAlign: 'left' }}>
              {campaign.title}
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              {/* <Progress done={percent} progressColor={progressColor} /> */}
              <LinearProgress
                variant='determinate'
                value={parseFloat(campaign?.percent)}
                color={progressColor}
                sx={{
                  color: `${progressColor}`,
                  height: '10px',
                  borderRadius: '8px',
                  marginTop: '.5rem',
                }}
              />
              <div>
                <p style={{ fontSize: '1rem' }}>
                  <strong>
                    {campaign?.totalFunded} BNB /{' '}
                    {campaign.tokensNeeded} BNB
                  </strong>
                </p>
              </div>
            </Grid>
          </Grid>

          <Button
            variant='contained'
            style={{
              backgroundColor: { setDisabledColor },
              marginTop: '.5rem',
              width: '100%',
              color: '#fff',
            }}
            onClick={handleDonate}
            onClose={handleClose}
            disabled={disabled}
          >
            {content.donateButton}
          </Button>
          {newDisabledButton ? (
            <Button
              variant='contained'
              className={!cancelDisable ? 'secondary' : ''}
              style={{
                backgroundColor: { cancelDisable },
                marginTop: '.5rem',
                width: '100%',
                color: '#fff',
              }}
              onClick={handleClose}
              disabled={disabled}
            >
              {content.cancel}
            </Button>
          ) : (
            <Button
              variant='contained'
              className='secondary'
              style={{
                backgroundColor: { cancelDisable },
                marginTop: '.5rem',
                width: '100%',
                color: '#fff',
              }}
              onClick={handleClose}
            >
              {content.cancel}
            </Button>
          )}
        </Container>
      </Dialog>
      <StyledModal show={isPopOpen} handleCloser={hiddenPopModal}>
        <ArrowCircleUpIcon
          style={{
            fontSize: '5rem',
            color: 'darkorange',
          }}
        />
        <h4
          style={{
            color: 'midnightblue',
            marginBottom: '0.5rem',
          }}
        >
          {content.transaction}
        </h4>
        <a
          style={{
            marginBottom: '2rem',
          }}
          href={`${explorer[network]}/tx/${transactionHash}`}
          target='_blank'
        >
          {content.clickToSee}
        </a>
      </StyledModal>
    </>
  );
}

const DialogBox = ({ campaign, buttonText, style, onDonated, language }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant='contained'
        className='campaign-card-buttons'
        style={style}
        onClick={handleClickOpen}
      >
        {buttonText}
      </Button>
      <SimpleDialog
        campaign={campaign}
        open={open}
        onClose={handleClose}
        onDonated={onDonated}
        language={language}
      />
    </div>
  );
};

const Progress = ({ done, progressColor }) => {
  const [style, setStyle] = React.useState({});

  const newStyle = {
    opacity: 1,
    width: `${done}%`,
    background: `${progressColor}`,
  };

  useEffect(() => {
    setStyle(newStyle);
  }, [done, progressColor]);

  return (
    <>
      <ProgressWrapper style={{ width: '100%', margin: '0' }}>
        <ProgressDone style={style}></ProgressDone>
      </ProgressWrapper>
    </>
  );
};

export default DialogBox;
