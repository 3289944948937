import React, { useState, useEffect, useRef, useCallback } from 'react';
import Container from '@material-ui/core/Container';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Switch from 'react-switch';
import moment from 'moment-timezone';
import LoadingGif from '../assets/845.gif';
import CreationLoading from '../components/shared/CreationLoading';
import MyCustomUploadAdapterPlugin from '../Util/ImageUploadAdapter';
import CalculatorDialog from '../components/Dialog/CalculatorDialog';
import Coin from '../assets/bnb.png';
import StateSelector from '../components/shared/StateSelector';
import { filledInputClasses } from '@mui/material';
import {
  CampaignCreateErrorMsg,
  CampaignCreateHeader,
  CampaignCreateInput,
  CampaignCreateSelect,
  CalendarLabel,
  CampaignCreateTextArea,
} from './pageStyles/CreateCampaignStyles';
// import { createReactEditorJS } from 'react-editor-js';
import ReactEditorJS from '../components/EditorJs/EditorJs';
import { EDITOR_JS_TOOLS } from '../Util/tools';
import { useDApp } from '../contexts/web3';
import ScrollToTop from '../Util/ScrollToTop';
import { useLanguage } from '../contexts/LanguageContext';
import { Helmet } from 'react-helmet';

// const ReactEditorJS = createReactEditorJS();

const initialState = {
  title: '',
  description: '',
  category: '',
  image: '',
  stateSelected: '',
  shortDescription: '',
  status: 'active',
};

const Input = styled('input')({
  display: 'none',
});

const EditCampaign = () => {
  const [formValue, setFormValue] = useState(initialState);
  const [titleErrMsg, setTitleErrMsg] = useState(null);
  const [categoryErrMsg, setCategoryErrMsg] = useState(null);
  const [shortDescErrMsg, setShortDescErrMsg] = useState(null);
  const [longDescErrMsg, setLongDescErrMsg] = useState(null);
  const [stateErrMsg, setStateErrMsg] = useState(null);
  const [headerImageErrMsg, setHeaderImageErrMsg] = useState(null);
  const [baseImage, setBaseImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [creationLoader, setCreationLoader] = useState(false);
  const [creationMessage, setCreationMessage] = useState('');
  // const [campaign, setCampaign] = useState(null);
  const { id } = useParams();
  const { account, web3, connect, walletState, jwt } = useDApp();
  const editorCore = useRef(null);
  const { language, getLanguageContent } = useLanguage();
  const [content, setContent] = useState(getLanguageContent('EditCampTrans'));

  useEffect(() => {
    setContent(getLanguageContent('EditCampTrans'));
  }, [language]);

  const handleInitialize = useCallback((instance) => {
    editorCore.current = instance;
  }, []);

  useEffect(() => {
    setFormValue((initialState) => ({
      ...initialState,
      planStart: moment().format('YYYY-MM-DD'),
    }));
  }, []);

  const navigate = useNavigate();

  const onTitleChange = (e) => {
    setTitleErrMsg(null);
    setFormValue({ ...formValue, title: e.target.value });
  };

  const onCategoryChange = (e) => {
    setCategoryErrMsg(null);
    setFormValue({ ...formValue, category: e.target.value });
  };

  const onSelectStateChange = (e) => {
    setStateErrMsg(null);
    setFormValue({ ...formValue, stateSelected: e.target.value });
  };

  const onShortDescriptionChange = (e) => {
    setShortDescErrMsg(null);
    setFormValue({ ...formValue, shortDescription: e.target.value });
  };

  const uploadImage = async (e) => {
    setHeaderImageErrMsg(null);
    setLoading(true);
    console.log(e.target.files);
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    const form = new FormData();
    // append file to form
    form.append('image', file);

    try {
      // send form to /api/image-upload
      const response = await fetch('/api/image-upload', {
        method: 'POST',
        body: form,
      });
      const data = await response.json();
      if (!data.error) {
        setFormValue((prev) => ({ ...prev, image: data.file.url }));
        setBaseImage(base64);
        setLoading(false);
      }
    } catch (e) {
      //
      console.log(e);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    console.log(formValue);
    if (!web3) {
      await connect();
      return false;
    }

    const desc = await editorCore.current.save();
    console.log(desc);

    console.log(formValue);

    if (!formValue.title) {
      setTitleErrMsg(content.titleCannotBeEmpty);
      return;
    }
    if (!formValue.category) {
      setCategoryErrMsg(content.categoryCannotBeEmpty);
      return;
    }

    if (!formValue.stateSelected) {
      setStateErrMsg(content.stateCannotBeEmpty);
      return;
    }
    if (!formValue.shortDescription) {
      setShortDescErrMsg(content.provideShortDesc);
      return;
    }
    if (desc.blocks.length === 0) {
      setLongDescErrMsg(content.provideDesc);
      return;
    }

    if (walletState === 'connected') {
      setCreationLoader(true);
    } else {
      toast.error(content.connectWallet);
      return;
    }

    let updatedCampaignData = {
      ...formValue,
      description: desc,
    };

    // if (!formValue.image) {
    //   delete updatedCampaignData.image;
    // }

    handleCampaignCreation(updatedCampaignData);
  };

  const handleCampaignCreation = async (updatedCampaignData) => {
    try {
      const resp = await fetch('/api/campaign/' + id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify(updatedCampaignData),
      });
      if (resp.status === 401) {
        toast.error(content.unauthorized);
      } else {
        const data = await resp.json();
        if (data.error) {
          toast.error(data?.message ?? data?.error);
        } else {
          let path = `/campaign/${id}/`;
          navigate(path);
          toast.success(content.campaignUpdated);
        }
      }
    } catch (e) {
      toast.error(e.message);
    }
    setCreationLoader(false);
  };

  const uploadBefore = () => {
    //disable button
    setLoading(true);
  };

  const uploadAfter = () => {
    setLoading(false);
  };

  const uploadError = (error) => {
    toast.error(error.message);
  };

  const tools = EDITOR_JS_TOOLS(uploadBefore, uploadAfter, uploadError);

  useEffect(() => {
    if (!id) return null;
    getCampaign();
  }, [id]);

  const getCampaign = async () => {
    try {
      const response = await fetch(`/api/campaign/${id}`);
      const camp = await response.json();
      //fetch the contract details
      setFormValue({
        title: camp.title,
        category: camp.category,
        stateSelected: camp.stateSelected,
        shortDescription: camp.shortDescription,
        description: camp.description,
        image: camp.image,
      });

      console.log(camp);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <ScrollToTop>
        <Helmet>
          <title>I WILL FUND | {content.editTopTitle}</title>
          <meta name={content.description} content={content.metaContent} />
          <meta name='theme-color' content='#191970' />
        </Helmet>
        <Container maxWidth='md' style={{ marginBottom: '4rem' }}>
          <CampaignCreateHeader>{content.editCampaign}</CampaignCreateHeader>
          <div>
            <form onSubmit={handleUpdate}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div>
                    {titleErrMsg && (
                      <CampaignCreateErrorMsg>
                        <p>{titleErrMsg}</p>
                      </CampaignCreateErrorMsg>
                    )}
                    <CampaignCreateInput
                      style={{ width: '100%' }}
                      type='text'
                      name='title'
                      value={formValue?.title || ''}
                      onChange={onTitleChange}
                      placeholder={content.titlePlaceholder}
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div>
                    {categoryErrMsg && (
                      <CampaignCreateErrorMsg>
                        <p>{categoryErrMsg}</p>
                      </CampaignCreateErrorMsg>
                    )}
                    <CampaignCreateSelect
                      style={{ width: '100%' }}
                      onChange={onCategoryChange}
                      value={formValue?.category || ''}
                      placeholder={content.categoryPlaceholder}
                    >
                      <option value='' style={{ color: '#333' }}>
                        {content.categoryPlaceholder}
                      </option>
                      <option value='General'>{content.general}</option>
                      <option value='Technology'>{content.tech}</option>
                      <option value='Fire Damage'>{content.fire}</option>
                      <option value='Health'>{content.health}</option>
                      <option value='Medicine'>{content.meds}</option>
                      <option value='Invention'>{content.invent}</option>
                      <option value='Education'>{content.ed}</option>
                      <option value='Environment'>{content.env}</option>
                      <option value='Science'>{content.sci}</option>
                      {/* {options.map((option, index) => (
                    <option value={option || ''} key={index}>
                      {option}
                    </option>
                  ))} */}
                    </CampaignCreateSelect>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  {stateErrMsg && (
                    <CampaignCreateErrorMsg>
                      <p>{stateErrMsg}</p>
                    </CampaignCreateErrorMsg>
                  )}
                  <CampaignCreateSelect
                    name='state'
                    onChange={onSelectStateChange}
                    style={{
                      padding: '7px',
                      color: '#495057',
                      borderRadius: '5px',
                      width: '100%',
                      border: 'solid 1px #c4c4c4',
                    }}
                    value={formValue?.stateSelected || ''}
                  >
                    <StateSelector />
                  </CampaignCreateSelect>
                </Grid>
                <Grid item xs={12}>
                  {shortDescErrMsg && (
                    <CampaignCreateErrorMsg>
                      <p>{shortDescErrMsg}</p>
                    </CampaignCreateErrorMsg>
                  )}
                  <CampaignCreateTextArea
                    style={{ width: '100%' }}
                    onChange={onShortDescriptionChange}
                    value={formValue?.shortDescription || ''}
                  ></CampaignCreateTextArea>
                </Grid>
                <Grid item xs={12}>
                  {longDescErrMsg && (
                    <CampaignCreateErrorMsg>
                      <p>{longDescErrMsg}</p>
                    </CampaignCreateErrorMsg>
                  )}

                  <p>{content.clickInside}</p>
                  <div
                    style={{
                      border: '1px solid #c4c4c4',
                      padding: '5px',
                      borderRadius: '8px',
                    }}
                  >
                    {/* <div id='editorjs'></div> */}
                    <ReactEditorJS
                      id='editorjs'
                      onInitialize={handleInitialize}
                      tools={tools}
                      value={formValue?.description || ''}
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ marginTop: '1rem' }}>
                {headerImageErrMsg && (
                  <CampaignCreateErrorMsg>
                    <p>{headerImageErrMsg}</p>
                  </CampaignCreateErrorMsg>
                )}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <label htmlFor='contained-button-file'>
                    <Input
                      style={{ width: '100%' }}
                      accept='image/*'
                      type='file'
                      onChange={(e) => uploadImage(e)}
                      id='contained-button-file'
                    />
                    <Button
                      variant='contained'
                      component='span'
                      style={{
                        backgroundColor: '#f7931a',
                        color: '#fff',
                        letterSpacing: '1.3px',
                      }}
                    >
                      {content.uploadHeaderImage}
                    </Button>
                  </label>
                  <label htmlFor='icon-button-file'>
                    <Input accept='image/*' id='icon-button-file' type='file' />
                    <IconButton
                      color='primary'
                      style={{ color: 'midnightblue' }}
                      aria-label='upload picture'
                      component='span'
                    >
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </div>
              </Grid>
              {loading ? (
                <div
                  style={{
                    marginTop: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img src={LoadingGif} alt='loading animation on data load' />
                </div>
              ) : (
                ''
              )}
              {baseImage ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '15px',
                  }}
                >
                  <br />
                  <img
                    src={baseImage}
                    alt='preview'
                    style={{ width: '200px' }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '15px',
                  }}
                >
                  <br />
                  <img
                    src={formValue?.image}
                    alt='preview'
                    style={{ width: '200px' }}
                  />
                </div>
              )}

              <div className='text-center'>
                <Button
                  disabled={loading ? true : false}
                  type='submit'
                  style={{
                    width: '100%',
                    background: `${loading ? 'lightgrey' : '#191970'}`,
                    color: '#fff',
                    marginTop: '1rem',
                  }}
                  variant='contained'
                >
                  {web3 ? content.updateCampaign : content.connectWalletFirst}
                </Button>
              </div>
            </form>
            {account && creationLoader ? (
              <CreationLoading text={creationMessage ? creationMessage : ''} />
            ) : (
              ''
            )}
          </div>
        </Container>
      </ScrollToTop>
    </>
  );
};

export default EditCampaign;
