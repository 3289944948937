import styled from 'styled-components';

export const Label = styled.span`
  font-size: 1rem;
  font-weight: 700;
  color: #333;
`;

export const AddressHolder = styled.span`
  max-width: 425px;
  background: #f8f9f9;
  margin-top: 5px;
  margin-bottom: 1rem;
  padding: 0.4rem;
  border: 1px solid #e8953c;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Container = styled.div`
  width: 95%;
  margin: 0 auto;
`;

export const NotificationBellHolder = styled.div`
  position: absolute;
  top: 26px;
  right: 45px;
  @media (min-width: 600px) {
    top: 5px;
    right: 17.5%;
  }
  @media (min-width: 768px) {
    right: 17%;
  }
  @media (min-width: 800px) {
    right: 16.5%;
  }
  @media (min-width: 950px) {
    right: 16%;
  }
  @media (min-width: 1050px) {
    right: 15.75%;
  }
  @media (min-width: 1125px) {
    right: 15.5%;
  }
  @media (min-width: 1250px) {
    right: 15%;
  }
  @media (min-width: 1350px) {
    right: 14.5%;
  }
  @media (min-width: 1550px) {
    right: 14%;
  }
  @media (min-width: 1650px) {
    right: 13%;
  }
  @media (min-width: 1800px) {
    right: 13%;
  }
  @media (min-width: 2000px) {
    right: 12.5%;
  }
`;
