export default {
  English: {
    descMeta: '404 Not Found page',
    notFoundTitle: 'PAGE NOT FOUND',
    notFound: '404 Not Found',
    notFoundText:
      "We Aren't Hiding It We Swear! We Just Can't Find The Page You Are Looking For.",
    altText: 'Creatures hiding in the woods looking for your page',
  },
  Spanish: {
    descMeta: '404 Página no encontrada',
    notFoundTitle: 'PÁGINA NO ENCONTRADA',
    notFound: '404 No Encontrado',
    notFoundText:
      'No estamos ocultándolo, ¡Nosotros Juramos! Solo no podemos encontrar la página que estás buscando.',
    altText: 'Criaturas escondidas en el bosque buscando tu página',
  },
};
