export default {
  English: {
    home: 'HOME',
    campaigns: 'CAMPAIGNS',
    createCampaign: 'CREATE CAMPAIGN',
    organizations: 'ORGANIZATIONS',
    about: 'ABOUT',
    faqs: 'FAQS',
  },
  Spanish: {
    home: 'INICIO',
    campaigns: 'CAMPAÑAS',
    createCampaign: 'CREAR CAMPAÑA',
    organizations: 'ORGANIZACIÓNES',
    about: 'NOSOTROS',
    faqs: 'FAQS',
  },
};
