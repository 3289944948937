export default {
  English: {
    home: 'HOME',
    descMeta: 'Funding your Dreams with Crypto Currency in I Will Fund',
    welcome: 'Welcome to ',
    mainMessage:
      "Let's change people's dreams/needs via crypto by funding their ideas all through the blockchain. All funding campaigns are handled by smart contracts.",
    startCampaignButton: 'Start a funding campaign',
    exploreButton: 'Explore dreams/needs',
  },
  Spanish: {
    home: 'INICIO',
    descMeta: 'Financiar sus sueños con Crypto Currency en I Will Fund',
    welcome: 'Bienvenido a ',
    mainMessage:
      'Cambia la realidad de las personas a través de la tecnología de la blockchain. Todas las campañas de financiación son gestionadas por contratos inteligentes.',
    startCampaignButton: 'Iniciar una campaña de financiación',
    exploreButton: 'Explora las ideas/necesidades',
  },
};
