import React, { useState, useEffect } from 'react';
import {
  CalcSlider,
  SliderText,
  SliderContainer,
  SliderInput,
} from './CalcSliderStyles';

const CalcSliderComponent = (props) => {
  const [value, setValue] = useState(0);
  const [x, setX] = useState('');
  const [color, setColor] = useState(
    'linear-gradient(90deg, rgb(25, 25, 112)' +
      x +
      '%, rgb(255, 140,0)' +
      x +
      '%)'
  );

  useEffect(() => {
    slideColor();
  }, [value]);

  const slideColor = () => {
    setX(value);
    setColor(
      'linear-gradient(90deg, rgb(25, 25, 112)' +
        x +
        '%, rgb(255, 140,0)' +
        x +
        '%)'
    );
  };

  const handleSliderChange = (e) => {
    setValue(e.target.value);
    if (props.onValueChanged) {
      props.onValueChanged(e.target.value);
    }
  };

  return (
    <CalcSlider>
      <SliderContainer>
        <SliderText>
          <span id='value'>{value}</span>%
        </SliderText>
        <SliderInput
          style={{ background: color }}
          type='range'
          min='0'
          max='100'
          step='1'
          value={value}
          onChange={handleSliderChange}
        />
      </SliderContainer>
    </CalcSlider>
  );
};

export default CalcSliderComponent;
