export default {
  English: {
    descMeta:
      'Privacy Policy for the protection of both iwill.fund and its users',
    privacyP: 'PRIVACY POLICY',
    pPolicy: 'Privacy Policy',
    yPolicy: 'Your Privacy',
    pAndCPolicy: 'Privacy and Cookies Policy',
    update: 'Last Updated: May 15, 2020',
    we: 'We, IWill.Fund',
    aSite: ', a site operated by ChainBlock Labs',
    llc: 'LLC, a limited company registered in Georgia (US) herein referred to as IWill.Fund',
    areCommitted:
      ', are committed to protecting any data that we collect concerning you. By using our services, you agree to the use of the data that we collect in accordance with this Privacy Policy.',
    weCollect: 'We are committed to protecting your privacy.',
    infoCollected:
      'We collect the minimum amount of information about you that is commensurate with providing you with a satisfactory service. This Policy indicates the type of processes that may result in data being collected about you. The purpose of this Privacy Policy to enable you to understand which personal identifying information ("PI",   "Personal Information") of yours is collected, how and when we might use your information, who has access to this information, and how you can correct any inaccuracies in the information. To better protect your privacy, we provide this notice explaining our online information practices and the choices you can make about the way your information is collected and used. To make this notice easy to find, we make it available on our website.',
    moreInfo: 'Information Collected',
    weMayCollect:
      ' We may collect any or all of the information that via both automated means such as communications profiles and cookies. Personal Information you give us depends on the type of service, support, or service inquiry, and may include your name and email address, dates of service provided, types of service provided, payment history,   manner of payment, amount of payments, date of payments, domain name or other payment information. All sensitive information is collected on a secure server and data is transferred. When transferring personal information, a notification will appear in your profile settings.',
    infoUse: 'Information Use',
    thisInfo:
      "This information is used for billing and to provide service and support to our customers. We may also study this information to  determine our customer's needs and provide support for our customers. All reasonable precautions are taken to prevent unauthorized access to this information. This safeguard may require you to provide additional forms of identity should you wish to obtain information about your account details. IWill.Fund",
    mayEmail:
      'may email its monthly newsletter to the primary contact e-mail on file, but customers are able to opt out of this newsletter at any time.',
    ip: 'We use IP addresses to analyze trends, administer our site and servers, track access, and gather broad demographic information for aggregate use. IP addresses are not linked to personally identifiable information. It is possible that personal information about a customer may be included in the log files due to the normal functions of IP addresses and SaaS applications.',
    cookies: 'Cookies',
    yourInternet:
      'Your Internet browser has the in-built facility for storing small text files - "cookies" - that hold information which allows a website to recognize your account. We use cookies to save your preferences and login information as well as provide personalized functionality. You can reject cookies by changing your browser settings but be aware that this will disable some of the functionality on the IWill.Fund',
    website: ' website. More information about cookies can be found at',
  },
  Spanish: {
    descMeta:
      'Política de privacidad para la protección de iwill.fund y sus usuarios',
    privacyP: 'POLÍTICA DE PRIVACIDAD',
    pPolicy: 'Política de Privacidad',
    yPolicy: 'Tu Privacidad',
    pAndCPolicy: 'Política de Privacidad y Cookies',
    update: 'Última actualización: Mayo 15, 2020',
    we: 'Nosotros, IWill.Fund',
    aSite: ', un sitio operado por ChainBlock Labs',
    llc: ', una sociedad limitada registrada en Georgia (EE.UU) aquí referida como IWill.Fund',
    areCommitted:
      ', comprometidos a proteger cualquier dato que nosotros recopilamos sobre usted. Al utilizar nuestros servicios, usted acepta el uso de los datos que recopilamos de acuerdo con esta Política de Privacidad.',
    weCollect: 'Nos comprometemos a proteger su privacidad.',
    infoCollected:
      'Recopilamos la información mínima sobre usted que es comensurable con un servicio satisfactorio. Esta Política indica los tipos de procesos que pueden resultar en la recopilación de información sobre usted. El propósito de esta Política es permitirle comprender qué información personal identificativa ("PI",   "Información Personal") de su información es recopilada, cómo y cuándo podemos utilizar su información, a quién tiene acceso a esta información, y cómo puede corregir cualquier imprecisión en la información. Para proteger su privacidad, nosotros ofrecemos esta nota explicando nuestras prácticas de información en línea y las opciones que puede elegir sobre la forma en que su información es recopilada y utilizada. Para hacer esta nota fácil de encontrar, la hacemos disponible en nuestro sitio web.',
    moreInfo: 'Información Recopilada',
    weMayCollect:
      'Podemos recopilar cualquier o toda la información que vía medios automatizados tales como perfiles de comunicaciones y cookies. La información personal que nos proporciona depende del tipo de servicio, soporte o servicio de consulta, y puede incluir su nombre y dirección de correo electrónico, fechas de servicio proporcionados, tipos de servicio proporcionados, historial de pagos, manera de pago, cantidad de pagos, fecha de pagos, nombre de dominio o otra información de pago. Toda la información sensible se recoge en un servidor seguro y los datos se transfieren. Cuando se transfieren los datos personales, aparecerá una notificación en la configuración de su perfil. ',
    infoUse: 'Uso de la Información',
    thisInfo:
      'Esta información es utilizada para facturación y para proporcionar servicio y soporte a nuestros clientes. Nos comprometemos a proteger su privacidad. Podemos también estudiar esta información para determinar nuestras necesidades de clientes y proporcionar soporte a nuestros clientes. Todas las precauciones razonables se toman para evitar el acceso no autorizado a esta información. Esta seguridad puede requerir que usted proporcione formas adicionales de identificación si desea obtener información sobre sus detalles de cuenta. IWill.Fund',
    mayEmail:
      'Podemos enviar el boletín mensual a la dirección de correo electrónico principal en nuestro registro, pero los clientes pueden optar por no recibir este boletín mensual en cualquier momento.',
    ip: 'Utilizamos direcciones IP para analizar tendencias, administrar nuestro sitio y servidores, rastrear acceso, y recopilar información demográfica amplia para uso agregado. Las direcciones IP no se vinculan a información personal identificativa. Es posible que la información personal de un cliente se incluya en los archivos de registro a través de las funciones de las direcciones IP y aplicaciones SaaS. ',
    cookies: 'Cookies',
    yourInternet:
      'Su navegador tiene la facilidad de almacenar archivos de texto pequeños - "cookies" - que almacenan información que permite que un sitio web reconozca su cuenta. Utilizamos cookies para guardar sus preferencias y información de inicio de sesión, así como para proporcionar funcionalidad personalizada. Puede rechazar cookies por cambiar la configuración de su navegador, pero tenga en cuenta que esto puede desactivar algunas de las funciones en nuestro sitio web. Puede encontrar más información sobre las cookies en',
    website:
      ' el sitio web. Más información sobre las cookies puede encontrarse en',
  },
};
