import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

import 'react-toastify/dist/ReactToastify.css';

import { DAppProvider } from './contexts/web3';
import { PriceApiProvider } from './contexts/priceApi';
import { NotificationsProvider } from './contexts/notifs';
import { LanguagesProvider, useLanguage } from './contexts/LanguageContext';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

import { Helmet } from 'react-helmet';
import Main from './components/Main';
import ScrollToTop from './Util/ScrollToTop';

const theme = createTheme({
  palette: {
    secondary: {
      main: '#f08213',
    },
    primary: {
      main: '#191970',
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1rem',
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#fff',
      },
    },
  },
});

const App = () => {
  return (
    <div className='App'>
      <Helmet>
        <title>I Will Fund</title>
        <meta
          name='description'
          content='Funding your Dreams with Crypto Currency in I Will Fund'
        />
        <meta name='theme-color' content='#191970' />
      </Helmet>
      <Router>
        <MuiThemeProvider theme={theme}>
          <LanguagesProvider>
            <DAppProvider>
              <PriceApiProvider>
                <NotificationsProvider>
                  <ScrollToTop>
                    <Main />
                  </ScrollToTop>
                </NotificationsProvider>
              </PriceApiProvider>
            </DAppProvider>
          </LanguagesProvider>
        </MuiThemeProvider>
      </Router>
    </div>
  );
};

export default App;
