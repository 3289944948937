export default {
  English: {
    wrongNet: ' Wrong Network - Click to switch',
    connect: 'Connect Wallet',
  },
  Spanish: {
    wrongNet: ' Red incorrecta - Da Clic para cambiar',
    connect: 'Conectar Cartera',
  },
};
