import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Carousel from 'react-elastic-carousel';
import CampaignsCreatedCard from '../components/CampaignsICreated/CampaignsCreatedCard';
import CampaignsDonated from '../components/CampaignsIDonated/CampaignsDonated';
import CampaignsClaimable from '../components/CampaignsClaimable/CampaignsClaimable';
import { Container, NotificationBellHolder } from './pageStyles/ProfileStyles';
import Settings from './Settings';
import { useDApp } from '../contexts/web3';
import { useNotifications } from '../contexts/notifs';
import { styled } from '@mui/system';
import BadgeUnstyled, { badgeUnstyledClasses } from '@mui/base/BadgeUnstyled';
import MailIcon from '@mui/icons-material/Mail';
import WalletButton from '../components/WalletButton';
import Coin from '../assets/bnbcoin.gif';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import moment from 'moment';
import { toast } from 'react-toastify';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useLanguage } from '../contexts/LanguageContext';
import { Helmet } from 'react-helmet';

const StyledBadge = styled(BadgeUnstyled)`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-family: IBM Plex Sans, sans-serif;
  position: relative;
  display: inline-block;
  line-height: 1;

  & .${badgeUnstyledClasses.badge} {
    z-index: auto;
    min-width: 20px;
    height: 20px;
    padding: 0 6px;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    text-align: center;
    background: red;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #fff;
    position: absolute;
    top: 0;
    right: 23px;
    transform: translate(50%, -50%);
    transform-origin: 100% 0;
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <span>{children}</span>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const [value, setValue] = useState(0);
  const { notificationsCount, notifications, setNotifications, unseenCount } =
    useNotifications();

  const {
    account,
    getCreatorCampaigns,
    walletState,
    getDonatorCampaigns,
    getClaimables,
    jwt,
  } = useDApp();
  const [campaigns, setCampaigns] = useState([]);
  const [donatorCampaigns, setDonatorCampaigns] = useState([]);
  const [claimables, setClaimables] = useState([]);
  const [loading, setLoading] = useState(false);
  const [markAllAsRead, setMarkAllAsRead] = useState(false);
  const { language, getLanguageContent } = useLanguage();
  const [content, setContent] = useState(getLanguageContent('ProfileTrans'));

  useEffect(() => {
    setContent(getLanguageContent('ProfileTrans'));
  }, [language]);

  useEffect(() => {
    setMarkAllAsRead(unseenCount === 0);
  }, [unseenCount]);

  useEffect(async () => {
    if (value !== 1) return;
    if (walletState !== 'connected') return;
    setLoading(true);
    const camps = await getCreatorCampaigns();
    setCampaigns(camps);
    setLoading(false);
  }, [walletState, account, value]);

  useEffect(async () => {
    if (value !== 2) return;
    if (walletState !== 'connected') return;
    setLoading(true);
    const camps = await getDonatorCampaigns();
    setDonatorCampaigns(camps);
    setLoading(false);
  }, [walletState, account, value]);

  useEffect(async () => {
    if (value !== 3) return;
    if (walletState !== 'connected') return;
    setLoading(true);
    const camps = await getClaimables();
    setClaimables(camps);
    setLoading(false);
  }, [walletState, account, value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 2 },
    { width: 1150, itemsToShow: 3, itemsToScroll: 2 },
    { width: 1450, itemsToShow: 3 },
    { width: 1750, itemsToShow: 4 },
    { width: 2000, itemsToShow: 5 },
  ];

  const label = { inputProps: { 'aria-label': content.markAsRead } };

  useEffect(() => {
    handleRefresh();
  }, []);

  const handleRefresh = () => {
    setLoading(true);
    getClaimables();
    // loading for one second then loading off
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleDelete = async (notification) => {
    setLoading(true);
    const response = await fetch(
      `/api/wallet/${account}/notifications/${notification._id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    setNotifications(notifications.filter((n) => n._id !== notification._id));
    setLoading(false);
    toast.success(content.notifDeleted);
  };

  const handleDeleteAll = async () => {
    if (!window.confirm(content.areYouSure)) return;
    const response = await fetch(`/api/wallet/${account}/notifications/all`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    setNotifications([]);
    toast.success(content.notifDeleted);
  };

  const handleSeen = async (notification) => {
    const seenAt = notification.seenAt;
    //to make it seem like it clicked faster...this is a hack to make the toggle faster
    let index = notifications.findIndex(
      (notif) => notif._id === notification._id
    );
    if (index > -1) {
      notifications[index].seenAt = !seenAt ? moment().toDate() : null;
      setNotifications([...notifications]);
      setMarkAllAsRead(
        notifications.filter((notif) => !notif.seenAt).length === 0
      );
    }

    //now let it do the real action
    const response = await fetch(
      `/api/wallet/${account}/notifications/${notification._id}/mark-seen/${!seenAt ? '1' : '0'
      }`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    const data = await response.json();
    index = notifications.findIndex((notif) => notif._id === data._id);
    if (index > -1) {
      notifications[index] = data;
      setNotifications([...notifications]);
    }
  };

  const handleMarkAllAsRead = async () => {
    const seenAt = markAllAsRead;
    //to make it seem like it clicked faster...this is a hack to make the toggle faster
    notifications.forEach(
      (notif) => (notif.seenAt = !seenAt ? moment().toDate() : null)
    );
    setNotifications([...notifications]);
    setMarkAllAsRead(!markAllAsRead);

    setLoading(true);
    const response = await fetch(
      `/api/wallet/${account}/notifications/mark-seen-all/${!markAllAsRead ? '1' : '0'
      }`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    const data = await response.json();
    setNotifications([...data]);
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>I WILL FUND | {content.profileTopTitle}</title>
        <meta name='description' content={content.descMeta} />
        <meta name='theme-color' content='#191970' />
      </Helmet>
      <Container>
        <h1 style={{ fontWeight: '500' }}>{content.profile}</h1>
      </Container>
      {!account ? (
        <div style={{ width: '50%', margin: '20px' }}>
          <WalletButton />
        </div>
      ) : (
        <Box component={'div'} sx={{ width: '100%' }}>
          <Box
            component={'div'}
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
            }}
          >
            <Tabs
              variant='scrollable'
              scrollButtons
              allowScrollButtonsMobile
              value={value}
              onChange={handleChange}
              aria-label='basic tabs example'
            >
              <Tab label={content.profile} {...a11yProps(0)} />
              <Tab label={content.created} {...a11yProps(1)} />
              <Tab label={content.donated} {...a11yProps(2)} />
              <Tab label={content.claimable} {...a11yProps(3)} />

              <Tab
                label={
                  unseenCount && account ? (
                    <StyledBadge badgeContent={unseenCount}>
                      <MailIcon style={{ color: 'grey' }} />
                    </StyledBadge>
                  ) : (
                    <MailIcon style={{ color: 'grey' }} />
                  )
                }
                {...a11yProps(4)}
              />
            </Tabs>
          </Box>

          <TabPanel component={'div'} value={value} index={0}>
            {account ? <Settings language={language} /> : <WalletButton />}
          </TabPanel>
          <TabPanel component={'div'} value={value} index={1}>
            <Container>
              {loading ? (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={Coin}
                    style={{ width: '100px' }}
                    alt='coin spinner loading'
                  />
                </div>
              ) : (
                <>
                  {account ? (
                    <Carousel breakPoints={breakPoints} itemPadding={[10, 15]}>
                      {campaigns.map((campaign) => (
                        <CampaignsCreatedCard
                          campaign={campaign}
                          id={campaign.id}
                          key={campaign.id}
                        />
                      ))}
                    </Carousel>
                  ) : (
                    <WalletButton />
                  )}
                </>
              )}
            </Container>
          </TabPanel>
          <TabPanel component={'div'} value={value} index={2}>
            {loading ? (
              <div style={{ textAlign: 'center' }}>
                <img
                  src={Coin}
                  style={{ width: '100px' }}
                  alt='coin spinner loading'
                />
              </div>
            ) : (
              <>
                {account ? (
                  <Carousel breakPoints={breakPoints} itemPadding={[10, 15]}>
                    {donatorCampaigns.map((donatorCampaign) => (
                      <CampaignsDonated
                        campaign={donatorCampaign}
                        id={donatorCampaign.id}
                        key={donatorCampaign.id}
                      />
                    ))}
                  </Carousel>
                ) : (
                  <WalletButton />
                )}
              </>
            )}
          </TabPanel>
          <TabPanel component={'div'} value={value} index={3}>
            {loading ? (
              <div style={{ textAlign: 'center' }}>
                <img
                  src={Coin}
                  style={{ width: '100px' }}
                  alt='coin spinner loading'
                />
              </div>
            ) : (
              <>
                {account ? (
                  <>
                    <div
                      style={{ margin: '0 10px 10px 10px', textAlign: 'left' }}
                    >
                      <span
                        style={{
                          color: 'green',
                          marginRight: '10px',
                          fontSize: '.75rem',
                          fontStyle: 'italic',
                        }}
                      >
                        {content.refresh}
                      </span>
                      <Button variant='text' onClick={handleRefresh}>
                        <span
                          style={{
                            color: 'midnightblue',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <RefreshIcon />{' '}
                          <span style={{ marginTop: '4px' }}>
                            {content.refreshText}
                          </span>
                        </span>
                      </Button>
                    </div>
                  </>
                ) : (
                  ''
                )}
                {account && claimables.length > 0 ? (
                  <>
                    <CampaignsClaimable
                      claimables={claimables}
                      setClaimables={setClaimables}
                    />
                  </>
                ) : (
                  <>{content.noClaim}</>
                )}
              </>
            )}
          </TabPanel>
          <TabPanel component={'div'} value={value} index={4}>
            {notifications && account && notifications.length > 0 ? (
              <>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: '100%', overflow: 'scroll' }}>
                    <TableHead style={{ whiteSpace: 'nowrap' }}>
                      <TableRow>
                        <TableCell>
                          <Button
                            variant='contained'
                            style={{ background: '#c04343', color: 'white' }}
                            onClick={() => handleDeleteAll()}
                          >
                            {content.deleteAll}
                          </Button>
                        </TableCell>
                        <TableCell>{content.timeStamp}</TableCell>
                        <TableCell>{content.title}</TableCell>
                        <TableCell>{content.notificationType}</TableCell>
                        <TableCell>{content.info}</TableCell>
                        <TableCell>
                          {markAllAsRead ? (
                            <>{content.markAllAsUnread}</>
                          ) : (
                            <>{content.markAllAsRead}</>
                          )}
                          <Checkbox
                            checked={markAllAsRead}
                            onClick={handleMarkAllAsRead}
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {notifications.map((notification) => (
                        <TableRow
                          key={notification._id}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          <TableCell>
                            <Button variant='text'>
                              <DeleteForeverIcon
                                style={{ color: '#c04343' }}
                                onClick={() => handleDelete(notification)}
                              />
                            </Button>
                          </TableCell>
                          <TableCell>
                            {moment(notification.timestamp).format(
                              'MM/DD/YYYY hh:mm:ss A'
                            )}
                          </TableCell>
                          <TableCell>
                            <Link
                              to={`/campaign/${notification.data.campId}`}
                              target='_blank'
                              style={{ textDecoration: 'none' }}
                            >
                              <h4>{notification.data.title}</h4>
                            </Link>
                          </TableCell>
                          <TableCell>{notification.type}</TableCell>
                          <TableCell>
                            {notification.type === 'aboutToClose'
                              ? moment(notification.data.planEnd)
                                .locale(language)
                                .fromNow()
                              : notification.data.claimAmount}
                          </TableCell>
                          <TableCell>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={notification.seenAt ? true : false}
                                  />
                                }
                                // onChange={() => {
                                //   // checked = !checked;
                                //   setChecked(!checked);
                                // }}
                                onClick={() => handleSeen(notification)}
                                label={
                                  notification.seenAt ? (
                                    <>{content.read}</>
                                  ) : (
                                    <>{content.unread}</>
                                  )
                                }
                              />
                            </FormGroup>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <>{content.noNotifications}</>
            )}
          </TabPanel>
        </Box>
      )}
    </>
  );
}
