import React from 'react';
import { Link } from 'react-router-dom';
import {
  ProfileCardsSingle,
  ProfileTitle,
  ProfileText,
  ProfileImageHolder,
  ProfileImage,
  ProfileSocialHolders,
  ProfileSocialLink,
} from './ProfileCardsStyles';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const ProfileCardSingle = (props) => {
  return (
    <ProfileCardsSingle>
      <ProfileTitle>{props.title}</ProfileTitle>
      <ProfileText>{props.text}</ProfileText>
      <ProfileImageHolder>
        <ProfileImage src={props.image} alt={props.alt} />
      </ProfileImageHolder>
      <ProfileSocialHolders>
        <ProfileSocialLink href={props.link} target='_blank'>
          <TwitterIcon fontSize='large' />
        </ProfileSocialLink>
        <ProfileSocialLink href={props.link2} target='_blank'>
          <FacebookIcon fontSize='large' />
        </ProfileSocialLink>
        <ProfileSocialLink href={props.link3} target='_blank'>
          <LinkedInIcon fontSize='large' />
        </ProfileSocialLink>
      </ProfileSocialHolders>
    </ProfileCardsSingle>
  );
};

export default ProfileCardSingle;
