export default {
  English: {
    editTopTitle: 'EDIT CAMPAIGN',
    titleCannotBeEmpty: 'Title Cannot Be Empty',
    categoryCannotBeEmpty: 'Category Cannot Be Empty',
    stateCannotBeEmpty: 'State Field Cannot be Empty',
    provideShortDesc: 'Please Provide a Short Description',
    provideDesc: 'Please Provide a Description',
    connectWallet: 'Please Connect to Your Wallet',
    unauthorized: 'Unauthorized to update campaign',
    campaignUpdated: 'Campaign has been updated',
    editCampaign: 'Edit Campaign',
    titlePlaceholder: 'Title of funding request',
    categoryPlaceholder: 'Please Select a Category',
    clickInside: 'Click Inside box to edit your campaign',
    uploadHeaderImage: 'Upload Header Image',
    updateCampaign: 'Update Campaign',
    connectWalletFirst: 'Connect to Wallet First!',
    general: 'General',
    tech: 'Technology',
    fire: 'Fire Damage',
    health: 'Health',
    meds: 'Medicine',
    invent: 'Invention',
    ed: 'Education',
    env: 'Environment',
    sci: 'Science',
  },
  Spanish: {
    editTopTitle: 'EDITAR CAMPAÑA',
    titleCannotBeEmpty: 'El titulo no puede estar vacio',
    categoryCannotBeEmpty: 'La categoria no puede estar vacia',
    stateCannotBeEmpty: 'El campo de estado no puede estar vacio',
    provideShortDesc: 'Por favor proporcione una descripcion corta',
    provideDesc: 'Por favor proporcione una descripcion',
    connectWallet: 'Por favor conectese a su wallet',
    unauthorized: 'No autorizado para actualizar la campaña',
    campaignUpdated: 'La campaña ha sido actualizada',
    editCampaign: 'Editar campaña',
    titlePlaceholder: 'Titulo de la solicitud de financiamiento',
    categoryPlaceholder: 'Por favor seleccione una categoria',
    clickInside: 'Haga clic en el cuadro de entrada para editar su campaña',
    uploadHeaderImage: 'Subir imagen de cabecera',
    updateCampaign: 'Actualizar campaña',
    connectWalletFirst: '¡Conectese a su wallet primero!',
    general: 'General',
    tech: 'Tecnología',
    fire: 'Daño a la Fuego',
    health: 'Salud',
    meds: 'Medicina',
    invent: 'Invención',
    ed: 'Educación',
    env: 'Medio Ambiente',
    sci: 'Ciencia',
  },
};
