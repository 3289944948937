import React, { useEffect, useState } from 'react';
import { FooterComponentStyled } from './pageStyles/FooterStyles';
import { Link } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import styled from 'styled-components';
import { FiLinkedin, FiTwitter, FiFacebook } from 'react-icons/fi';

const SocialMedia = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  a {
    text-decoration: none;
    color: #fff;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const Footer = (props) => {
  const { language, getLanguageContent } = useLanguage();
  const [content, setContent] = useState(getLanguageContent('FooterTrans'));

  useEffect(() => {
    setContent(getLanguageContent('FooterTrans'));
  }, [language]);

  return (
    <FooterComponentStyled>
      <div>
        <span>
          IWill.Fund
          <span style={{ fontSize: '9px', verticalAlign: 'super' }}>
            <sup>TM</sup>
          </span>{' '}
          {content.division}
          <br />
          <a
            href='https://chainblocklabs.com'
            target='_blank'
            style={{ textDecoration: 'none' }}
          >
            <span style={{ color: '#f08213' }}>
              ChainBlock Labs
              <span style={{ fontSize: '9px', verticalAlign: 'super' }}>
                <sup>TM</sup>
              </span>{' '}
            </span>
          </a>
          <br />
          &copy;2022
        </span>
      </div>

      <Link
        to='/terms-of-service'
        style={{ color: '#fff', textDecoration: 'none' }}
      >
        {content.terms}
      </Link>
      <Link
        to='/privacy-policy'
        style={{ color: '#fff', textDecoration: 'none' }}
      >
        {content.privacy}
      </Link>
      <SocialMedia>
        <div>
          <a
            href='https://www.linkedin.com/company/iwill-fund/'
            target='_blank'
          >
            <FiLinkedin size={30} />
          </a>
        </div>
        <div>
          <a href='https://www.facebook.com/IWillFund' target='_blank'>
            <FiFacebook size={30} />
          </a>
        </div>
        <div>
          <a href='https://twitter.com/IWillFund1' target='_blank'>
            <FiTwitter size={30} />
          </a>
        </div>
      </SocialMedia>
    </FooterComponentStyled>
  );
};

export default Footer;
