export default {
  English: {
    createTopTitle: 'CREATE CAMPAIGN',
    sorry: 'We are sorry. You seem to have taken a wrong turn.',
    orgTitle: 'Organization Funding Request',
    create: 'Create Funding Request!',
    titlePlaceholder: 'Title of funding request',
    upload: 'Upload Header Image',
    recommend: 'Recommended image size: 290px x 290px',
    calculate: 'Calculate how many tokens you will need with this',
    calculator: 'CAMPAIGN CALCULATOR',
    catPlaceholder: 'Please Select a Category',
    planStartDate: 'Plan Start Date',
    planEndDate: 'Plan End Date',
    state: 'Select a State / Region',
    description: 'Provide a short description of your campaign',
    longdescAlt: 'Describe the Organization Campaign Details',
    longdesc:
      'Click inside box to write an awesome story that gets you funded!',
    receive: 'Receive Donation if Goal Not Met.',
    createOrgButton: 'Create Organization Campaign',
    createButton: 'CREATE CAMPAIGN',
    howTo: 'How do I use the Editor? CLICK ME',
    connect: 'CONNECT WALLET FIRST!',
    titleCannotBeEmpty: 'Title Cannot Be Empty',
    categoryCannotBeEmpty: 'Category Cannot Be Empty',
    provideHowManyTokens: 'You Must Provide How Many Tokens Needed',
    provideCampaignEndDate: 'You Must Provide Campaign End Date',
    stateCannotBeEmpty: 'State Field Cannot be Empty',
    provideShortDesc: 'Please Provide a Short Description',
    provideDesc: 'Please Provide a Description',
    provideImage: 'Please Provide an Image',
    connectWallet: 'Please Connect to Your Wallet',
    waitingApproval: 'Waiting on approval to create the campaign',
    pleaseWait: 'Please Wait While We Create Your Campaign',
    campaignCreated: 'Campaign created successfully',
    description: 'Description',
    general: 'General',
    tech: 'Technology',
    fire: 'Fire Damage',
    health: 'Health',
    meds: 'Medicine',
    invent: 'Invention',
    ed: 'Education',
    env: 'Environment',
    sci: 'Science',
    metaContent:
      'This page allows the visitor to create a funding campaign by filling out the title of their campaign, category, their state where the campaign is taking place, a handy calculator to calculate how much to ask for, when they would like to start and end their campaign, a short description fo the campaigns as well as a nice rich editor for adding styled text as well as videos and images. Also there is a place to add their campaign main image. If a campaign creator would like to receive the funds weather they have met their goal or not, they can choose to receive the funds regardless.',
  },
  Spanish: {
    createTopTitle: 'CREAR CAMPAÑA',
    sorry: 'Lo sentimos. Parece que has llegado a una dirección equivocada.',
    orgTitle: 'Organización | Solicitud de financiamiento',
    create: 'Crear Solicitud de Financiamiento!',
    titlePlaceholder: 'Título de la solicitud de financiamiento',
    upload: 'Subir Imagen de Encabezado',
    recommend: 'Tamaño recomendado: 290px x 290px',
    calculate: 'Calcular cuántos tokens necesitarás con este',
    calculator: 'CALCULADORA DE CAMPAÑAS',
    catPlaceholder: 'Seleccione una categoría',
    planStartDate: 'Fecha de Inicio',
    planEndDate: 'Fecha de Finalización',
    state: 'Seleccione un Estado / Región',
    description: 'Proporcione una breve descripción de su campaña',
    longdescAlt: 'Describa los detalles de la campaña de la organización',
    longdesc:
      'Haga clic en el cuadro para escribir una historia increíble que te lleve a ser financiado!',
    receive: 'Recibir Donación si la Meta No Se Cumple.',
    howTo: '¿Cómo uso el Editor? HAGA CLICK',
    createOrgButton: 'Crear Campaña Org',
    createButton: 'CREAR CAMPAÑA',
    connect: 'CONECTAR LA CARTERA PRIMERO!',
    titleCannotBeEmpty: 'El título no puede estar vacío',
    categoryCannotBeEmpty: 'La categoría no puede estar vacía',
    provideHowManyTokens: 'Debe proporcionar cuántos tokens necesita',
    provideCampaignEndDate:
      'Debe proporcionar la fecha de finalización de la campaña',
    stateCannotBeEmpty: 'El campo de estado no puede estar vacío',
    provideShortDesc: 'Proporcione una breve descripción',
    provideDesc: 'Proporcione una descripción',
    provideImage: 'Proporcione una imagen',
    connectWallet: 'Conecte su cartera primero',
    waitingApproval: 'Esperando aprobación para crear la campaña',
    pleaseWait: 'Por favor espere mientras creamos su campaña',
    campaignCreated: 'Campaña creada con éxito',
    description: 'Descripción',
    general: 'General',
    tech: 'Tecnología',
    fire: 'Daño de Fuego',
    health: 'Salud',
    meds: 'Medicina',
    invent: 'Invención',
    ed: 'Educación',
    env: 'Medio Ambiente',
    sci: 'Ciencia',
    metaContent:
      'Esta página le permite al visitante crear una campaña de financiamiento llenando el título de su campaña, categoría, su estado donde se encuentra la campaña, una calculadora útil para calcular cuánto se pide, cuando quiere comenzar y terminar su campaña, una breve descripción de la campaña así como un editor de texto enriquecido con estilos y imágenes. También hay un lugar para agregar su imagen principal de la campaña. Si un creador de campaña quiere recibir los fondos incluso si no ha alcanzado su meta, puede elegir recibir los fondos de todos modos.',
  },
};
