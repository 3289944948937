import React, { useState, useEffect } from 'react';
import Header from '../components/header/Header';
import Button from '../components/shared/Button/Button';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CampaignsCarousel from '../components/CampaignCarousel/CampaignsCarousel';
import About from './About';
import Faqs from '../components/Faqs/Faqs';
import './pageStyles/HomeStyles.css';
import BackToTop from '../components/shared/BackToTop';
import { Helmet } from 'react-helmet';
import { useLanguage } from '../contexts/LanguageContext';

export default function Home(props) {
  const { language, getLanguageContent } = useLanguage();
  const [content, setContent] = useState(getLanguageContent('HomeTrans'));

  useEffect(() => {
    setContent(getLanguageContent('HomeTrans'));
  }, [language]);

  return (
    <>
      <Helmet>
        <title>I WILL FUND | {content.home}</title>
        <meta name='description' content={content.descMeta} />
        <meta name='theme-color' content='#191970' />
      </Helmet>
      <BackToTop />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              alignContent: 'center',
              textAlign: 'center',
            }}
          >
            <Header />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              alignContent: 'center',
              textAlign: 'center',
            }}
            className='home-box'
          >
            <h1 style={{ color: '#757575' }}>
              <span style={{ color: '#191970', letterSpacing: '-2px' }}>
                {content.welcome}
              </span>{' '}
              <br />
              <span className='main-title'>
                I Will Fund
                <span
                  style={{
                    fontSize: '10px',
                    verticalAlign: 'super',
                    marginTop: '-8px',
                    marginLeft: '2px',
                    letterSpacing: '1px',
                  }}
                >
                  <sup>TM</sup>
                </span>
              </span>
              <span
                style={{
                  color: '#191970',
                  lineSpacing: '5px',
                  letterSpacing: '1.2px',
                  fontSize: '42px',
                  fontStyle: 'italic',
                }}
              ></span>
            </h1>
            <Container>
              <p
                style={{
                  fontSize: 22,
                  lineHeight: 1.5,
                  paddingLeft: '20px',
                  paddingRight: '20px',
                }}
              >
                {content.mainMessage}
              </p>
            </Container>
            <div>
              <Button type='button' buttonStyle='btn--primary--solid'>
                <Link
                  to='/create-campaign'
                  style={{ textDecoration: 'none', color: 'white' }}
                >
                  {content.startCampaignButton}
                </Link>
              </Button>
              <Button type='button' buttonStyle='btn--secondary--solid'>
                <Link
                  to='/campaigns'
                  style={{ textDecoration: 'none', color: 'white' }}
                >
                  {content.exploreButton}
                </Link>
              </Button>
            </div>
          </Box>
        </Grid>
      </Grid>
      <CampaignsCarousel language={props.language} />
      <div
        id='about'
        style={{ paddingTop: '30px', background: '#b0b0b0' }}
      ></div>
      <About language={props.language} />
      <div
        id='faqs'
        style={{ paddingTop: '30px', background: '#b0b0b0' }}
      ></div>
      <Faqs language={props.language} />
    </>
  );
}
