import styled from 'styled-components';

export const LegalHeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  background-color: #505050;
  padding-top: 3rem;
  padding-bottom: 3rem;
`;

export const LegalContainer = styled.div`
  width: 90%;
  margin: 0 auto;
`;

export const LegalTitle = styled.h1`
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 500;
  color: #fff;
`;

export const LegalSubTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
`;

export const LegalTextHeading = styled.h4`
  font-size: 1.3rem;
  font-weight: bold;
  color: #fff;
`;

export const LegalText = styled.p`
  font-size: 1.1rem;
  color: #fff;
`;

export const LegalBodySection = styled.div`
  display: flex;
  background-color: #333;
`;
