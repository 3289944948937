import React from 'react';
import styled from 'styled-components';

const ContainerStyle = styled.div`
  /* width: 95%;
  margin: 0 auto; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    font-family: 'Open Sans', sans-serif;
  }
`;

const Container = ({ children }) => {
  return <ContainerStyle>{children}</ContainerStyle>;
};

export default Container;
