import styled from 'styled-components';

export const NotFoundPageContainer = styled.div`
  width: 80%;
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const NotFoundImageHolder = styled.div`
  height: 100%;
  position: relative;
  text-align: center;
`;

export const FourOFourImage = styled.img`
  width: 75%;
  text-align: center;
  margin: 0 auto;
`;

export const FourOFourText = styled.h1`
  color: darkorange;
  font-size: 1.5rem;
  font-weight: normal;
  position: absolute;
  top: -40%;
  right: 10%;
  z-index: 1;
  @media (min-width: 350px) {
    top: -20%;
    right: 0;
  }
  @media (min-width: 380px) {
    top: -10%;
    right: 0;
  }
  @media (min-width: 400px) {
    top: -5%;
    right: 15%;
  }
  @media (min-width: 500px) {
    top: 0;
    right: 5rem;
  }
`;

export const NotFoundText = styled.h3`
  color: #191970;
  text-align: center;
  font-size: 1.5rem;
  font-weight: normal;
  @media (max-width: 500px) {
    font-size: 1.2rem;
  }
`;
