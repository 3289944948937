export default {
  English: {
    terminate: 'Terminate Campaign',
    terminateMsg:
      'Are you sure you want to Terminate this campaign. Once you do, it is permanent and cannot be undone. If you want to terminate this campaign, click confirm below.',
    cancel: 'Cancel',
    confirm: 'Confirm',
    pleaseApprove: 'Please Approve Transaction to Continue...',
    terminating: 'Terminating...',
    terminated: 'Campaign Terminated!',
    errorClosingEarly: 'Error Closing Early',
  },
  Spanish: {
    terminate: 'Terminar Campaña',
    terminateMsg:
      '¿Estás seguro de que quieres terminar esta campaña? Una vez que lo hagas, no se puede deshacer. Si quieres terminar esta campaña, haz clic en confirmar.',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    pleaseApprove: 'Por favor, aproveche la transacción para continuar...',
    terminating: 'Terminando...',
    terminated: 'Campaña Terminada!',
    errorClosingEarly: 'Error cerrando temprano',
  },
};
