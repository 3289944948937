import React, { createContext, useState, useEffect, useContext } from 'react';
import { useDApp } from './web3';

const NotificationsContext = createContext();

export function useNotifications() {
  return useContext(NotificationsContext);
}

export function NotificationsProvider({ children }) {
  const [notifications, setNotifications] = useState([]);
  const { account, walletState, jwt } = useDApp();

  const getNotifications = async () => {
    // query db for notifications
    const response = await fetch(`/api/wallet/${account}/notifications`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    const ar = await response.json();
    setNotifications(ar);
  };

  useEffect(() => {
    if (!account) return null;
    if (walletState !== 'connected') return null;
    const interval = setInterval(() => {
      getNotifications();
    }, 10 * 60 * 1000);
    getNotifications();
    return () => clearInterval(interval);
  }, [account, walletState]);

  return (
    <NotificationsContext.Provider value={{
      notifications,
      notificationsCount: notifications.length,
      unseenCount: notifications.filter(notif => !notif.seenAt).length,
      setNotifications,
    }}>
      {children}
    </NotificationsContext.Provider >
  );
}