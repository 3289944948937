import Access from "./abi/Access.json";
import ServiceEscrow from "./abi/ServiceEscrow.json";
import CampaignFactory from "./abi/CampaignFactory.json";
import Campaign from "./abi/Campaign.json";
import PriceConsumerV3 from "./abi/PriceConsumerV3.json";
import Multicall from "./abi/Multicall.json";
import CCampaign from "./abi/CCampaign.json";
import Organization from "./abi/Organization.json";

export default {
  "Access": {
    "abi": Access,
    "address": {
      "test": "0x22dfAdcfaa6Edfa912E349A603770bB556888007",
      "live": "",
    }
  },
  "ServiceEscrow": {
    "abi": ServiceEscrow,
    "address": {
      "test": "0xa008AF05F956e73577789605958Dd66a4662F001",
      "live": "",
    }
  },
  "CampaignFactory": {
    "abi": CampaignFactory,
    "address": {
      "test": "0xA9f6943a683eB75E5fCf94C69c16af42dE096986",
      "live": "",
    }
  },
  "CCampaign": {
    "abi": CCampaign
  },
  "Campaign": {
    "abi": Campaign
  },
  "Organization": {
    "abi": Organization
  },
  "PriceConsumerV3": {
    "abi": PriceConsumerV3,
    "address": {
      "test": "0x28B578dC56902BF92356622be5fc52fF5113e879",
      "live": "",
    }
  },
  "Multicall": {
    "abi": Multicall,
    "address": {
      "test": "0xB063e9dF3Fa0bb060ba08FD4cF1B7A1707c66611",
      "live": "",
    }
  }
}