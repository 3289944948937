export default {
  English: {
    howMuch: 'How much would you like to donate?',
    priceChange: 'The price has changed',
    refresh: 'Refresh',
    donatePlaceholder: ' Donate USD',
    donateButton: 'DONATE',
    cancel: 'CANCEL',
    transaction: 'Transaction Submitted',
    clickToSee: 'Click to see the transaction on bscscan',
    priceChange: 'Price has changed. Please refresh inputs.',
    doesNotExist: 'Campaign does not exist',
    connectWallet: 'Please Connect Wallet',
    detailsNotFound: 'Campaign details not found',
    changeNetwork: 'Please change the network of your wallet',
    canNotFundOwnCampaign: 'You can not fund your own campaign',
    campaignEnded: 'Campaign ended',
    notStarted: 'Campaign not started yet',
    campaignTerminated: 'Campaign terminated',
    notEnoughFunds: 'You do not have enough funds',
    donationConfirmed: 'Donation confirmed - Thank you for your donation!',
    donationReverted: 'Donation transaction reverted',
    fiveDollarMin: 'You must donate at least $5',
  },
  Spanish: {
    howMuch: '¿Cuánto deseas donar?',
    priceChange: 'El precio ha cambiado',
    refresh: 'Actualizar',
    donatePlaceholder: ' Donar USD',
    donateButton: 'DONAR',
    cancel: 'CANCELAR',
    transaction: 'Transacción enviada',
    clickToSee: 'Haga clic para ver la transacción en bscscan',
    priceChange: 'El precio ha cambiado. Por favor, actualice las entradas.',
    doesNotExist: 'La campaña ya no existe',
    connectWallet: 'Por favor, conecte la cartera',
    detailsNotFound: 'Detalles de la campaña no encontrados',
    changeNetwork: 'Por favor, cambie la red de su cartera',
    canNotFundOwnCampaign: 'No puede financiar su propia campaña',
    campaignEnded: 'Campaña terminada',
    notStarted: 'La campaña no ha comenzado todavía',
    campaignTerminated: 'Campaña terminada',
    notEnoughFunds: 'No tiene fondos suficientes',
    donationConfirmed: 'Donación confirmada - ¡Gracias por su donación!',
    donationReverted: 'La transacción de donación se ha revertido',
    fiveDollarMin: 'Debe donar al menos $5',
  },
};
