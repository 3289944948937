import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CreationLoading from '../shared/CreationLoading';
import { useDApp, campaignAbi } from '../../contexts/web3';
import { toast } from 'react-toastify';
import { useLanguage } from '../../contexts/LanguageContext';

const CampaignButtonTerminateDialog = ({ campaign, onSuccess }) => {
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const { web3, account, jwt } = useDApp();
  const { language, getLanguageContent } = useLanguage();
  const [content, setContent] = useState(
    getLanguageContent('CmpBtnTerminateTrans')
  );

  useEffect(() => {
    setContent(getLanguageContent('CmpBtnTerminateTrans'));
  }, [language]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    setLoading(true);
    setMsg(content.pleaseApprove);
    const contract = new web3.eth.Contract(
      campaignAbi,
      campaign.contractAddress
    );
    contract.methods
      .terminateCampaign()
      .send({ from: account })
      .once('transactionHash', (txHash) => {
        setMsg(content.terminating);
      })
      .once('confirmation', async (confNumber, receipt) => {
        if (receipt.status) {
          toast.success(content.terminated);
          //send out a put request to the server to update planEnd
          console.log(receipt);
          await fetch('/api/campaign/' + campaign.id, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + jwt,
            },
            body: JSON.stringify({
              status: 'closed',
              terminated: true,
            }),
          });
          if (onSuccess) onSuccess();
        } else {
          toast.error(content.errorClosingEarly);
        }
        setLoading(false);
      })
      .once('error', (error, receipt) => {
        toast.error(error.message);
        setLoading(false);
      });
  };

  return (
    <div>
      <Button
        variant='contained'
        style={{
          marginLeft: '3px',
          marginRight: '3px',
          color: '#fff',
          background: '#c04343',
        }}
        onClick={handleClickOpen}
      >
        {content.terminate}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{content.terminate}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {content.terminateMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            style={{ background: '#191970' }}
            onClick={handleClose}
          >
            {content.cancel}
          </Button>
          <Button
            variant='contained'
            style={{ background: 'darkorange' }}
            onClick={handleConfirm}
            autoFocus
          >
            {content.confirm}
          </Button>
        </DialogActions>
      </Dialog>
      {loading && <CreationLoading text={msg} />}
    </div>
  );
};

export default CampaignButtonTerminateDialog;
