import React, { useState, useEffect } from 'react';
import {
  NotFoundPageContainer,
  NotFoundImageHolder,
  FourOFourImage,
  FourOFourText,
  NotFoundText,
} from './pageStyles/NotFoundPageStyles';
import NotFoundImage from '../assets/notfound.svg';
import { useLanguage } from '../contexts/LanguageContext';
import { Helmet } from 'react-helmet';

const NotFoundPage = (props) => {
  const { language, getLanguageContent } = useLanguage();
  const [content, setContent] = useState(getLanguageContent('NotFoundTrans'));

  useEffect(() => {
    setContent(getLanguageContent('NotFoundTrans'));
  }, [language]);

  return (
    <>
      <Helmet>
        <title>I WILL FUND | {content.notFoundTitle}</title>
        <meta name='description' content={content.descMeta} />
        <meta name='theme-color' content='#191970' />
      </Helmet>
      <NotFoundPageContainer>
        <NotFoundImageHolder>
          <FourOFourText>{content.notFound}</FourOFourText>
          <FourOFourImage src={NotFoundImage} alt={content.altText} />
        </NotFoundImageHolder>
        <NotFoundText>{content.notFoundText}</NotFoundText>
      </NotFoundPageContainer>
    </>
  );
};

export default NotFoundPage;
