import styled from 'styled-components';

export const CalcSlider = styled.div`
  font-size: 1rem;
  color: #666;
`;

export const SliderText = styled.p`
  font-size: 1.5rem;
  color: #666;
  margin-top: -5px;
  margin-bottom: 0;
  opacity: 0.7;
`;

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const SliderInput = styled.input`
  position: relative;
  -webkit-appearance: none;
  width: 100%;
  height: 20px;
  background: linear-gradient(
    90deg,
    rgb(25, 25, 112) 0%,
    rgb(255, 140, 0) 0%
  );
  outline: none;
  opacity: 0.8;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 12px;
  box-shadow: 0px 1px 10px 1px #000;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #fff;
    cursor: pointer;
    border-radius: 50%;
  }

  &::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #fff;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0px 1px 10px 1px #000;
  }
`;
