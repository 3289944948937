import React, { useEffect, useState } from 'react';
import CardComponent from '../shared/CardComponent';
import Carousel from 'react-elastic-carousel';
import Container from '@material-ui/core/Container';
import { useDApp, getCampaignDetails } from '../../contexts/web3';
import { TopCampaigns } from './CampaignCarouselStyles';
import { useLanguage } from '../../contexts/LanguageContext';
import CampaignModel from '../../Util/Classes/CampaignModel';

const CampaignsCarousel = (props) => {
  const [campaigns, setCampaigns] = useState([]);
  const [topFundedCampaigns, setTopFundedCampaigns] = useState([]);
  const [topDailyVolumeCampaigns, setTopDailyVolumeCampaigns] = useState([]);
  const [extraSpace, setExtraSpace] = useState('');
  const { web3R } = useDApp();
  const { language, getLanguageContent } = useLanguage();
  const [content, setContent] = useState(
    getLanguageContent('CmpCarouselTrans')
  );

  useEffect(() => {
    setContent(getLanguageContent('CmpCarouselTrans'));
  }, [language]);

  useEffect(() => {
    getCampaigns();
  }, []);

  useEffect(() => {
    getTopFundedCampaigns();
  }, []);

  useEffect(() => {
    getTopDailyVolumeCampaigns();
  }, []);

  const getCampaigns = async () => {
    const response = await fetch('/api/campaigns?perpage=10');

    const data = await response.json();
    // console.log(data);
    //grab data from blockchain
    const ar = [];
    for (const camp of data.data) {
      ar.push(getCampaignDetails(web3R, camp));
    }
    try {
      await Promise.all(ar);
    } catch (e) {
      console.log(e);
    }
    const camps = [];
    for (const camp of data.data) {
      camps.push(new CampaignModel(camp));
    }
    setCampaigns(camps);
  };

  const getTopFundedCampaigns = async () => {
    const response = await fetch('/api/top-funded');
    const data = await response.json();
    // console.log(data);
    //grab data from blockchain
    const ar = [];
    for (const camp of data) {
      ar.push(getCampaignDetails(web3R, camp));
    }
    try {
      await Promise.all(ar);
    } catch (e) {
      console.log(e);
    }
    const camps = [];
    for (const camp of data) {
      camps.push(new CampaignModel(camp));
    }
    setTopFundedCampaigns(camps);
    // setTopFundedCampaigns(data);
  };

  const getTopDailyVolumeCampaigns = async () => {
    const response = await fetch('/api/top-daily-volume');

    const data = await response.json();
    // console.log(data);
    //grab data from blockchain
    const ar = [];
    for (const camp of data) {
      ar.push(getCampaignDetails(web3R, camp));
    }
    try {
      await Promise.all(ar);
    } catch (e) {
      console.log(e);
    }
    const camps = [];
    for (const camp of data) {
      camps.push(new CampaignModel(camp));
    }
    setTopDailyVolumeCampaigns(camps);
    // setTopDailyVolumeCampaigns(data);
  };

  const updateCampaign = async (camp) => {
    try {
      const resp = await fetch('/api/campaign/' + camp.id);
      const data = await resp.json();
      await getCampaignDetails(web3R, data);
      return new CampaignModel(data);
    } catch (err) {
      console.log(err);
    }
    return null;
  };

  const onDonatedLatest = async (campaign, amount) => {
    const camp = await updateCampaign(campaign);
    if (camp) {
      //find old record
      // const index = campaigns.findIndex(c => c._id === camp._id);
      // if (index > -1) {
      setCampaigns((prev) => {
        const newCampaigns = prev.map((c) => {
          if (c.id === camp.id) {
            return camp;
          }
          return c;
        });
        return newCampaigns;
      });
      // const oldCamps = [...campaigns];
      // oldCamps[index] = camp;
      // setCampaigns(oldCamps);
      // }
    }
  };

  const onDonatedDailyVolume = async (campaign, amount) => {
    const camp = await updateCampaign(campaign);
    if (camp) {
      //find old record
      // const index = topDailyVolumeCampaigns.findIndex(c => c._id === camp._id);
      // if (index > -1) {
      // const oldCamps = [...campaigns];
      // oldCamps[index] = camp;
      // setTopDailyVolumeCampaigns(oldCamps);
      setTopDailyVolumeCampaigns((prev) => {
        const newCampaigns = prev.map((c) => {
          if (c.id === camp.id) {
            return camp;
          }
          return c;
        });
        return newCampaigns;
      });
      // }
    }
  };

  const onDonatedTopFunded = async (campaign, amount) => {
    const camp = await updateCampaign(campaign);
    if (camp) {
      //find old record
      // const index = topFundedCampaigns.findIndex(c => c._id === camp._id);
      // if (index > -1) {
      // const oldCamps = [...campaigns];
      // oldCamps[index] = camp;
      // setTopFundedCampaigns(oldCamps);
      setTopFundedCampaigns((prev) => {
        const newCampaigns = prev.map((c) => {
          if (c.id === camp.id) {
            return camp;
          }
          return c;
        });
        return newCampaigns;
      });
      // }
    }
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 2 },
    { width: 1150, itemsToShow: 3, itemsToScroll: 2 },
    { width: 1450, itemsToShow: 3 },
    { width: 1750, itemsToShow: 4 },
    { width: 2000, itemsToShow: 5 },
  ];

  useEffect(() => {
    if (campaigns.length < 0) {
      setExtraSpace('5rem');
    } else {
      setExtraSpace('71rem');
    }
  }, [campaigns]);

  return (
    <>
      <TopCampaigns
        className='top-campaigns'
        style={{
          paddingBottom: '4rem',
          backgroundColor: '#333',
          minHeight: { extraSpace },
        }}
      >
        <br />
        <br />
        <h2 style={{ textAlign: 'center', fontWeight: '400' }}>
          {content.latestTen}
        </h2>
        <div
          style={{
            width: '100%',
            margin: '0 auto',
            paddingRight: '0',
          }}
        >
          <Container style={{ paddingTop: '30px' }}>
            {/* {campaigns.length <= 0 ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <h4>
                    Nothing in the Latest Ten Campaigns yet. Consider Funding.
                  </h4>
                </div>
              </>
            ) : ( */}
            {campaigns.length > 0 ? (
              <>
                <Carousel breakPoints={breakPoints} itemPadding={[10, 15]}>
                  {campaigns.map((campaign) => (
                    <CardComponent
                      campaign={campaign}
                      key={campaign.id}
                      onDonated={onDonatedLatest}
                      language={language}
                    />
                  ))}
                </Carousel>
              </>
            ) : (
              <>
                <div
                  style={{
                    textAlign: 'center',
                    background: 'midnightblue',
                    borderRadius: '8px',
                    letterSpacing: '1px',
                    padding: '1.5rem',
                  }}
                >
                  <h3>
                    {content.topTenMessage1} <br />
                    {content.topTenMessage2}
                  </h3>
                </div>
              </>
            )}

            {/* )} */}
          </Container>
        </div>
      </TopCampaigns>
      <TopCampaigns
        style={{
          paddingBottom: '4rem',
          background: '#666',
          minHeight: { extraSpace },
        }}
      >
        <br />
        <br />
        <h2 style={{ textAlign: 'center', fontWeight: '400' }}>
          {content.topFunded}
        </h2>
        <div
          style={{
            width: '100%',
            margin: '0 auto',
            paddingRight: '0',
          }}
        >
          <Container style={{ paddingTop: '30px' }}>
            {/* {campaigns.length <= 0 ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <h4>
                    Nothing in the Top Funded Campaigns yet. Consider Funding.
                  </h4>
                </div>
              </>
            ) : ( */}
            {topFundedCampaigns.length > 0 ? (
              <>
                <Carousel breakPoints={breakPoints} itemPadding={[10, 15]}>
                  {topFundedCampaigns.map((topFundedCampaign) => (
                    <CardComponent
                      campaign={topFundedCampaign}
                      key={topFundedCampaign.id}
                      onDonated={onDonatedTopFunded}
                      language={language}
                    />
                  ))}
                </Carousel>
              </>
            ) : (
              <>
                <div
                  style={{
                    textAlign: 'center',
                    background: 'midnightblue',
                    borderRadius: '8px',
                    letterSpacing: '1px',
                    padding: '1.5rem',
                  }}
                >
                  <h3>
                    {content.topFundedMessage1} <br />
                    {content.topFundedMessage2}
                  </h3>
                </div>
              </>
            )}

            {/* )} */}
          </Container>
        </div>
      </TopCampaigns>
      <TopCampaigns
        style={{
          paddingBottom: '4rem',
          background: '#333',
          minHeight: { extraSpace },
        }}
      >
        <br />
        <br />
        <h2 style={{ textAlign: 'center', fontWeight: '400' }}>
          {content.topDailyVolume}
        </h2>
        <div
          style={{
            width: '100%',
            margin: '0 auto',
            paddingRight: '0',
          }}
        >
          <Container style={{ paddingTop: '30px' }}>
            {/* {campaigns.length <= 0 ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <h4>
                    Nothing in the Top Daily Volume yet. Consider Funding.
                  </h4>
                </div>
              </>
            ) : ( */}
            {topDailyVolumeCampaigns.length > 0 ? (
              <>
                <Carousel breakPoints={breakPoints} itemPadding={[10, 15]}>
                  {topDailyVolumeCampaigns.map((topDailyVolumeCampaign) => (
                    <CardComponent
                      campaign={topDailyVolumeCampaign}
                      key={topDailyVolumeCampaign.id}
                      onDonated={onDonatedDailyVolume}
                      language={language}
                    />
                  ))}
                </Carousel>
              </>
            ) : (
              <>
                <div
                  style={{
                    textAlign: 'center',
                    background: 'midnightblue',
                    borderRadius: '8px',
                    letterSpacing: '1px',
                    padding: '1.5rem',
                  }}
                >
                  <h3>
                    {content.topDailyVolumeMessage1} <br />
                    {content.topDailyVolumeMessage2}
                  </h3>
                </div>
              </>
            )}

            {/* )} */}
          </Container>
        </div>
      </TopCampaigns>
    </>
  );
};

export default CampaignsCarousel;
