import React, { createContext, useContext, useState, useEffect } from 'react';

const LanguageContext = createContext();

export function useLanguage() {
  return useContext(LanguageContext);
}

export function LanguagesProvider({ children }) {
  let languageStoredInLocalStorage = localStorage.getItem('language');
  let [language, setLanguage] = useState(
    languageStoredInLocalStorage ? languageStoredInLocalStorage : 'en'
  );
  function storeLanguageInLocalStorage(language) {
    localStorage.setItem('language', language);
    setLanguage(language);
  }

  function getLanguageContent(fileName) {
    const content = require(`../Util/Translations/${fileName}.js`).default;
    return language === 'es' ? content.Spanish : content.English;
  }

  return (
    <LanguageContext.Provider
      value={{
        language,
        storeLanguageInLocalStorage,
        getLanguageContent,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
}
