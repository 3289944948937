export default {
  English: {
    fax: 'Frequently Asked Questions',
    question1: 'What is a smart contract?',
    answer1:
      'A smart contract basically is a set of instructions (an application) on the blockchain . It knows who called the contracts functions and how much funds are being sent to it. It can hold funds and transfer funds. Functions on a smart contract  can also be restricted to only certain people.',
    answer1b: 'A perfect example of a smart contract is an escrow account:',
    li1: 'Person A can send the funds to the contract.',
    li2: 'Person B can send the funds to the contract.',
    li3: ' Person A cannot withdraw the funds as it is restricted to only Person B.',
    li4: ' It can be withdrawn only if a certain action has happened (maybe a house sale)',
    question2: 'Why use this over other funding platforms?',
    q2li1:
      'This is all driven on the blockchain using smart contract! Which means it is secure and reliable.',
    q2li2:
      'This is a decentralized platform! Meaning nodes on the blockchain network have to mine the block and do concensus to      agree if the block is valid.',
    q2li3:
      " We wouldn't have access to any campaigns smart contract funds. Only what was sent to us from service fees.",
    q2li4:
      'The crypto community has exploded over the last couple of years and they want to utilize their crypto instead of fiat.',
    question3: 'What type of crypto currency does you application use?',
    answer3:
      'At the moment, we are using the Binance Smart Chain (BNB tokens).',
    question4: 'What is Fiat?',
    answer4:
      'Fiat is a currency that is used to buy crypto currency. It is usually used in the USD.',
    question5: 'I use metamask, how do I add the binance network to it?',
    answer5:
      'MetaMask, can be easily tweaked to support Binance Smart Chain. Learn how to set this up by visiting the',
  },
  Spanish: {
    fax: 'Preguntas Frecuentes',
    question1: '¿Qué es un contrato inteligente?',
    answer1:
      'Un contrato inteligente es un conjunto de instrucciones (una aplicación) en la cadena de bloques. Sabe quién llamó a las funciones del contrato y cuánto se envía a él. Puede tener fondos y transferir fondos. Las funciones de un contrato inteligente pueden también ser restringidas a sólo determinados usuarios.',
    answer1b:
      'Un ejemplo perfecto de un contrato inteligente es una cuenta de depósito:',
    li1: 'Persona A puede enviar los fondos al contrato.',
    li2: 'Persona B puede enviar los fondos al contrato.',
    li3: 'Persona A no puede retirar los fondos porque está restringido a sólo Persona B.',
    li4: 'Sólo puede retirarse si se produce una acción específica (puede ser una venta de una casa)',
    question2: '¿Por qué usar esto sobre otras plataformas de financiamiento?',
    q2li1: 'Esto se basa en la blockchain, por lo que es seguro y confiable.',
    q2li2:
      'Esta es una plataforma descentralizada! Es decir, los nodos en la red de bloques de la blockchain deben minar el bloque y aceptar concensos para que el bloque sea válido.',
    q2li3:
      'No tendremos acceso a ninguna campaña de fondos de contratos inteligentes. Sólo lo que se nos envía a través de los servicios de comisión.',
    q2li4:
      'La comunidad de criptomonedas ha explotado en los últimos años y quieren utilizar su criptomoneda en lugar de dinero.',
    question3: '¿Qué tipo de criptomoneda utiliza tu aplicación?',
    answer3: 'Actualmente, utilizamos Binance Smart Chain (BNB tokens).',
    question4: '¿Qué es Fiat?',
    answer4:
      'Fiat es una moneda que se utiliza para comprar criptomonedas. Normalmente se utiliza en el USD.',
    question5:
      '¿Utilizo MetaMask, cómo puedo añadir la red binance a mi MetaMask?',
    answer5:
      'MetaMask, puede ser fácilmente configurado para soportar Binance Smart Chain. Aprende cómo configurarlo en la',
  },
};
