import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  LegalHeaderSection,
  LegalBodySection,
  LegalContainer,
  LegalTitle,
  LegalSubTitle,
  LegalTextHeading,
  LegalText,
} from './pageStyles/LegalStyles';
import { useLanguage } from '../contexts/LanguageContext';
import { Helmet } from 'react-helmet';

const TermsOfService = (props) => {
  const { language, getLanguageContent } = useLanguage();
  const [content, setContent] = useState(getLanguageContent('TermsTrans'));

  useEffect(() => {
    setContent(getLanguageContent('TermsTrans'));
  }, [language]);
  return (
    <>
      <Helmet>
        <title>I WILL FUND | {content.termsTopTitle}</title>
        <meta name='description' content={content.descMeta} />
        <meta name='theme-color' content='#191970' />
      </Helmet>
      <LegalHeaderSection>
        <LegalContainer>
          <LegalTitle>{content.terms}</LegalTitle>
          <LegalSubTitle>{content.legalDef}</LegalSubTitle>
        </LegalContainer>
      </LegalHeaderSection>
      <LegalBodySection>
        <LegalContainer>
          <LegalTextHeading>{content.termsUse}</LegalTextHeading>
          <LegalText>{content.pleaseRead}</LegalText>

          <LegalTextHeading>{content.who}</LegalTextHeading>
          <LegalText>
            {content.iWillFund}
            <sup>
              <span
                style={{
                  fontSize: '8px',
                  verticalAlign: 'super',
                }}
              >
                TM
              </span>
            </sup>
            , {content.isOperated}
            <span
              style={{
                fontSize: '8px',
                verticalAlign: 'super',
              }}
            >
              <sup>TM</sup>
            </span>{' '}
            {content.llc}
            <span
              style={{
                fontSize: '8px',
                verticalAlign: 'super',
              }}
            >
              <sup>TM</sup>
            </span>
            .
            <br />
            <br />
            {content.contact}
            <br />
            <br />
            {content.usingSite}{' '}
            <Link to='/risk-disclaimer' style={{ color: '#fff' }}>
              {content.risk}
            </Link>{' '}
            {content.andAccept}
            <br />
            <br />
            {content.notAgree}
            <br />
            <br />
            {content.weRecommend}
          </LegalText>
          <LegalTextHeading>{content.difi}</LegalTextHeading>
          <LegalText>
            {content.although}
            <span
              style={{
                fontSize: '8px',
                verticalAlign: 'super',
              }}
            >
              <sup>TM</sup>
            </span>{' '}
            {content.isDifi} IWill.Fund
            <span
              style={{
                fontSize: '8px',
                verticalAlign: 'super',
              }}
            >
              <sup>TM</sup>
            </span>{' '}
            {content.rights}
          </LegalText>
          <LegalTextHeading>{content.otherTerms}</LegalTextHeading>
          <LegalText>
            {content.additionalTerms}
            <br />
            <br />
            {content.privacyCookies}
            <br />
            <br />
            {content.furthermore}
            <span
              style={{
                fontSize: '8px',
                verticalAlign: 'super',
              }}
            >
              <sup>TM</sup>
            </span>
            , {content.userAgree}
            <br />
            <br />
            {content.wideByLaw}
            <span
              style={{
                fontSize: '8px',
                verticalAlign: 'super',
              }}
            >
              <sup>TM</sup>
            </span>{' '}
            {content.shallNot}
            <span
              style={{
                fontSize: '8px',
                verticalAlign: 'super',
              }}
            >
              <sup>TM</sup>
            </span>
            .
          </LegalText>
          <LegalTextHeading>{content.mayMakeChanges}</LegalTextHeading>
          <LegalText>{content.weAmend}</LegalText>
          <LegalTextHeading>{content.moreChanges}</LegalTextHeading>
          <LegalText>{content.weUpdate}</LegalText>
          <LegalTextHeading>{content.weSuspend}</LegalTextHeading>
          <LegalText>
            {content.siteAvailable}
            <br />
            <br />
            {content.noGuarantee}
            <br />
            <br />
            {content.youAreResponsible}
            <br />
            <br />
            {content.moreResponsible}
          </LegalText>

          <LegalTextHeading>{content.weTransfer}</LegalTextHeading>
          <LegalText>{content.transferRights}</LegalText>

          <LegalTextHeading>{content.useMaterial}</LegalTextHeading>
          <LegalText>
            {content.youAreOwner}
            <br />
            <br />
            {content.print}
            <br />
            <br />
            {content.noModify}
            <br />
            <br />
            {content.ourStatus}
            <br />
            <br />
            {content.noUse}
            <br />
            <br />
            {content.ifYouPrint}
          </LegalText>
          <LegalTextHeading>{content.doNotRely}</LegalTextHeading>
          <LegalText>{content.weTryUpdate}</LegalText>
          <LegalTextHeading>{content.notResponsibleWebsites}</LegalTextHeading>
          <LegalText>{content.sitesContainLinks}</LegalText>
          <LegalText>{content.noControl}</LegalText>
          <LegalTextHeading>{content.customerOrBusiness}</LegalTextHeading>
          <LegalText>
            {content.weDoNotExclude}
            <br />
            <br />
            {content.differentLimitations}
          </LegalText>
          <LegalTextHeading>{content.ifBusinessUser}</LegalTextHeading>
          <LegalText>
            {content.weExcludeImplied}
            <br />
            <br />
            {content.willNotBeLiable}
            <br />
            <br />
            {content.inability}
            <br />
            <br />
            {content.reliance}
            <br />
            <br />
            {content.inParticular}
            <br />
            <br />
            {content.loss}
            <br />
            <br />
            {content.interruption}
            <br />
            {content.lossSavings}
            <br />
            <br />
            {content.lossOpportunity}
            <br />
            <br />
            {content.damage}
          </LegalText>
          <LegalTextHeading>{content.ifConsumer}</LegalTextHeading>
          <LegalText>{content.pleaseNote}</LegalText>
          <LegalTextHeading>{content.personal}</LegalTextHeading>
          <LegalText>
            {content.weWillOnlyUse}
            <br />
            <br />
            {content.weAreNotResponsible}
            <br />
            <br />
            {content.weDoNotGuarantee}
            <br />
            <br />
            {content.configuring}
            <br />
            <br />
            {content.misuse}
          </LegalText>
          <LegalTextHeading>{content.rules}</LegalTextHeading>
          <LegalText>
            {content.youMayLink}
            <br />
            <br />
            {content.mustNotEstablish}
            <br />
            {content.siteNotOwnedByYou}
            <br />
            <br />
            {content.doNotFrame}
          </LegalText>
          <LegalTextHeading>{content.whichCountry}</LegalTextHeading>
          <LegalText>
            {content.ifConsumerPleaseNote}
            <br />
            <br />
            {content.govByLawOfState}
          </LegalText>
          <LegalTextHeading>{content.ourTradeMarks}</LegalTextHeading>
          <LegalText>{content.youAreNotPermitted}</LegalText>
        </LegalContainer>
      </LegalBodySection>
    </>
  );
};

export default TermsOfService;
