import styled from 'styled-components';

export const FooterComponentStyled = styled.footer`
  position: relative;
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: midnightblue;
  color: #fff;
  padding: 3rem;
`;
