import React, { useState, useEffect } from 'react';
import {
  AboutSection,
  Container,
  AboutTitle,
  AboutWrapper,
  AboutSubtitle,
  AboutText,
  AboutImageHolder,
  AboutImage,
  AboutTextWrapper,
} from './pageStyles/AboutStyles';
import Friends from '../assets/friends.png';
import Scott from '../assets/scott3.jpg';
import Erik from '../assets/erik1.png';
import Bere from '../assets/bere.jpeg';
import ProfileCards from '../components/ProfileCards/ProfileCards';
import ProfileCardSingle from '../components/ProfileCards/ProfileCardSingle';
import { useLanguage } from '../contexts/LanguageContext';

const About = (props) => {
  const { language, getLanguageContent } = useLanguage();
  const [content, setContent] = useState(getLanguageContent('AboutTrans'));

  useEffect(() => {
    setContent(getLanguageContent('AboutTrans'));
  }, [language]);

  return (
    <AboutSection>
      <Container>
        <AboutTitle style={{ fontWeight: 'bold' }}>{content.about}</AboutTitle>
        <AboutWrapper style={{ paddingBottom: '2rem' }}>
          <AboutTextWrapper>
            <AboutSubtitle>{content.aboutSub}</AboutSubtitle>
            <AboutText>
              {content.create} I Will Fund
              <span style={{ fontSize: '9px', verticalAlign: 'super' }}>
                <sup>TM</sup>
              </span>{' '}
              {content.aboutText}
            </AboutText>
          </AboutTextWrapper>

          <div>
            <AboutImageHolder>
              <AboutImage
                src={Friends}
                alt='unexpected friends'
                style={{ width: '75%' }}
              />
            </AboutImageHolder>
          </div>
        </AboutWrapper>
        <AboutTitle
          style={{
            marginTop: '3rem',
            marginBottom: '5rem',
            color: '#333',
            fontSize: '2rem',
            textTransform: 'uppercase',
          }}
        >
          {content.meetTeam}
        </AboutTitle>

        <AboutWrapper>
          <ProfileCards
            title={content.meetScott}
            text={[
              <span key={1}>
                {content.meetScottText} IWill.Fund
                <span style={{ fontSize: '9px', verticalAlign: 'super' }}>
                  <sup>TM</sup>
                </span>{' '}
                {content.meetScottTextTwo}
                <br />
                <br />
                <em>{content.meetScottTextThree}</em>
              </span>,
            ]}
            image={Scott}
            alt={'Scott'}
            link={'https://twitter.com/31337noob'}
            link2={'https://www.facebook.com/sdeutsch18/'}
            link3={'https://www.linkedin.com/in/sdeutsch/'}
          />
          <ProfileCards
            title={content.meetErik}
            text={[
              <span key={2}>
                {content.meetErikText}
                <br />
                <br /> {content.meetErikTextTwo}
              </span>,
            ]}
            image={Erik}
            alt={'Erik'}
            link={'https://twitter.com/Erik1969Robles'}
            link2={'https://www.facebook.com/erik.robles.1466126/'}
            link3={'https://www.linkedin.com/in/erik-james-245067a8/'}
          />
        </AboutWrapper>
        <AboutWrapper>
          <ProfileCardSingle
            title={content.meetBere}
            text={[
              <span key={2}>
                {content.meetBereText}
                <br />
                <br />
                {content.meetBereTextTwo}
              </span>,
            ]}
            image={Bere}
            alt={'Berenice'}
            link={'https://twitter.com/Berebeat'}
            link2={'https://www.facebook.com/berebeat'}
            link3={'https://www.linkedin.com/in/bereniceramosc/'}
          />
        </AboutWrapper>
      </Container>
    </AboutSection>
  );
};

export default About;
