import React, { useState, useContext, useEffect } from 'react';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3 from 'web3';
import Web3Modal, { local, ProviderController } from 'web3modal';
import Contracts from '@apollo/libs/contractAddresses';
import CampaignModel from '../Util/Classes/CampaignModel';
// import Nodes from "../../../nodes";
import Nodes from "@apollo/libs/nodes";

// const chainId = [56, 97, 1337];
const chainIds = [97, 1337];

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: 'a57cb399d01f4a538b330164e203f3ac', // required,
      rpc: {
        // 56: 'https://bsc-dataseed.binance.org/',
        97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
        1337: 'http://localhost:7545',
      },
    },
    // display: {
    //   logo: "data:image/gif;base64,INSERT_BASE64_STRING",
    //   name: "IWill.Fund",
    //   description: "IWill.Fund DApp - Donate funds for a good cause",
    // },
  },
};

export const network = 'test';
export const explorer = {
  live: 'https://bscscan.com',
  test: 'https://testnet.bscscan.com',
};

const dAppContext = React.createContext();

export function useDApp() {
  return useContext(dAppContext);
}

function readOnlyMode() {
  const options = {
    // timeout: 30000, // ms

    // clientConfig: {
    //   // Useful if requests are large
    //   // maxReceivedFrameSize: 100000000,   // bytes - default: 1MiB
    //   // maxReceivedMessageSize: 100000000, // bytes - default: 8MiB

    //   // Useful to keep a connection alive
    //   keepalive: true,
    //   keepaliveInterval: -1 // ms
    // },

    // Enable auto reconnection
    reconnect: {
      auto: true,
      delay: 1500, // ms
      maxAttempts: 50,
      onTimeout: false,
    },
  };

  if (network === 'test') {
    // return new Web3(
    //   'wss://speedy-nodes-nyc.moralis.io/f6140540b2758fe41f49dd9c/bsc/testnet/ws'
    // );
    return new Web3(
      new Web3.providers.WebsocketProvider(
        // 'wss://crimson-purple-wildflower.bsc-testnet.discover.quiknode.pro/0a72bbf19b1572ede064556ee0073ef67af631ce/',
        Nodes.bsc.wss.testnet,
        options
      )
    );
    // return new Web3('https://data-seed-prebsc-1-s1.binance.org:8545/');
  } else if (network === 'live') {
    // return new Web3(
    //   'wss://speedy-nodes-nyc.moralis.io/f6140540b2758fe41f49dd9c/bsc/mainnet/ws'
    // );
    return new Web3(
      new Web3.providers.WebsocketProvider(
        // 'wss://speedy-nodes-nyc.moralis.io/f6140540b2758fe41f49dd9c/bsc/mainnet/ws',
        Nodes.bsc.wss.mainnet,
        options
      )
    );
    // return new Web3('https://bsc-dataseed.binance.org/');
  } else if (network === 'local') {
    return new Web3('ws://localhost:7545');
  }
}

// export const campaignAbi = Contracts.Campaign.abi;
export const campaignAbi = Contracts.CCampaign.abi;
export const multiCall = Contracts.Multicall;

export async function getCampaignDetails(web3, camp) {
  if (!camp) return;
  if (
    camp.contractAddress &&
    camp.contractAddress !== '0x0000000000000000000000000000000000000000'
  ) {
    const c = new web3.eth.Contract(campaignAbi, camp.contractAddress);
    const details = await c.methods.details().call();
    details.totalFunded =
      +web3.utils.fromWei(details.amountFunded) +
      +web3.utils.fromWei(details.totalFees);
    //inject into camp

    if (details.totalFunded > 0) {
      details.percent = (
        (details.totalFunded / +camp.tokensNeeded) *
        100
      ).toFixed(2);
    }
    camp.details = details;
  } else {
    camp.details = {
      totalFunded: 0,
      percent: 0,
    };
  }
}

export function DAppProvider({ children }) {
  const [web3, setWeb3] = useState(null);
  const [web3R] = useState(readOnlyMode());
  const [account, setAccount] = useState(null);
  const [campaignFactory, setCampaignFactory] = useState(null);
  const [web3Modal, setWeb3Modal] = useState(null);
  const [wrongNetwork, setWrongNetwork] = useState(false);
  const [walletState, setWalletState] = useState('not-connected');
  const [priceConsumerContract, setPriceConsumerContract] = useState(null);
  const [accessContract, setAccessContract] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  // const [provider, setProvider] = useState(null);
  const [providerCleanup, setProviderCleanup] = useState(null);
  // const [checkerInterval, setCheckerInterval] = useState(null);
  const [jwt, setJwt] = useState(null);

  useEffect(() => {
    if (!accessContract) return null;
    //check if provider is set and autologin
    if (localStorage) {
      const v = localStorage.getItem('WEB3_CONNECT_CACHED_PROVIDER', null);
      if (v) {
        //now check if jwt exists
        const jwt = localStorage.getItem('jwt', null);
        setJwt(jwt);
        if (jwt) {
          //autologin
          connect();
        } else {
          localStorage.removeItem('WEB3_CONNECT_CACHED_PROVIDER');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContract]); //needs accessContract to work!

  async function _isAdmin(account) {
    if (!accessContract) return;
    if (!account) return;
    const isAdmin = await accessContract.methods
      .hasRole('admin', account)
      .call();
    setIsAdmin(isAdmin);
    console.log('Is admin?', isAdmin);
    return isAdmin;
  }

  async function _login(w3, account, admin, con) {
    if (localStorage) {
      const v = localStorage.getItem('jwt', null);
      if (v) {
        setJwt(v);
        if (con) {
          _connect(w3);
          setWalletState('connected');
        }
        return;
      }
    }
    setWalletState('signing');
    const msg = `
      I, the account holder, am signing this message to login in to use the defi app.
      This signuature will generate a key.
      This key will be stored in the local storage and will be used to authenticate all API calls.
      If you clear your local storage, you will need to sign this again to re-generate the key.
    `;
    try {
      const signed = await w3.eth.personal.sign(msg, account);
      const resp = await fetch('/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          signed,
          msg,
          wallet: account,
          admin,
        }),
      });
      const data = await resp.json();
      if (data.error) {
        console.log('Error logging in', data.error);
        _disconnect();
        return;
      } else {
        console.log('Received JWT', data.token);
        if (localStorage) {
          localStorage.setItem('jwt', data.token);
          setJwt(data.token);
        }

        //store in JWT context
        if (con) {
          _connect(w3);
          setWalletState('connected');
          // console.log("IN HERE");
        }
      }
    } catch (e) {
      console.log('Error signing message', e);
      _disconnect();
    }
  }

  async function _connect(w3) {
    //load in base contracts
    let { abi, address } = Contracts.CampaignFactory;
    const cf = new w3.eth.Contract(abi, address[network]);
    setCampaignFactory(cf);
  }

  useEffect(() => {
    //load in the priceConsumerContract as a readonly contract
    const pcv3 = Contracts.PriceConsumerV3;
    const pc = new web3R.eth.Contract(pcv3.abi, pcv3.address[network]);
    setPriceConsumerContract(pc);

    const ac = Contracts.Access;
    const a = new web3R.eth.Contract(ac.abi, ac.address[network]);
    setAccessContract(a);
  }, [web3R]);

  useEffect(() => {
    switch (walletState) {
      case 'disconnected': {
        // console.log('CLEARED');
        setWrongNetwork(false);
        setWeb3(null);
        setAccount(null);
        setCampaignFactory(null);
        setWeb3Modal(null);
        setJwt(null);
        //destroy token
        if (localStorage) {
          localStorage.removeItem('jwt');
        }
        // clearInterval(checkerInterval);
        // setCheckerInterval(null);
        // setWalletState('disconnected');
        break;
      }
      case 'wrong-network': {
        setWrongNetwork(true);
        break;
      }
      case 'connected': {
        setWrongNetwork(false);
        break;
      }
      default: {
        break;
      }
    }
  }, [walletState]);

  async function autoSwitchOrAdd() {
    try {
      await web3.eth.currentProvider.request({
        method: 'wallet_switchEthereumChain',
        // params: [{ chainId: '0x38' }],
        params: [{ chainId: '0x61' }],
      });
      setWrongNetwork(false);
    } catch (error) {
      if (error.code === 4902) {
        try {
          await web3.eth.currentProvider.request({
            method: 'wallet_addEthereumChain',
            // params: [
            //   {
            //     chainId: '0x38',
            //     chainName: 'Binance Smart Chain',
            //     rpcUrls: ['https://bsc-dataseed.binance.org/'],
            //     nativeCurrency: {
            //       name: 'BNB',
            //       symbol: 'BNB',
            //       decimals: 8,
            //     },
            //     blockExplorerUrls: ['https://bscscan.com'],
            //   },
            // ],
            params: [
              {
                chainId: '0x61',
                chainName: 'Binance Smart Chain - TestNet',
                rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
                nativeCurrency: {
                  name: 'BNB',
                  symbol: 'BNB',
                  decimals: 8,
                },
                blockExplorerUrls: ['https://testnet.bscscan.com'],
              },
            ],
          });
        } catch (error) {
          alert(error.message);
        }
      }
    }
  }

  async function connect() {
    try {
      if (!account) {
        setWalletState('connecting');
        const w3Modal = new Web3Modal({
          network: 'binance', // optional
          cacheProvider: true, // optional
          providerOptions,
        });

        // await w3Modal.clearCachedProvider();
        const provider = await w3Modal.connect();
        // setProvider(p);
        const w3 = await new Web3(provider);
        setWeb3(w3);
        setWeb3Modal(w3Modal);

        const disconnectFunc = async (error) => {
          console.log('discconectFunc'.error);
          if (error.code === 1013) return;
          _disconnect();
        };
        provider.on('disconnect', disconnectFunc);
        // Subscribe to accounts change

        // const accountsChanged = async ([newAccount]) => {
        const accountsChanged = async () => {
          console.log('Account Changed');
          // if (typeof newAccount === 'undefined') {
          //   _disconnect();
          // } else {
          //   console.log(newAccount);
          //   setAccount(newAccount);
          //   const _admin = await _isAdmin(newAccount);
          //   _login(w3, newAccount, _admin, false);
          // }
          _disconnect({ w3, w3Modal }); //some reason in here web3 is null
        };
        provider.on('accountsChanged', accountsChanged);

        const [wallet] = await w3.eth.getAccounts();
        const admin = await _isAdmin(wallet);

        // Subscribe to chainId change
        const chainChanged = (chainId) => {
          console.log(chainId);
          //convert hex to number
          const networkId = parseInt(chainId, 16);
          // const networks = [56, 97, 1337];
          if (chainIds.indexOf(networkId) > -1) {
            // _connect(w3);
            // setWalletState('connected');
            _login(w3, wallet, admin, true);
          } else {
            setWalletState('wrong-network');
          }
        };
        provider.on('chainChanged', chainChanged);

        setProviderCleanup(() => {
          return () => {
            // provider.removeAllListeners();
            console.log('CALLED CLEANUP');
            provider.removeListener('disconnect', disconnectFunc);
            provider.removeListener('accountsChanged', accountsChanged);
            provider.removeListener('chainChanged', chainChanged);
          };
        });

        // console.log(provider);
        setAccount(wallet);
        const networkId = await w3.eth.getChainId();
        // const networks = [56, 97, 1337];

        if (chainIds.indexOf(networkId) > -1) {
          // _connect(w3);
          // setWalletState('connected');
          _login(w3, wallet, admin, true);
        } else {
          setWalletState('wrong-network');
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function _disconnect(opts) {
    opts = {
      web3,
      web3Modal,
      ...opts,
    };
    // console.log("IN HERE _disconnect");
    console.log(opts);
    await opts?.web3?.currentProvider?.disconnect?.();
    await opts?.web3Modal?.clearCachedProvider?.();
    setWalletState('disconnected');
    //turn off subscriptions on provider
    providerCleanup?.();

    // setProvider(null);
    setProviderCleanup(null);
  }

  async function disconnect() {
    //TODO: see how to really disconnect it
    if (walletState === 'disconnected' || walletState === 'not-connected')
      return;
    return _disconnect();
  }

  async function getClaimables() {
    //check creator claimables
    if (!account) return;
    if (!campaignFactory) return;
    if (!web3) return;
    try {
      const creator = await campaignFactory.methods
        .getCreatorClaimableCampaigns(account)
        .call();
      const donations = await campaignFactory.methods
        .getDonatorClaimableCampaigns(account)
        .call();
      //convert address to campaigns
      const resp = await fetch('/api/contract-addresses', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ addresses: [...creator, ...donations] }),
      });
      const data = await resp.json();
      //now split into creator/donations
      const creatorClaimables = data.filter(
        (c) => creator.indexOf(c.contractAddress) > -1
      );
      for (const d of data) {
        //attach balance that is in the smart contract
        const bal = await web3.eth.getBalance(d.contractAddress);
        d.balance = +web3.utils.fromWei(bal);
      }
      const donationsClaimables = data.filter(
        (c) => donations.indexOf(c.contractAddress) > -1
      );
      for (const camp of donationsClaimables) {
        const campaign = new web3.eth.Contract(
          Contracts.Campaign.abi,
          camp.contractAddress
        );
        //attach donators balance
        const bal = await campaign.methods.donated(account).call();
        camp.balance = +web3.utils.fromWei(bal);
      }
      console.log(creatorClaimables, donationsClaimables);
      // return {
      //   creator: creatorClaimables,
      //   donations: donationsClaimables,
      // };
      // return [...creatorClaimables, ...donationsClaimables];
      return [
        ...creatorClaimables.map((c) => new CampaignModel(c)),
        ...donationsClaimables.map((c) => new CampaignModel(c)),
      ];
    } catch (error) {
      console.log(error);
    }
    return [];
  }

  async function getCreatorCampaigns() {
    //check creator campaigns
    if (!account) return;
    if (!campaignFactory) return;
    if (!web3) return;
    try {
      const creator = await campaignFactory.methods
        .getCreatorCampaigns(account)
        .call();
      let camps = [];
      if (creator.length > 0) {
        const resp = await fetch('/api/contract-addresses', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ addresses: creator }),
        });
        const data = await resp.json();
        for (const d of data) {
          await getCampaignDetails(web3R, d);
        }
        camps = [...data];
      }
      return camps.map((c) => new CampaignModel(c));
    } catch (error) {
      console.log(error);
    }
    return [];
  }

  async function getDonatorCampaigns() {
    //check donator campaigns
    if (!account) return;
    if (!campaignFactory) return;
    if (!web3) return;
    try {
      const donator = await campaignFactory.methods
        .getDonatorsCampaigns(account)
        .call();
      let camps = [];
      if (donator.length > 0) {
        const resp = await fetch('/api/contract-addresses', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ addresses: donator }),
        });
        const data = await resp.json();
        for (const d of data) {
          await getCampaignDetails(web3R, d);
          const c = new web3R.eth.Contract(campaignAbi, d.contractAddress);
          const donated = await c.methods.donated(account).call();
          d.donated = +web3R.utils.fromWei(donated);
        }
        camps = [...data];
      }
      return camps.map((c) => new CampaignModel(c));
    } catch (error) {
      console.log(error);
    }
    return [];
  }

  return (
    <dAppContext.Provider
      value={{
        account,
        web3,
        campaignFactory,
        connect,
        disconnect,
        web3R,
        wrongNetwork,
        autoSwitchOrAdd,
        walletState,
        priceConsumerContract,
        isAdmin,
        getClaimables,
        getCreatorCampaigns,
        getDonatorCampaigns,
        jwt,
      }}
    >
      {children}
    </dAppContext.Provider>
  );
}
