import moment from 'moment-timezone';

export default class CampaignModel {
  #camp;

  constructor(campaign) {
    this.#camp = campaign;
  }

  status() {
    const startTime = moment.utc(this.#camp?.planStart);
    const endTime = this.#camp?.details?.endTime;
    const type = this.#camp?.type;
    const category = this.#camp?.category;
    const terminated = this.#camp?.details?.terminated;
    const now = moment.utc();
    const isUpcoming = startTime > now;
    const isNotEnded = moment.utc(this.#camp?.planEnd) >= now;
    const hasStarted = !isUpcoming;
    // console.log(isUpcoming, isActive, hasStarted, this.#camp?.planEnd);
    //see if it has started yet
    if (isUpcoming && !terminated) {
      return 'upcoming';
    } else if (
      //If it is currently active
      hasStarted &&
      ((type === 1 && endTime === '0') || //org
        (type === 0 && isNotEnded)) && //individual
      !terminated
    ) {
      return 'active';
    } else if (terminated) {
      return 'terminated';
    }
    return 'ended';
  }

  get dbInfo() {
    const dat = JSON.parse(JSON.stringify(this.#camp));
    delete dat.details;
    return dat;
  }

  get chainInfo() {
    const dat = JSON.parse(JSON.stringify(this.#camp?.details ?? {}));
    return dat;
  }

  //quick getters to needed info

  get terminated() {
    return this.#camp?.details?.terminated;
  }

  get active() {
    return this.status() === 'active';
  }

  get closed() {
    const active = this.status();
    return active === 'terminated' || active === 'ended';
  }

  get comingSoon() {
    return this.status() === 'upcoming';
  }

  get title() {
    return this.#camp?.title;
  }

  get image() {
    return this.#camp?.image;
  }

  get creator() {
    return this.#camp?.creator;
  }

  get contractAddress() {
    return this.#camp?.contractAddress;
  }

  get totalFunded() {
    return +this.#camp?.details?.totalFunded;
  }

  get tokensNeeded() {
    return +this.#camp?.tokensNeeded;
  }

  get planStart() {
    return moment.utc(this.#camp?.planStart);
  }

  get planEnd() {
    if (this.#camp?.details?.endTime === '0') return null;
    return moment.utc(this.#camp?.planEnd);
  }

  get description() {
    return this.#camp?.description;
  }

  get shortDescription() {
    return this.#camp?.shortDescription;
  }

  get funded() {
    return this.totalFunded >= this.tokensNeeded;
  }

  get category() {
    return this.#camp?.category;
  }

  get type() {
    return this.#camp?.type;
  }

  get typeString() {
    return this.#camp?.type === 0 ? 'individual' : 'organization';
  }

  get percent() {
    return this.#camp?.details?.percent;
  }

  get stateSelected() {
    return this.#camp?.stateSelected;
  }

  get receiveIfGoalNotMet() {
    return this.#camp?.receiveIfGoalNotMet;
  }

  get id() {
    return this.#camp?._id;
  }

  get balance() {
    return this.#camp?.balance;
  }

  get donated() {
    return this.#camp?.donated;
  }

  get tokensLeft() {
    return this.tokensNeeded - this.totalFunded;
  }
}
