export default {
  English: {
    descMeta:
      'Profile page for user data such as balance, funded campaigns, notifications, etc.',
    profileTopTitle: 'PROFILE',
    profile: 'Profile',
    created: 'Created',
    donated: 'Donated',
    claimable: 'Claimable',
    refresh: 'Refresh to see new claimables',
    refreshText: 'Refresh',
    noClaim: 'No claimables yet. Please check back later',
    deleteAll: 'Delete All',
    timeStamp: 'Time Stamp',
    title: 'Title',
    notificationType: 'Notification Type',
    info: 'Info',
    markAllAsUnread: 'Mark All As Unread',
    markAllAsRead: 'Mark All As Read',
    read: ' Read',
    unread: ' Unread',
    noNotifications: 'No Notifications Yet. Please Check back later.',
    markAsRead: 'Mark as Read',
    notifDeleted: 'Notification Successfully Deleted',
    areYouSure: 'Are you sure you want to delete all? This cannot be undone.',
  },
  Spanish: {
    descMeta:
      'Página de perfil para datos de usuario como saldo, campañas financiadas, notificaciones, etc.',
    profileTopTitle: 'PERFIL',
    profile: 'Perfil',
    created: 'Creado',
    donated: 'Donado',
    claimable: 'Recuperable',
    refresh: 'Actualizar para ver nuevas reclamables',
    refreshText: 'Actualizar',
    noClaim: 'No hay reclamables todavía. Por favor, vuelva más tarde',
    deleteAll: 'Eliminar Todos',
    timeStamp: 'Fecha y Hora',
    title: 'Título',
    notificationType: 'Tipo de Notificación',
    info: 'Info',
    markAllAsUnread: 'Marcar todos como no leídos',
    markAllAsRead: 'Marcar todos como leídos',
    read: ' Leído',
    unread: ' No leído',
    noNotifications:
      'No hay notificaciones todavía. Por favor, vuelva más tarde.',
    markAsRead: 'Marcar como leído',
    notifDeleted: 'Notificación eliminada correctamente',
    areYouSure:
      '¿Está seguro de que desea eliminar todo? Esto no se puede deshacer.',
  },
};
