import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import {
  Card,
  ImageHolder,
  CampaignTitle,
  ShortDescription,
  GoalText,
} from './CampaignsCreatedCardStyles';
import PlaceholderImage from '../../assets/placeholder-image.png';
import { excerpt } from '../../Util/Excerpt';
import { usePriceApi } from '../../contexts/priceApi';

const CampaignsCreatedCard = ({ campaign }) => {
  const left = campaign.tokensLeft;
  const { price } = usePriceApi();

  return (
    <>
      <Card style={{ margin: '0px', textAlign: 'center' }}>
        <ImageHolder>
          <img
            style={{
              maxWidth: '270px',
              height: '200px',
              borderRadius: '8px 8px 0 0',
              backgroundPosition: 'center',
              overflow: 'hidden',
            }}
            src={campaign.image ? campaign.image : PlaceholderImage}
            alt='png-holder'
          />{' '}
        </ImageHolder>
        <CampaignTitle>{campaign.title}</CampaignTitle>
        <GoalText>
          {left > 0
            ? (left * price).toLocaleString(undefined, {
              currency: 'usd',
              style: 'currency',
            })
            : 'Goal has been reached!'}
          {left > 0 ? ' Left To Reach Goal' : ''}
        </GoalText>
        <ShortDescription>
          {excerpt(campaign.shortDescription, 85, ' ', '...')}
        </ShortDescription>
        <Link
          to={`/campaign/${campaign.id}`}
          style={{ textDecoration: 'none' }}
        >
          <Button
            variant='contained'
            className='campaign-card-buttons'
            style={{
              width: '100%',
              color: '#fff',
              background: '#f7931a',
              marginLeft: '3px',
            }}
          >
            More Info
          </Button>
        </Link>
      </Card>
      {/* ))} */}
    </>
  );
};

export default CampaignsCreatedCard;
