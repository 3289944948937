import React, { useEffect, useState } from 'react'
import EditorJS from '@editorjs/editorjs';

export default function EditorJs(props) {
  const [editor, setEditor] = useState(null);
  useEffect(() => {
    const e = new EditorJS({
      tools: props.tools || [],
      data: props.defaultValue || null,
      placeholder: props.placeholder || '',
      readOnly: props.readOnly || false,
      holder: 'editorjs',
    })
    e.isReady.then(() => {
      setEditor(e);
      if (props.onInitialize) {
        props.onInitialize(e);
      }
    }).catch((reason) => {
      console.log(`Editor.js initialization failed because of ${reason}`)
    });
    return () => {
      e.destroy();
      // const el = document.querySelector('#editorjs');
      // if (el) {
      //   el.remove();
      // }
    }
  }, []);

  useEffect(async () => {
    if (!editor) return null;
    if (!props.value) return null;
    await editor.isReady;
    editor.render(props.value);
  }, [editor, props.value]);

  return (
    <div id="editorjs"></div>
  )
}
