import React, { useState, useEffect } from 'react';
import {
  LegalHeaderSection,
  LegalBodySection,
  LegalContainer,
  LegalTitle,
  LegalSubTitle,
  LegalTextHeading,
  LegalText,
} from './pageStyles/LegalStyles';
import { useLanguage } from '../contexts/LanguageContext';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = (props) => {
  const { language, getLanguageContent } = useLanguage();
  const [content, setContent] = useState(getLanguageContent('PrivacyTrans'));

  useEffect(() => {
    setContent(getLanguageContent('PrivacyTrans'));
  }, [language]);
  return (
    <>
      <Helmet>
        <title>I WILL FUND | {content.privacyP}</title>
        <meta name='description' content={content.descMeta} />
        <meta name='theme-color' content='#191970' />
      </Helmet>
      <LegalHeaderSection>
        <LegalContainer>
          <LegalTitle>{content.pPolicy}</LegalTitle>
          <LegalSubTitle>{content.yPolicy}</LegalSubTitle>
        </LegalContainer>
      </LegalHeaderSection>
      <LegalBodySection>
        <LegalContainer>
          <LegalTextHeading>{content.pAndCPolicy}</LegalTextHeading>
          <LegalTextHeading>
            <em>
              <small>{content.update}</small>
            </em>
          </LegalTextHeading>
          <LegalText>
            {content.we}
            <span
              style={{
                fontSize: '8px',
                verticalAlign: 'super',
              }}
            >
              <sup>TM</sup>
            </span>
            {content.aSite}
            <span
              style={{
                fontSize: '8px',
                verticalAlign: 'super',
              }}
            >
              <sup>TM</sup>
            </span>{' '}
            {content.llc}
            <span
              style={{
                fontSize: '8px',
                verticalAlign: 'super',
              }}
            >
              <sup>TM</sup>
            </span>
            {content.areCommitted}
          </LegalText>
          <LegalTextHeading>{content.weCollect}</LegalTextHeading>
          <LegalText>{content.infoCollected}</LegalText>
          <LegalTextHeading>{content.moreInfo}</LegalTextHeading>
          <LegalText>{content.weMayCollect}</LegalText>
          <LegalTextHeading>{content.infoUse}</LegalTextHeading>
          <LegalText>
            {content.thisInfo}
            <span
              style={{
                fontSize: '8px',
                verticalAlign: 'super',
              }}
            >
              <sup>TM</sup>
            </span>{' '}
            {content.mayEmail}
            <br />
            <br />
            {content.ip}
          </LegalText>
          <LegalTextHeading>{content.cookies}</LegalTextHeading>
          <LegalText>
            {content.yourInternet}
            <span
              style={{
                fontSize: '8px',
                verticalAlign: 'super',
              }}
            >
              <sup>TM</sup>
            </span>{' '}
            {content.website}{' '}
            <a
              href='https://en.wikipedia.org/wiki/HTTP_cookie'
              target='_blank'
              style={{ color: '#fff' }}
            >
              https://en.wikipedia.org/wiki/HTTP_cookie
            </a>
          </LegalText>
        </LegalContainer>
      </LegalBodySection>
    </>
  );
};

export default PrivacyPolicy;
