import React from 'react';
import styled from 'styled-components';
import CryptoLaunch from '../../assets/cryptolaunch.png';
import Container from '../shared/Container';

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  padding-top: 40px;
`;

const Header = () => {
  return (
    <Container>
      <Image src={CryptoLaunch} alt='launch' />
    </Container>
  );
};

export default Header;
