import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import {
  Card,
  ImageHolder,
  CampaignTitle,
  ShortDescription,
  GoalText,
} from './CampaignsDonatedStyles';
import PlaceholderImage from '../../assets/placeholder-image.png';
import { excerpt } from '../../Util/Excerpt';

const CampaignsDonated = ({ campaign }) => {
  return (
    <Card style={{ margin: '0px', textAlign: 'center' }}>
      <ImageHolder>
        <img
          style={{
            maxWidth: '270px',
            height: '200px',
            borderRadius: '8px 8px 0 0',
            backgroundPosition: 'center',
            overflow: 'hidden',
          }}
          src={campaign.image ? campaign.image : PlaceholderImage}
          alt='png-holder'
        />{' '}
      </ImageHolder>
      <CampaignTitle>{campaign.title}</CampaignTitle>
      <GoalText>{campaign.donated ? campaign.donated : ''} BNB</GoalText>
      <ShortDescription>
        {excerpt(campaign.shortDescription, 85, ' ', '...')}
      </ShortDescription>
      <Link to={`/campaign/${campaign.id}`} style={{ textDecoration: 'none' }}>
        <Button
          variant='contained'
          className='campaign-card-buttons'
          style={{
            width: '100%',
            color: '#fff',
            background: '#f7931a',
            marginLeft: '3px',
          }}
        >
          More Info
        </Button>
      </Link>
    </Card>
  );
};

export default CampaignsDonated;
