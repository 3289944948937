import styled from 'styled-components';

export const ThumbnailHolder = styled.div`
  width: 100%;
  img {
    width: 50px;
    height: 50px;
    overflow: hidden;
    background-position: center;
  }
`;

export const AmountInput = styled.input`
  max-width: 12.25rem;
  overflow: hidden;
  padding: 1rem;
  border: 1px solid #666;
  border-radius: 5px;
  font-size: 1.2rem;
  text-align: right;
  resize: none;
  &[type='number']::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    margin-left: 4px;
  }
  &[type='number']::placeholder {
    text-align: right;
  }
`;

const ModalDiv = styled.div`
  display: ${(p) => p.block && p.block};
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 19;
`;

const ContentDiv = styled.div`
  position: fixed;
  background: white;
  width: 50%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  z-index: 20;
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;

const ModalButton = styled.button`
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  background-color: midnightblue;
  color: white;
  border: none;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const ProgressWrapper = styled.div`
  background-color: #d8d8d8;
  border-radius: 20px;
  position: relative;
  margin: 15px 0;
  height: 30px;
  width: 300px;
`;

export const ProgressDone = styled.div`
  box-shadow: 0 3px 3px -5px midnightblue, 0 2px 5px midnightblue;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
`;

export const StyledModal = ({ handleCloser, show, children }) => {
  return (
    <ModalDiv block={show ? 'block' : 'none'}>
      <ContentDiv>
        <FlexDiv>
          {children}
          <ModalButton onClick={handleCloser}>Close</ModalButton>
        </FlexDiv>
      </ContentDiv>
    </ModalDiv>
  );
};
