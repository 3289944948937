import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../Util/ScrollToTop';
import { useLanguage } from '../contexts/LanguageContext';

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
`;

const OrgHeader = styled.h1`
  font-weight: 300;
  font-size: 2.5rem;
  line-height: 3rem;
`;

const OrgSubHeader = styled.h3`
  font-weight: bold;
  font-size: 1.5rem;
`;

const OrgText = styled.p`
  font-weight: 400;
  font-size: 1.25rem;
`;

const FormBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Form = styled.form`
  input {
    min-width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  button {
    background-color: #191970;
    padding: 15px;
    font-size: 1rem;
    border-radius: 8px;
    color: white;
    border: none;
    margin-top: 20px;
    margin-bottom: 40px;
    width: 100%;
    cursor: pointer;
    &:hover {
      background-color: darkblue;
    }
  }
`;

const FormCard = styled.div`
  width: 95%;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  border-top: 8px solid #191970;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
`;

const ContentHolder = styled.div`
  display: grid;
  grid-template-areas: 'box1 box2';
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-areas:
      'box1'
      'box2';
    grid-template-columns: 1fr;
  }
`;

const Box1 = styled.div`
  grid-area: box1;
  padding: 20px;
`;

const Box2 = styled.div`
  grid-area: box2;
  margin-top: 30px;
  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;

const SelectBox = styled.select`
  width: 99%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  border-radius: 8px;
`;

const TextBox = styled.textarea`
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  border-radius: 8px;
`;

const SITE_KEY = '6LcBl6cfAAAAAGNWBiKqXDXE3-OORvLko2-h6TcP';

const Organization = (props) => {
  const [orgName, setOrgName] = useState('');
  const [orgWebsite, setOrgWebsite] = useState('');
  const [orgId, setOrgId] = useState('');
  const [sent, setSent] = useState(false);
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [country, setCountry] = useState('');
  const [anualRange, setAnualRange] = useState('');
  const [hearAboutUs, setHearAboutUs] = useState('');
  const [message, setMessage] = useState('');
  const { language, getLanguageContent } = useLanguage();
  const [content, setContent] = useState(getLanguageContent('OrgTrans'));
  const navigate = useNavigate();

  useEffect(() => {
    setContent(getLanguageContent('OrgTrans'));
  }, [language]);

  useEffect(() => {
    //load in google recaptcha v3
    const googleRecaptchaId = 'google-recaptcha';
    const loadScriptByUrl = (id, url, callback) => {
      const existingScript = document.getElementById(id);
      if (!existingScript) {
        console.log('Creating Google Recaptcha script');
        const script = document.createElement('script');
        script.src = url;
        script.id = id;
        script.onload = () => {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
    };
    loadScriptByUrl(
      googleRecaptchaId,
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      () => {
        console.log('Google recaptcha loaded');
      }
    );
    return () => {
      document.querySelector(`#${googleRecaptchaId}`).remove();
      document.querySelector('.grecaptcha-badge').parentNode.remove();
    };
  }, []);

  const submitOrgForm = (e) => {
    e.preventDefault();
    if (
      orgName === '' ||
      orgWebsite === '' ||
      fname === '' ||
      lname === '' ||
      email === '' ||
      phone === '' ||
      jobTitle === '' ||
      country === '' ||
      anualRange === '' ||
      hearAboutUs === ''
    ) {
      toast.error(content.toastError);
    } else {
      setSent(true);
      navigate('/');
      toast.success(content.toastSent);
    }

    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute(SITE_KEY, { action: 'request_organization' })
        .then(async function (token) {
          //post to server
          const resp = await fetch('/api/organization/request', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              firstName: fname,
              lastName: lname,
              jobTitle: jobTitle,
              email,
              // phoneNumber: phone,
              orgName,
              orgUrl: orgWebsite,
              orgCountry: country,
              revenue: anualRange,
              // ein: orgId,
              marketingFrom: hearAboutUs,
              message,
              token,
            }),
          });
          const data = await resp.json();
          if (data.ok) {
            setSent(true);
            navigate('/');
            toast.success(content.toastSent);
          }
        });
    });
  };

  return (
    <>
      <Helmet>
        <title>I WILL FUND | {content.orgTopTitle}</title>
        <meta name='description' content={content.content} />
        <meta name='theme-color' content='#191970' />
      </Helmet>
      <Container>
        <ScrollToTop />
        <ContentHolder>
          <Box1>
            <OrgHeader>{content.organization}</OrgHeader>
            <OrgSubHeader>{content.orgAreYou}</OrgSubHeader>
            <OrgText>{content.orgText}</OrgText>
            <OrgSubHeader>{content.dontMissOut}</OrgSubHeader>
            <OrgText>
              {content.takeAdvantage}
              <span style={{ color: 'red' }}>
                <sup>*</sup>
              </span>
              .
            </OrgText>
            <small>
              <span style={{ color: 'red' }}>
                <sup>*</sup>
              </span>
              {content.orgNonprofit}
            </small>
          </Box1>
          <Box2>
            {!sent ? (
              <FormCard>
                <Form>
                  <h3>{content.tellAboutYourself}</h3>

                  <label>
                    {content.firstName}
                    <span style={{ color: 'red' }}>
                      <sup>*</sup>
                    </span>
                  </label>
                  <input
                    type='text'
                    value={fname}
                    onChange={(e) => setFname(e.target.value)}
                    required
                  />
                  <label>
                    {content.lastName}
                    <span style={{ color: 'red' }}>
                      <sup>*</sup>
                    </span>
                  </label>
                  <input
                    type='text'
                    value={lname}
                    onChange={(e) => setLname(e.target.value)}
                    required
                  />
                  <label>
                    {content.applicantEmail}
                    <span style={{ color: 'red' }}>
                      <sup>*</sup>
                    </span>
                  </label>
                  <input
                    type='text'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <label>
                    {content.applicantJobTitle}
                    <span style={{ color: 'red' }}>
                      <sup>*</sup>
                    </span>
                  </label>
                  <input
                    type='text'
                    value={jobTitle}
                    onChange={(e) => setJobTitle(e.target.value)}
                    required
                  />
                  <label>{content.applicantPhone}</label>
                  <input
                    type='text'
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <hr />
                  <h3>{content.tellAboutOrg}</h3>
                  <label>
                    {content.oName}
                    <span style={{ color: 'red' }}>
                      <sup>*</sup>
                    </span>
                  </label>
                  <input
                    type='text'
                    value={orgName}
                    onChange={(e) => setOrgName(e.target.value)}
                    required
                  />
                  <label>
                    {content.oWebsite}
                    <span style={{ color: 'red' }}>
                      <sup>*</sup>
                    </span>
                  </label>
                  <input
                    type='text'
                    value={orgWebsite}
                    onChange={(e) => setOrgWebsite(e.target.value)}
                    required
                  />
                  <label htmlFor='country'>{content.oCountry}</label>
                  <span style={{ color: 'red' }}>
                    <sup>*</sup>
                  </span>
                  <br />
                  <SelectBox
                    id='country'
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    <option value=''>{content.selectCountry}</option>
                    <option value='United States'>{content.usa}</option>
                    <option value='Afghanistan'>{content.afghanistan}</option>
                    <option value='Åland Islands'>
                      {content.alandIslands}
                    </option>
                    <option value='Albania'>{content.albania}</option>
                    <option value='Algeria'>{content.Algeria}</option>
                    <option value='American Samoa'>
                      {content.AmericanSamoa}
                    </option>
                    <option value='Andorra'>{content.Andorra}</option>
                    <option value='Angola'>{content.Angola}</option>
                    <option value='Anguilla'>{content.Anguilla}</option>
                    <option value='Antarctica'>{content.Antarctica}</option>
                    <option value='Antigua and Barbuda'>
                      {content.AntiguaandBarbuda}
                    </option>
                    <option value='Argentina'>{content.Argentina}</option>
                    <option value='Armenia'>{content.Armenia}</option>
                    <option value='Aruba'>{content.Aruba}</option>
                    <option value='Australia'>{content.Australia}</option>
                    <option value='Austria'>{content.Austria}</option>
                    <option value='Azerbaijan'>{content.Azerbaijan}</option>
                    <option value='Bahamas'>{content.Bahamas}</option>
                    <option value='Bahrain'>{content.Bahrain}</option>
                    <option value='Bangladesh'>{content.Bangladesh}</option>
                    <option value='Barbados'>{content.Barbados}</option>
                    <option value='Belarus'>{content.Belarus}</option>
                    <option value='Belgium'>{content.Belgium}</option>
                    <option value='Belize'>{content.Belize}</option>
                    <option value='Benin'>{content.Benin}</option>
                    <option value='Bermuda'>{content.Bermuda}</option>
                    <option value='Bhutan'>{content.Bhutan}</option>
                    <option value='Bolivia'>{content.Bolivia}</option>
                    <option value='Bosnia and Herzegovina'>
                      {content.BosniaandHerzegovina}
                    </option>
                    <option value='Botswana'>{content.Botswana}</option>
                    <option value='Bouvet Island'>
                      {content.BouvetIsland}
                    </option>
                    <option value='Brazil'>{content.Brazil}</option>
                    <option value='British Indian Ocean Territory'>
                      {content.BritishIndianOceanTerritory}
                    </option>
                    <option value='Brunei Darussalam'>
                      {content.BruneiDarussalam}
                    </option>
                    <option value='Bulgaria'>{content.Bulgaria}</option>
                    <option value='Burkina Faso'>{content.BurkinaFaso}</option>
                    <option value='Burundi'>{content.Burundi}</option>
                    <option value='Cambodia'>{content.Cambodia}</option>
                    <option value='Cameroon'>{content.Cameroon}</option>
                    <option value='Canada'>{content.Canada}</option>
                    <option value='Cape Verde'>{content.CapeVerde}</option>
                    <option value='Cayman Islands'>
                      {content.CaymanIslands}
                    </option>
                    <option value='Central African Republic'>
                      {content.CentralAfricanRepublic}
                    </option>
                    <option value='Chad'>{content.Chad}</option>
                    <option value='Chile'>{content.Chile}</option>
                    <option value='China'>{content.China}</option>
                    <option value='Christmas Island'>
                      {content.ChristmasIsland}
                    </option>
                    <option value='Cocos (Keeling) Islands'>
                      {content.CocosKeelingIslands}
                    </option>
                    <option value='Colombia'>{content.Colombia}</option>
                    <option value='Comoros'>{content.Comoros}</option>
                    <option value='Congo'>{content.Congo}</option>
                    <option value='Congo, The Democratic Republic of The'>
                      {content.CongoTheDemocraticRepublicofThe}
                    </option>
                    <option value='Cook Islands'>{content.CookIslands}</option>
                    <option value='Costa Rica'>{content.CostaRica}</option>
                    <option value="Cote D'ivoire">{content.CoteDivoire}</option>
                    <option value='Croatia'>{content.Croatia}</option>
                    <option value='Cuba'>{content.Cuba}</option>
                    <option value='Cyprus'>{content.Cyprus}</option>
                    <option value='Czech Republic'>
                      {content.CzechRepublic}
                    </option>
                    <option value='Denmark'>{content.Denmark}</option>
                    <option value='Djibouti'>{content.Djibouti}</option>
                    <option value='Dominica'>{content.Dominica}</option>
                    <option value='Dominican Republic'>
                      {content.DominicanRepublic}
                    </option>
                    <option value='Ecuador'>{content.Ecuador}</option>
                    <option value='Egypt'>{content.Egypt}</option>
                    <option value='El Salvador'>{content.ElSalvador}</option>
                    <option value='Equatorial Guinea'>
                      {content.EquatorialGuinea}
                    </option>
                    <option value='Eritrea'>{content.Eritrea}</option>
                    <option value='Estonia'>{content.Estonia}</option>
                    <option value='Ethiopia'>{content.Ethiopia}</option>
                    <option value='Falkland Islands (Malvinas)'>
                      {content.FalklandIslandsMalvinas}
                    </option>
                    <option value='Faroe Islands'>
                      {content.FaroeIslands}
                    </option>
                    <option value='Fiji'>{content.Fiji}</option>
                    <option value='Finland'>{content.Finland}</option>
                    <option value='France'>{content.France}</option>
                    <option value='French Guiana'>
                      {content.FrenchGuiana}
                    </option>
                    <option value='French Polynesia'>
                      {content.FrenchPolynesia}
                    </option>
                    <option value='French Southern Territories'>
                      {content.FrenchSouthernTerritories}
                    </option>
                    <option value='Gabon'>{content.Gabon}</option>
                    <option value='Gambia'>{content.Gambia}</option>
                    <option value='Georgia'>{content.Georgia}</option>
                    <option value='Germany'>{content.Germany}</option>
                    <option value='Ghana'>{content.Ghana}</option>
                    <option value='Gibraltar'>{content.Gibraltar}</option>
                    <option value='Greece'>{content.Greece}</option>
                    <option value='Greenland'>{content.Greenland}</option>
                    <option value='Grenada'>{content.Grenada}</option>
                    <option value='Guadeloupe'>{content.Guadeloupe}</option>
                    <option value='Guam'>{content.Guam}</option>
                    <option value='Guatemala'>{content.Guatemala}</option>
                    <option value='Guernsey'>{content.Guernsey}</option>
                    <option value='Guinea'>{content.Guinea}</option>
                    <option value='Guinea-bissau'>
                      {content.Guineabissau}
                    </option>
                    <option value='Guyana'>{content.Guyana}</option>
                    <option value='Haiti'>{content.Haiti}</option>
                    <option value='Heard Island and Mcdonald Islands'>
                      {content.HeardIslandandMcdonaldIslands}
                    </option>
                    <option value='Holy See (Vatican City State)'>
                      {content.HolySeeVaticanCityState}
                    </option>
                    <option value='Honduras'>{content.Honduras}</option>
                    <option value='Hong Kong'>{content.HongKong}</option>
                    <option value='Hungary'>{content.Hungary}</option>
                    <option value='Iceland'>{content.Iceland}</option>
                    <option value='India'>{content.India}</option>
                    <option value='Indonesia'>{content.Indonesia}</option>
                    <option value='Iran, Islamic Republic of'>
                      {content.IranIslamicRepublicof}
                    </option>
                    <option value='Iraq'>{content.Iraq}</option>
                    <option value='Ireland'>{content.Ireland}</option>
                    <option value='Isle of Man'>{content.IsleofMan}</option>
                    <option value='Israel'>{content.Israel}</option>
                    <option value='Italy'>{content.Italy}</option>
                    <option value='Jamaica'>{content.Jamaica}</option>
                    <option value='Japan'>{content.Japan}</option>
                    <option value='Jersey'>{content.Jersey}</option>
                    <option value='Jordan'>{content.Jordan}</option>
                    <option value='Kazakhstan'>{content.Kazakhstan}</option>
                    <option value='Kenya'>{content.Kenya}</option>
                    <option value='Kiribati'>{content.Kiribati}</option>
                    <option value="Korea, Democratic People's Republic of">
                      {content.KoreaDemocraticPeoplesRepublicof}
                    </option>
                    <option value='Korea, Republic of'>
                      {content.KoreaRepublicof}
                    </option>
                    <option value='Kuwait'>{content.Kuwait}</option>
                    <option value='Kyrgyzstan'>{content.Kyrgyzstan}</option>
                    <option value="Lao People's Democratic Republic">
                      {content.LaoPeoplesDemocraticRepublic}
                    </option>
                    <option value='Latvia'>{content.Latvia}</option>
                    <option value='Lebanon'>{content.Lebanon}</option>
                    <option value='Lesotho'>{content.Lesotho}</option>
                    <option value='Liberia'>{content.Liberia}</option>
                    <option value='Libyan Arab Jamahiriya'>
                      {content.LibyanArabJamahiriya}
                    </option>
                    <option value='Liechtenstein'>
                      {content.Liechtenstein}
                    </option>
                    <option value='Lithuania'>{content.Lithuania}</option>
                    <option value='Luxembourg'>{content.Luxembourg}</option>
                    <option value='Macao'>{content.Macao}</option>
                    <option value='Macedonia, The Former Yugoslav Republic of'>
                      {content.MacedoniaTheFormerYugoslavRepublicof}
                    </option>
                    <option value='Madagascar'>{content.Madagascar}</option>
                    <option value='Malawi'>{content.Malawi}</option>
                    <option value='Malaysia'>{content.Malaysia}</option>
                    <option value='Maldives'>{content.Maldives}</option>
                    <option value='Mali'>{content.Mali}</option>
                    <option value='Malta'>{content.Malta}</option>
                    <option value='Marshall Islands'>
                      {content.MarshallIslands}
                    </option>
                    <option value='Martinique'>{content.Martinique}</option>
                    <option value='Mauritania'>{content.Mauritania}</option>
                    <option value='Mauritius'>{content.Mauritius}</option>
                    <option value='Mayotte'>{content.Mayotte}</option>
                    <option value='Mexico'>{content.Mexico}</option>
                    <option value='Micronesia, Federated States of'>
                      {content.MicronesiaFederatedStatesof}
                    </option>
                    <option value='Moldova, Republic of'>
                      {content.MoldovaRepublicof}
                    </option>
                    <option value='Monaco'>{content.Monaco}</option>
                    <option value='Mongolia'>{content.Mongolia}</option>
                    <option value='Montenegro'>{content.Montenegro}</option>
                    <option value='Montserrat'>{content.Montserrat}</option>
                    <option value='Morocco'>{content.Morocco}</option>
                    <option value='Mozambique'>{content.Mozambique}</option>
                    <option value='Myanmar'>{content.Myanmar}</option>
                    <option value='Namibia'>{content.Namibia}</option>
                    <option value='Nauru'>{content.Nauru}</option>
                    <option value='Nepal'>{content.Nepal}</option>
                    <option value='Netherlands'>{content.Netherlands}</option>
                    <option value='Netherlands Antilles'>
                      {content.NetherlandsAntilles}
                    </option>
                    <option value='New Caledonia'>
                      {content.NewCaledonia}
                    </option>
                    <option value='New Zealand'>{content.NewZealand}</option>
                    <option value='Nicaragua'>{content.Nicaragua}</option>
                    <option value='Niger'>{content.Niger}</option>
                    <option value='Nigeria'>{content.Nigeria}</option>
                    <option value='Niue'>{content.Niue}</option>
                    <option value='Norfolk Island'>
                      {content.NorfolkIsland}
                    </option>
                    <option value='Northern Mariana Islands'>
                      {content.NorthernMarianaIslands}
                    </option>
                    <option value='Norway'>{content.Norway}</option>
                    <option value='Oman'>{content.Oman}</option>
                    <option value='Pakistan'>{content.Pakistan}</option>
                    <option value='Palau'>{content.Palau}</option>
                    <option value='Palestinian Territory, Occupied'>
                      {content.PalestinianTerritoryOccupied}
                    </option>
                    <option value='Panama'>{content.Panama}</option>
                    <option value='Papua New Guinea'>
                      {content.PapuaNewGuinea}
                    </option>
                    <option value='Paraguay'>{content.Paraguay}</option>
                    <option value='Peru'>{content.Peru}</option>
                    <option value='Philippines'>{content.Philippines}</option>
                    <option value='Pitcairn'>{content.Pitcairn}</option>
                    <option value='Poland'>{content.Poland}</option>
                    <option value='Portugal'>{content.Portugal}</option>
                    <option value='Puerto Rico'>{content.PuertoRico}</option>
                    <option value='Qatar'>{content.Qatar}</option>
                    <option value='Reunion'>{content.Reunion}</option>
                    <option value='Romania'>{content.Romania}</option>
                    <option value='Russian Federation'>
                      {content.RussianFederation}
                    </option>
                    <option value='Rwanda'>{content.Rwanda}</option>
                    <option value='Saint Helena'>{content.SaintHelena}</option>
                    <option value='Saint Kitts and Nevis'>
                      {content.SaintKittsandNevis}
                    </option>
                    <option value='Saint Lucia'>{content.SaintLucia}</option>
                    <option value='Saint Pierre and Miquelon'>
                      {content.SaintPierreandMiquelon}
                    </option>
                    <option value='Saint Vincent and The Grenadines'>
                      {content.SaintVincentandTheGrenadines}
                    </option>
                    <option value='Samoa'>{content.Samoa}</option>
                    <option value='San Marino'>
                      San Marino{content.SanMarino}
                    </option>
                    <option value='Sao Tome and Principe'>
                      {content.SaoTomeandPrincipe}
                    </option>
                    <option value='Saudi Arabia'>{content.SaudiArabia}</option>
                    <option value='Senegal'>{content.Senegal}</option>
                    <option value='Serbia'>{content.Serbia}</option>
                    <option value='Seychelles'>{content.Seychelles}</option>
                    <option value='Sierra Leone'>{content.SierraLeone}</option>
                    <option value='Singapore'>{content.Singapore}</option>
                    <option value='Slovakia'>{content.Slovakia}</option>
                    <option value='Slovenia'>{content.Slovenia}</option>
                    <option value='Solomon Islands'>
                      {content.SolomonIslands}
                    </option>
                    <option value='Somalia'>{content.Somalia}</option>
                    <option value='South Africa'>{content.SouthAfrica}</option>
                    <option value='South Georgia and The South Sandwich Islands'>
                      {content.SouthGeorgiaandTheSouthSandwichIslands}
                    </option>
                    <option value='Spain'>{content.Spain}</option>
                    <option value='Sri Lanka'>{content.SriLanka}</option>
                    <option value='Sudan'>{content.Sudan}</option>
                    <option value='Suriname'>{content.Suriname}</option>
                    <option value='Svalbard and Jan Mayen'>
                      {content.SvalbardandJanMayen}
                    </option>
                    <option value='Swaziland'>{content.Swaziland}</option>
                    <option value='Sweden'>{content.Sweden}</option>
                    <option value='Switzerland'>{content.Switzerland}</option>
                    <option value='Syrian Arab Republic'>
                      {content.SyrianArabRepublic}
                    </option>
                    <option value='Taiwan'>{content.Taiwan}</option>
                    <option value='Tajikistan'>{content.Tajikistan}</option>
                    <option value='Tanzania, United Republic of'>
                      {content.TanzaniaUnitedRepublicof}
                    </option>
                    <option value='Thailand'>{content.Thailand}</option>
                    <option value='Timor-leste'>{content.Timorleste}</option>
                    <option value='Togo'>{content.Togo}</option>
                    <option value='Tokelau'>{content.Tokelau}</option>
                    <option value='Tonga'>{content.Tonga}</option>
                    <option value='Trinidad and Tobago'>
                      {content.TrinidadandTobago}
                    </option>
                    <option value='Tunisia'>{content.Tunisia}</option>
                    <option value='Turkey'>{content.Turkey}</option>
                    <option value='Turkmenistan'>{content.Turkmenistan}</option>
                    <option value='Turks and Caicos Islands'>
                      {content.TurksandCaicosIslands}
                    </option>
                    <option value='Tuvalu'>{content.Tuvalu}</option>
                    <option value='Uganda'>{content.Uganda}</option>
                    <option value='Ukraine'>{content.Ukraine}</option>
                    <option value='United Arab Emirates'>
                      {content.UnitedArabEmirates}
                    </option>
                    <option value='United Kingdom'>
                      {content.UnitedKingdom}
                    </option>
                    <option value='United States Minor Outlying Islands'>
                      {content.UnitedStatesMinorOutlyingIslands}
                    </option>
                    <option value='Uruguay'>{content.Uruguay}</option>
                    <option value='Uzbekistan'>{content.Uzbekistan}</option>
                    <option value='Vanuatu'>{content.Vanuatu}</option>
                    <option value='Venezuela'>{content.Venezuela}</option>
                    <option value='Viet Nam'>{content.VietNam}</option>
                    <option value='Virgin Islands, British'>
                      {content.VirginIslandsBritish}
                    </option>
                    <option value='Virgin Islands, U.S.'>
                      {content.VirginIslandsUS}
                    </option>
                    <option value='Wallis and Futuna'>
                      {content.WallisandFutuna}
                    </option>
                    <option value='Western Sahara'>
                      {content.WesternSahara}
                    </option>
                    <option value='Yemen'>{content.Yemen}</option>
                    <option value='Zambia'>{content.Zambia}</option>
                    <option value='Zimbabwe'>{content.Zimbabwe}</option>
                  </SelectBox>
                  <label>{content.oId}</label>
                  <input
                    type='text'
                    value={orgId}
                    onChange={(e) => setOrgId(e.target.value)}
                    required
                  />
                  <label>
                    {content.anRev}
                    <span style={{ color: 'red' }}>
                      <sup>*</sup>
                    </span>
                  </label>
                  <SelectBox
                    value={anualRange}
                    onChange={(e) => setAnualRange(e.target.value)}
                  >
                    <option value=''>{content.pleaseSelect}</option>
                    <option value='under200'>{content.under200k}</option>
                    <option value='200To500'>{content.between200k500k}</option>
                    <option value='500To1M'>{content.fivehundK1M}</option>
                    <option value='1MTo5M'>{content.oneM5M}</option>
                    <option value='5MTo10M'>{content.fiveM10M}</option>
                    <option value='10MTo100M'>{content.tenM100M}</option>
                    <option value='100MPlus'>{content.oneHundMPlus}</option>
                  </SelectBox>
                  <hr />
                  <label>
                    {content.youHearAboutUs}
                    <span style={{ color: 'red' }}>
                      <sup>*</sup>
                    </span>
                  </label>
                  <SelectBox
                    value={hearAboutUs}
                    onChange={(e) => setHearAboutUs(e.target.value)}
                  >
                    <option value=''>{content.pleaseSelect}</option>
                    <option value='searchEngine'>{content.searchEngine}</option>
                    <option value='socialMedia'>{content.socialMedia}</option>
                    <option value='advertising'>{content.advertising}</option>
                    <option value='news'>{content.news}</option>
                    <option value='newsletter'>{content.newsletter}</option>
                    <option value='onlineevent'>{content.onlineEvent}</option>
                    <option value='inpersonevent'>
                      {content.inPersonEvent}
                    </option>
                    <option value='wordofmouth'>{content.wordOfMouth}</option>
                    <option value='other'>{content.other}</option>
                  </SelectBox>
                  <label>{content.messaging}</label>
                  <TextBox
                    cols='30'
                    rows='5'
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></TextBox>
                  <button onClick={submitOrgForm}>{content.send}</button>
                </Form>
              </FormCard>
            ) : (
              <p>{content.requestSent}</p>
            )}
          </Box2>
        </ContentHolder>
      </Container>
    </>
  );
};

export default Organization;
