import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './navigation/Navigation';
import Home from '../Page/Home';
import About from '../Page/About';
import CreateCampaign from '../Page/CreateCampaign';
import EditCampaign from '../Page/EditCampaign';
import CampaignsPage from '../Page/CampaignsPage';
import Campaign from '../Page/Campaign';
import Profile from '../Page/Profile';
import Settings from '../Page/Settings';
import Faqs from './Faqs/Faqs';
import Organization from '../Page/Organization';
import { ToastContainer } from 'react-toastify';
import Footer from '../Page/Footer';
import TermsOfService from '../Page/TermsOfService';
import PrivacyPolicy from '../Page/PrivacyPolicy';
import RiskDisclaimer from '../Page/RiskDisclaimer';
import OverlayScreen from './shared/Overlay/OverlayScreen';
import NotFoundPage from '../Page/NotFoundPage';
import OrganizationForm from '../Page/OrganizationForm';
import { useLanguage } from '../contexts/LanguageContext';

const Main = () => {
  const { storeLanguageInLocalStorage } = useLanguage();

  return (
    <>
      <OverlayScreen />
      <Navigation
        handleSetLanguage={(language) => {
          storeLanguageInLocalStorage(language);
        }}
      />
      <ToastContainer />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/#about' element={<About />} />
        <Route path='/#faqs' element={<Faqs />} />
        <Route path='/create-campaign' element={<CreateCampaign />} />
        <Route path='/edit-campaign/:id' element={<EditCampaign />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/campaigns' element={<CampaignsPage />} />
        <Route path='/campaign/:id' element={<Campaign />} />
        <Route path='/settings' element={<Settings />} />
        <Route path='/terms-of-service' element={<TermsOfService />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/risk-disclaimer' element={<RiskDisclaimer />} />
        <Route path='/organization' element={<Organization />} />
        <Route path='/organization-form' element={<OrganizationForm />} />
        <Route path='/*' element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </>
  );
};

export default Main;
