import styled from 'styled-components';

export const CampaignCreateHeader = styled.h1`
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
`;

export const CampaignCreateErrorMsg = styled.div`
  color: red;
  font-size: 1rem;
`;

export const CampaignCreateInput = styled.input`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0.5rem;
  width: 95%;
  font-family: 'Roboto', sans-serif;
  color: #333;
  &[type='date'] {
    font-family: 'Roboto', sans-serif;
    color: #333;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
`;

export const CampaignCreateTextArea = styled.textarea`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0.5rem;
  width: 95%;
  font-family: 'Roboto', sans-serif;
  color: #333;
`;

export const CampaignCreateSelect = styled.select`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0.5rem;
  width: 95%;
  font-family: 'Roboto', sans-serif;
  color: #333;
`;

export const CalendarLabel = styled.label`
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
`;
