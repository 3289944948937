export default {
  English: {
    latestTen: 'Latest 10 Campaigns',
    topFunded: 'Top Funded Campaigns',
    topDailyVolume: 'Top Daily Volume Campaigns',
    topTenMessage1: 'Top Ten Campaigns Not Visible Yet',
    topTenMessage2: 'Please Refresh your browser or Check Back Soon',
    topFundedMessage1: ' No Top Funded Campaigns Yet',
    topFundedMessage2: 'Please Refresh your browser or Check Back Soon',
    topDailyVolumeMessage1: ' No Top Daily Volume Campaigns Yet',
    topDailyVolumeMessage2: 'Please Refresh your browser or Check Back Soon',
  },
  Spanish: {
    latestTen: 'Últimos 10 Campañas',
    topFunded: 'Campañas con mayor recaudación',
    topDailyVolume: 'Campañas con mayor volumen diario',
    topTenMessage1: 'No hay campañas en la lista',
    topTenMessage2: 'Por favor refresca la página o regresa pronto',
    topFundedMessage1: 'No hay campañas en la lista',
    topFundedMessage2: 'Por favor refresca la página o regresa pronto',
    topDailyVolumeMessage1: 'No hay campañas en la lista',
    topDailyVolumeMessage2: 'Por favor refresca la página o regresa pronto',
  },
};
