export default {
    English: {
        about: 'ABOUT',
        aboutSub: "What We're All About",
        create: 'Creating ',
        aboutText:
          "was an easy decision as we wanted to create a system of funding for those already familiar with the concept of crowdfunding and cryptocurrencies. We wanted to create a platform that would allow anyone to create a campaign and have it funded with ease. Donating we felt shouldn't be a hassle so we also wanted to make it as easy as possible for the user to donate.",
        meetTeam: 'MEET THE TEAM',
        meetScott: 'MEET SCOTT',
        meetScottText:
          'Scott, the Chief Blockchain Officer (CBO), loves to write code, play video games, and watch movies/tv shows. He also loves to spend time with his wife and son. He believes that blockchain and defi are the future of tech/banking. Therefore, he wanted to develop some project that uses the blockchain. Hence,',
        meetScottTextTwo:
          'was born. Crowdfunding via crypto currency instead of fiat. He is going to leave you with a joke that he made up.',
        meetScottTextThree:
          "When you drive a car you must be an engineer. Why? Because there is an 'engine near' you.",
        meetErik: 'MEET ERIK',
        meetErikText:
          'Erik, the Senior FrontEnd Developer, Started out in developing because he just wanted to build cool stuff that people could enjoy online. He is an avid fan of anything related to Electro-Gravitics and Anti-Gravity research as well as first  person shooter video games.',
        meetErikTextTwo:
          'Seeing that the blockchain and defi is here to stay, he wanted to play a part in using that technology to help people fund their dreams and projects.',
        meetBere: 'MEET BERE',
        meetBereText:
          'Berenice, the strategic marketer with more than 15 years of experience and great enthusiasm for innovation and technology. She is an entrepreneur, travel lover and an avid cryptocurrency and Data Miner!',
        meetBereTextTwo:
          'She wants to bring new technologies closer to as many people as possible and connect dreams to reality!',
      },
      Spanish: {
        about: 'NOSOTROS',
        aboutSub: 'Acerca de Nosotros',
        create: 'Crear ',
        aboutText:
          'fue una decisión fácil porque queremos crear un sistema de financiación para aquellos que ya están familiarizados con el concepto de crowdfunding y las criptomonedas. Queríamos crear una plataforma que permitiera a cualquier persona crear una campaña y hacerla financiada con facilidad. Donar nos sentía que no debería ser una molestia así que también queremos hacerla tan fácil como sea posible para el usuario para donar.',
        meetTeam: 'Conozca a nuestro Equipo',
        meetScott: 'CONOZCA A SCOTT',
        meetScottText:
          'Scott, el Jefe de Blockchain (CBO), ama escribir código, jugar videojuegos y ver películas/series. También ama pasar tiempo con su esposa y hijo. Creen que blockchain y defi son el futuro de tecnología/bancos. Por lo tanto, quiso desarrollar algún proyecto que utiliza la blockchain. Por lo tanto,',
        meetScottTextTwo:
          'nació. Crowdfunding a través de criptomonedas en lugar de fiat',
        meetScottTextThree: '',
        meetErik: 'CONOZCA A ERIK',
        meetErikText:
          'Erik, el Desarrollador FrontEnd Senior, Empezó en desarrollando porque quiso construir cosas que los usuarios puedan disfrutar en línea. Es un aficionado de cualquier cosa relacionada con Electro-Gravitics y Anti-Gravity investigación como también videojuegos de primera persona.',
        meetErikTextTwo:
          'Viendo que la blockchain y defi está aquí para siempre, quiso participar en utilizar esa tecnología para ayudar a los usuarios a financiar sus sueños y proyectos.',
        meetBere: 'CONOZCA A BERE',
        meetBereText:
          'Berenice, la marketing estratégica con más de 15 años de experiencia y gran entusiasmo por innovación y tecnología. Es una emprendedora, amante de viajes y una autodidacta de criptomonedas y minería de datos!',
        meetBereTextTwo:
          'Quiere traer nuevas tecnologías más cerca a tantos usuarios como sea posible y conectar sueños a realidad!',
      },
}