import React from 'react';
import BNBCoin from '../../assets/bnbcoin.gif';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1999;
`;

const LodingImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  z-index: 2000;
  h4 {
    color: #fff;
  }
`;

const CreationLoading = ({ text }) => {
  return (
    <LoadingContainer>
      <LodingImageBox>
        <img src={BNBCoin} style={{ width: '150px' }} alt='Loading...' />
        {text && <h4>{text}</h4>}
      </LodingImageBox>
    </LoadingContainer>
  );
};

export default CreationLoading;
