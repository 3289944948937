export default {
  English: {
    campTopTitle: 'CAMPAIGN',
    noLongerActive: 'This Campaign is no Longer Active',
    edit: 'Edit',
    campaignAddress: 'Campaign Address',
    category: 'Category:',
    start: 'Start',
    end: 'End',
    closed: 'Closed ',
    closes: 'Closes ',
    comeSoon: 'Coming Soon',
    campaignClosed: 'Campaign Closed',
    aboutAuthor: 'About the Author',
    share: 'Share this Campaign',
    recentDonations: 'Recent Donations',
    copied: 'Copied to Clipboard',
    description: 'Description',
    metaContent:
      'On this page we can see the individual campaign details and the progress of the campaign. Here we get the campaign address, a progress bar showing how much has been donated and how much is left to reach the campaign. Also, we have the campaign category as well as the campaign start and end date. Additionally, we can see if the campaign is coming soon, or closing soon. If you want to donate, there is an I Will Fund Button that will open a donation dialog box. There is also a place where we can see who initialized the campaign un the Author section showing his or her wallet address. If one wants to share that campaign, there are Social Media icons that will allow the visitor to share that campaign on their social media account.',
  },
  Spanish: {
    campTopTitle: 'CAMPAÑA',
    noLongerActive: 'Esta Campaña ya no está activa',
    edit: 'Editar',
    campaignAddress: 'Dirección de la Campaña',
    category: 'Categoría:',
    start: 'Inicio',
    end: 'Fin',
    closed: 'Cerrado ',
    closes: 'Cierra ',
    comeSoon: 'Próximamente',
    campaignClosed: 'Campaña Cerrada',
    aboutAuthor: 'Sobre el Autor',
    share: 'Comparte esta Campaña',
    recentDonations: 'Donaciones Recientes',
    copied: 'Copiado al Portapapeles',
    description: 'Descripción',
    metaContent:
      'En esta página podemos ver la información individual de la campaña y el progreso de la misma. Aquí podemos ver la dirección de la campaña, una barra de progreso que muestra cuánto se ha donado y cuánto queda para llegar a la campaña. Además, podemos ver la categoría de la campaña, la fecha de inicio y fin de la misma. Además, podemos ver si la campaña está próxima o cerrada. Si quieres donar, hay un botón I Will Fund que abrirá un cuadro de diálogo de donación. También hay un lugar donde podemos ver quién inició la campaña en la sección de autor mostrando su dirección de wallet. Si quieres compartir esta campaña, hay iconos de redes sociales que permitirán al visitante compartir la misma campaña en sus redes sociales.',
  },
};
