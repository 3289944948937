import React, { Fragment, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import LoadingGif from '../assets/bnbcoin.gif';
import StateSelector from '../components/shared/StateSelector';
import {
  FormInput,
  FormHeader,
  CatSelect,
  StateSelect,
  MinMaxTokenInput,
  SearchButtons,
  StickySearch,
  SearchContainer,
  LeftPanel,
  RightPanel,
  EndlessScroll,
  SearchForm,
} from './pageStyles/CampaignsPageSearchStyles';
import Switch from 'react-switch';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDApp, getCampaignDetails } from '../contexts/web3';
import CardComponent from '../components/shared/CardComponent';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SearchIcon from '@mui/icons-material/Search';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../Util/ScrollToTop';
import { useLanguage } from '../contexts/LanguageContext';
import CampaignModel from '../Util/Classes/CampaignModel';

const CampaignsPage = (props) => {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [cSChecked, setCSChecked] = useState(false);
  const [closingsoon, setClosingSoon] = useState(false);
  const [comingSoon, setComingSoon] = useState(false);
  const [category, setCategory] = useState('');
  const [state, setState] = useState('');
  const [tokenMin, setTokenMin] = useState('');
  const [tokenMax, setTokenMax] = useState('');
  const [q, setQ] = useState('');
  const [uri, setUri] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const { web3R } = useDApp();
  const [chevronVisible, setChevronVisible] = useState(false);
  const [arrowStyle, setArrowStyle] = useState('');
  const [fadeOut, setFadeOut] = useState(1);
  const [arrowScale, setArrowScale] = useState(1);
  const [collapsePanel, setCollapsePanel] = useState('0');
  const [paddingCollapse, setPaddingCollapse] = useState('0');

  const { language, getLanguageContent } = useLanguage();
  const [content, setContent] = useState(getLanguageContent('CampaignsTrans'));

  useEffect(() => {
    setContent(getLanguageContent('CampaignsTrans'));
  }, [language]);

  // const [items, setItems] = useState(Array.from({ length: 20 }));

  const fetchMoreData = () => {
    // setTimeout(() => {
    //   setItems(items.concat(Array.from({ length: 20 })));
    // }, 1500);
    setPage((page) => page + 1);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getCampaigns();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [page, uri]);

  const getCampaigns = async () => {
    // console.log(page);
    setLoading(true);
    const response = await fetch(`/api/campaigns?page=${page}&perpage=6${uri}`);
    const data = await response.json();
    //grab data from blockchain
    const ar = [];
    for (const camp of data.data) {
      ar.push(getCampaignDetails(web3R, camp));
    }
    try {
      await Promise.all(ar);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    // console.log(data.data);
    const camps = [];
    // setCampaigns((prev) => [...prev, ...data.data]);
    for (const camp of data.data) {
      camps.push(new CampaignModel(camp));
    }
    setCampaigns((prev) => [...prev, ...camps]);
    // setLoading(false);
    setHasMore(data.hasNext);
  };

  const handleSearch = () => {
    setCampaigns([]);
    setPage(1);
    setUri(
      `&q=${q}&category=${category}&state=${state}&tokenmin=${tokenMin}&tokenmax=${tokenMax}&comingsoon=${comingSoon ? '1' : '0'
      }&closingsoon=${closingsoon ? '1' : '0'}&timestamp=${Date.now()}`
    );
  };

  const handleToggle = (e) => {
    setChecked(!checked);
    if (!checked) {
      setClosingSoon(true);
    } else {
      setClosingSoon(false);
    }
  };

  const handleToggleComingSoon = (e) => {
    setCSChecked(!cSChecked);
    if (!cSChecked) {
      setComingSoon(true);
    } else {
      setComingSoon(false);
    }
  };

  const handleClearSearch = () => {
    setPage(1);
    setCampaigns([]);
    setQ('');
    setCategory('');
    setState('');
    setTokenMin('');
    setTokenMax('');
    setClosingSoon(false);
    setComingSoon(false);
    setChecked(false);
    setCSChecked(false);
    setUri(`&timestamp=${Date.now()}`);
  };

  const onDonated = async (campaign, amount) => {
    //try it here....if this does not update here then the page itself it has to be
    //fetch the campaign
    try {
      const resp = await fetch('/api/campaign/' + campaign.id);
      const data = await resp.json();
      await getCampaignDetails(web3R, data);
      setCampaigns((prev) => {
        const newCampaigns = prev.map((c) => {
          if (c.id === data._id) {
            return new CampaignModel(data);
          }
          return c;
        });
        return newCampaigns;
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (chevronVisible) {
      setArrowStyle('100%');
    }
  }, []);

  const expandSearch = () => {
    setCollapsePanel('0');
    setPaddingCollapse('0');
    setFadeOut(0);
    setArrowScale('1');
    setTimeout(() => {
      setChevronVisible(!chevronVisible);
    }, 1500);
  };

  const collapseSearch = () => {
    setCollapsePanel('500px');
    setPaddingCollapse('1rem 1.5rem 3rem 1.5rem');
    setFadeOut('1');
    setArrowScale('0');
    setTimeout(() => {
      setChevronVisible(!chevronVisible);
    }, 1500);
  };

  return (
    <>
      <Helmet>
        <title>I WILL FUND | {content.campsTopTitle}</title>
        <meta name='description' content={content.metaContent} />
        <meta name='theme-color' content='#191970' />
      </Helmet>
      <Fragment>
        <ScrollToTop />
        <SearchContainer style={{ gridTemplateColumns: arrowStyle }}>
          {chevronVisible ? (
            <ExpandLessIcon
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                fontSize: '2.5rem',
                cursor: 'pointer',
                color: '#fff',
                opacity: fadeOut,
                transition: 'opacity .5s',
              }}
              onClick={expandSearch}
            />
          ) : (
            <SearchIcon
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                fontSize: '2rem',
                cursor: 'pointer',
                color: '#fff',
                transform: `scale(${arrowScale})`,
                transition: 'transform ease-in-out 1s',
              }}
              onClick={collapseSearch}
            />
          )}

          <LeftPanel
            style={{
              height: collapsePanel,
              transition: 'height 1s ease-in-out, padding 1.5s ease-in-out',
              padding: paddingCollapse,
            }}
          >
            <StickySearch>
              <FormHeader>{content.search}</FormHeader>
              <SearchForm>
                <FormInput
                  type='text'
                  placeholder={content.keywords}
                  value={q}
                  onChange={(e) => setQ(e.target.value)}
                />
                <CatSelect
                  name='category'
                  className='cat-input'
                  placeholder={content.cat}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value='' style={{ color: '#333' }}>
                    {content.cat}
                  </option>
                  <option value='General'>{content.general}</option>
                  <option value='Technology'>{content.tech}</option>
                  <option value='Fire Damage'>{content.fire}</option>
                  <option value='Health'>{content.health}</option>
                  <option value='Medicine'>{content.meds}</option>
                  <option value='Invention'>{content.invent}</option>
                  <option value='Education'>{content.ed}</option>
                  <option value='Environment'>{content.env}</option>
                  <option value='Science'>{content.sci}</option>
                  {/* {options.map((option, index) => (
                    <option value={option || ''} key={index}>
                      {option}
                    </option>
                  ))} */}
                </CatSelect>
                <StateSelect
                  name='state'
                  style={{
                    padding: '7px',
                    color: '#495057',
                    borderRadius: '5px',
                    border: 'solid 1px #c4c4c4',
                  }}
                  onChange={(e) => setState(e.target.value)}
                  value={state}
                >
                  <StateSelector
                    language={props.language}
                    placeholder={content.state}
                  />
                </StateSelect>
                <label>{content.amount}</label>
                <MinMaxTokenInput>
                  <FormInput
                    type='number'
                    min='0'
                    placeholder='Min'
                    onChange={(e) => setTokenMin(e.target.value)}
                  />
                  <FormInput
                    type='number'
                    min='1'
                    placeholder='Max'
                    onChange={(e) => setTokenMax(e.target.value)}
                  />
                </MinMaxTokenInput>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <label
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <span style={{ marginTop: '5px', marginBottom: '5px' }}>
                      {content.closeSoon}
                    </span>
                    <Switch
                      color='warning'
                      type='checkbox'
                      checked={checked}
                      onChange={handleToggle}
                      aria-label={'secondary checkbox'}
                    />
                  </label>

                  <label
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <span style={{ marginTop: '5px', marginBottom: '5px' }}>
                      {content.comeSoon}
                    </span>
                    <Switch
                      color='warning'
                      type='checkbox'
                      checked={cSChecked}
                      onChange={handleToggleComingSoon}
                      aria-label={'secondary checkbox'}
                    />
                  </label>
                </div>

                <SearchButtons className='search-buttons'>
                  <Button
                    variant='contained'
                    style={{
                      backgroundColor: 'midnightblue',
                      width: '45%',
                      color: '#fff',
                    }}
                    onClick={handleClearSearch}
                  >
                    {content.clear}
                  </Button>
                  <Button
                    variant='contained'
                    style={{
                      backgroundColor: 'midnightblue',
                      width: '45%',
                      color: '#fff',
                    }}
                    onClick={handleSearch}
                  >
                    {content.apply}
                  </Button>
                </SearchButtons>
              </SearchForm>
            </StickySearch>
          </LeftPanel>

          <RightPanel style={{ height: '100%' }}>
            {loading ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '35vh',
                }}
              >
                <img
                  style={{ width: '75px' }}
                  src={LoadingGif}
                  alt='loading animation on data load'
                />
              </div>
            ) : (
              <>
                {!campaigns.length ? (
                  <div style={{ textAlign: 'center' }}>
                    <h1 style={{ fontWeight: '400', color: '#fff' }}>
                      No Campaigns Found <br />
                      Please try Again.
                    </h1>
                  </div>
                ) : (
                  <>
                    <EndlessScroll
                      id='scrollableDiv'
                      style={{
                        height: '90vh',
                        overflow: 'auto',
                        width: '100%',
                      }}
                    >
                      <InfiniteScroll
                        dataLength={campaigns.length}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                        }}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={
                          <img
                            style={{ width: '6rem', padding: '8rem' }}
                            src={LoadingGif}
                            alt='loading animation on data load'
                          />
                        }
                        scrollableTarget='scrollableDiv'
                      >
                        {campaigns.map((campaign) => (
                          <CardComponent
                            language={props.language}
                            campaign={campaign}
                            key={campaign.id}
                            onDonated={onDonated}
                          />
                        ))}
                      </InfiniteScroll>
                    </EndlessScroll>
                  </>
                )}
              </>
            )}
          </RightPanel>
        </SearchContainer>

        {/* <div className='bottom-panel'>
            <Typography>Page: {page}</Typography>
            <Pagination
              count={campaigns.totalPages}
              page={page}
              onChange={handlePageChange}
            />
          </div> */}
      </Fragment>
    </>
  );
};

export default CampaignsPage;
