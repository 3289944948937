import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDApp, campaignAbi, multiCall, network } from '../../contexts/web3';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CreationLoading from '../shared/CreationLoading';
import { toast } from 'react-toastify';
import { usePriceApi } from '../../contexts/priceApi';
import { useLanguage } from '../../contexts/LanguageContext';

const CampaignsClaimable = ({ claimables, setClaimables }) => {
  const { web3, account } = useDApp();
  const { price } = usePriceApi();
  const [creationLoader, setCreationLoader] = useState(false);
  const { language, getLanguageContent } = useLanguage();
  const [content, setContent] = useState(
    getLanguageContent('CmpClaimableTrans')
  );

  useEffect(() => {
    setContent(getLanguageContent('CmpClaimableTrans'));
  }, [language]);

  const claim = async (claim) => {
    setCreationLoader(true);
    const c = new web3.eth.Contract(campaignAbi, claim.contractAddress);
    try {
      const receipt = await c.methods
        .claimFunds(account)
        .send({ from: account });
      console.log(receipt);
      setCreationLoader(false);
      setClaimables((prev) => prev.filter((c) => c.id !== claim.id));
      toast.success(content.claimedSuccessfully);
    } catch (e) {
      toast.error(e.message);
      setCreationLoader(false);
    }
  };

  const claimAll = async () => {
    setCreationLoader(true);
    try {
      //load the contract
      const multicall = new web3.eth.Contract(
        multiCall.abi,
        multiCall.address[network]
      );
      //make an array of call datas
      const ar = claimables.map((claim) => {
        const c = new web3.eth.Contract(campaignAbi, claim.contractAddress);
        return {
          target: claim.contractAddress,
          data: c.methods.claimFunds(account).encodeABI(),
        };
      });
      console.log(ar);
      //now send it to the aggregate function
      const receipt = await multicall.methods
        .aggregate(ar)
        .send({ from: account });
      console.log(receipt);
      setCreationLoader(false);
      setClaimables([]);
      toast.success(content.claimedAllSuccessfully);
    } catch (e) {
      toast.error(e.message);
      setCreationLoader(false);
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '15px',
        }}
      >
        <Button
          variant='contained'
          style={{ marginLeft: 'auto', background: 'midnightblue' }}
          onClick={claimAll}
        >
          CLAIM ALL
        </Button>
      </div>
      {creationLoader && <CreationLoading text={content.claimingFunds} />}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell>Thumbnail</TableCell>
              <TableCell>Campaign Title</TableCell>
              <TableCell>Claimable Amount</TableCell>
              <TableCell>Claim</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {claimables.map((claimable) => (
              <TableRow key={claimable.id}>
                <TableCell>
                  <img
                    src={claimable.image}
                    alt={claimable.title}
                    style={{
                      width: '50px',
                      height: '50px',
                      backgroundPosition: 'center',
                      overflow: 'hidden',
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Link
                    to={`/campaign/${claimable.id}`}
                    target='_blank'
                    style={{ textDecoration: 'none' }}
                  >
                    <h4>{claimable.title}</h4>
                  </Link>
                </TableCell>
                <TableCell>
                  {claimable.balance} BNB{' '}
                  <small>
                    (
                    {(claimable.balance * price).toLocaleString(undefined, {
                      currency: 'USD',
                      style: 'currency',
                    })}
                    )
                  </small>
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      claim(claimable);
                    }}
                    variant='contained'
                    style={{ background: 'darkorange' }}
                  >
                    Claim
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CampaignsClaimable;
