export default {
  English: {
    orgBanner: 'ORGANIZATION',
    state: 'State',
    remaining: 'remaining',
    goal: 'Goal Reached',
    comeSoon: 'Coming Soon',
    closed: 'Closed',
    moreInfo: 'More Info',
    campReceived: 'Campaign receives funds if goal met or not.',
    campRefund: 'Funds refunded if goal not met.',
  },
  Spanish: {
    orgBanner: 'ORGANIZACIÓN',
    state: 'Estado',
    remaining: 'que falta',
    goal: 'Meta alcanzada',
    comeSoon: 'Próximamente',
    closed: 'Cerrado',
    moreInfo: 'Más info',
    campReceived: 'La campaña recibe fondos si la meta se alcanza o no.',
    campRefund: 'Los fondos se devuelven si la meta no se alcanza.',
  },
};
