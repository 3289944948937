export default {
  English: {
    campcalc: 'Campaign Calculator',
    calcText:
      'To help you decide how much you may need to make your campaign successful, fill in the form below to calculate how many tokens you will need.',
    calcText2: 'How much do you need ',
    calcText3: 'for your campaign?',
    donate: 'Donate',
    campAmountReq: 'Campaign Amount must be $100 and above',
    taxText:
      'If you know what your taxes will be, You can set it here. Otherwise leave it at 0%',
    serviceFee: 'Service Fee',
    receiveText: 'Receive Donation if Goal Not Met:',
    creationGas: 'Creation Gas Fee',
    should: 'You Should ask for',
    or: 'or',
    apply: 'Apply',
    cancel: 'Cancel',
    atLeastOneHundred:
      'Campaign Must be at Least $100 with no Negative Numbers. ',
    notConnected:
      "You're not connected to the right network. Please connect to the bnb network.",
    posOnly: ' Positive Only ',
    tax1: 'If you know what your taxes will be',
    tax2: 'You can set it here.',
    tax3: 'Otherwise leave it at 0%',
    exitReceive: 'Receive Donation if Goal Not Met.',
    shouldAsk: 'You Should ask for',
    or: 'or',
  },
  Spanish: {
    campcalc: 'Calculadora de Campaña',
    calcText:
      'Para ayudarte a decidir cuánto necesitarás para que tu campaña sea exitosa, llena el siguiente formulario para calcular cuántos tokens necesitarás.',
    calcText2: '¿Cuánto necesitas',
    calcText3: 'para tu campaña?',
    donate: 'Donar',
    campAmountReq: 'La cantidad de la campaña debe ser de $100 o más',
    taxText:
      'Si sabes cuáles serán tus impuestos, puedes establecerlo aquí. De lo contrario, déjalo en 0%',
    serviceFee: 'Gastos de Servicio',
    receiveText: 'Recibir Donación si la meta no se alcanza:',
    creationGas: 'Gastos de Creación en Gas',
    should: 'Debes pedir',
    or: 'o',
    apply: 'Aplicar',
    cancel: 'Cancelar',
    atLeastOneHundred:
      'La campaña debe ser de al menos $100 con números no negativos. ',
    notConnected:
      'No estás conectado a la red correcta. Por favor conéctate a la red de bnb.',
    posOnly: ' Solo positivos ',
    tax1: 'Si sabes cuáles serán tus impuestos',
    tax2: 'puedes establecerlo aquí.',
    tax3: 'De lo contrario déjalo en 0%',
    exitReceive: 'Recibir Donación si la meta no se alcanza.',
    ShouldAsk: 'Debes pedir',
    or: 'o',
  },
};
