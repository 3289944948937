import styled from 'styled-components';

export const Card = styled.div`
  background: #fff;
  max-width: 300px;
  border-radius: 10px;
  margin-right: 0px;
  padding: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
`;

export const ImageHolder = styled.div`
  max-width: 300px;
  text-align: center;
`;

export const CampaignTitle = styled.h3`
  font-weight: 500;
  font-size: 1.2rem;
  color: #333;
`;

export const ShortDescription = styled.p`
  font-size: 1rem;
  color: #333;
  margin-top: 0.5rem;
  text-align: justify;
`;

export const GoalText = styled.p`
  font-size: 1rem;
  color: green;
  margin-top: 0.5rem;
  text-align: center;
`;
