import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { useDApp } from '../contexts/web3';
import shortenAddress from '../Util/ShortenAddress';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import NotificationDot from './shared/NotificationDot';
import { WalletButtonNotificationBell } from './WalletButtonStyles';
import { styled } from '@mui/system';
import BadgeUnstyled, { badgeUnstyledClasses } from '@mui/base/BadgeUnstyled';
import { useNotifications } from '../contexts/notifs';
import { useLanguage } from '../contexts/LanguageContext';

const StyledBadge = styled(BadgeUnstyled)`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-family: IBM Plex Sans, sans-serif;
  position: relative;
  display: inline-block;
  line-height: 1;

  & .${badgeUnstyledClasses.badge} {
    z-index: auto;
    min-width: 20px;
    height: 20px;
    padding: 0 6px;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    text-align: center;
    background: red;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #fff;
    position: absolute;
    top: -14px;
    right: -15px;
    transform: translate(50%, -50%);
    transform-origin: 100% 0;
  }
`;

const useStyles = makeStyles({
  tooltip: {
    fontSize: '1rem',
  },
});

const WalletButton = () => {
  const { account, connect, disconnect, wrongNetwork, autoSwitchOrAdd } =
    useDApp();
  const { unseenCount } = useNotifications();

  const [anchorElUser, setAnchorElUser] = useState(null);
  const { language, getLanguageContent } = useLanguage();
  const [content, setContent] = useState(getLanguageContent('WalletTrans'));

  useEffect(() => {
    setContent(getLanguageContent('WalletTrans'));
  }, [language]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  async function showWalletConnect() {
    await connect();
  }

  async function disconnectWallet() {
    await disconnect();
  }

  async function switchNetwork() {
    await autoSwitchOrAdd();
  }

  const classes = useStyles();

  return (
    <>
      {wrongNetwork ? (
        <Button variant='contained' onClick={switchNetwork}>
          {content.wrongNet}
        </Button>
      ) : !account ? (
        <Button
          variant='contained'
          style={{
            borderRadius: '18px',
            background: 'linear-gradient(to right, #e8953c, #9b2fb5)',
            color: 'white',
          }}
          onClick={() => showWalletConnect()}
        >
          {content.connect}
        </Button>
      ) : (
        <>
          <Box sx={{ flexGrow: 0 }}>
            {/* <Tooltip
              title={anchorElUser ? '' : 'Click to See Settings'}
              style={{ marginLeft: '5px' }}
              classes={{ tooltip: classes.tooltip }}
            > */}
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <div
                className='walletHolder'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '.3rem .3rem',
                  border: '1px solid #e8953c',
                  borderRadius: '18px',
                  paddingRight: '1rem',
                }}
              >
                <span
                  style={{
                    fontSize: '.9rem',
                    marginRight: '1.2rem',
                    marginLeft: '1.2rem',
                    color: '#333',
                  }}
                >
                  {shortenAddress(account)}
                </span>
                <Jazzicon
                  diameter={20}
                  seed={jsNumberForAddress(account)}
                  style={{ paddingRight: '1.2rem' }}
                />
              </div>

              <WalletButtonNotificationBell>
                {unseenCount ? (
                  <>
                    <StyledBadge badgeContent={unseenCount}> </StyledBadge>
                  </>
                ) : (
                  ''
                )}
              </WalletButtonNotificationBell>
            </IconButton>

            <Menu
              sx={{ mt: '45px' }}
              id='menu-appbar'
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              className='wallet-button'
            >
              <MenuItem
                onClick={handleCloseUserMenu}
                style={{
                  display: 'flex',
                  flexGrow: 1,
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <Typography textAlign='center'>
                  <Link
                    to={`/profile/`}
                    style={{
                      color: '#505050',
                      textDecoration: 'none',
                    }}
                  >
                    Profile
                  </Link>
                </Typography>

                <Typography textAlign='center'>
                  <Button
                    to=''
                    style={{
                      color: '#505050',
                      textDecoration: 'none',
                      textTransform: 'capitalize',
                    }}
                    onClick={disconnectWallet}
                  >
                    LogOut
                  </Button>
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </>
      )}
    </>
  );
};

export default WalletButton;
