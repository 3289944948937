export default {
  English: {
    extend: 'Extend Campaign',
    extendMsg:
      'There is a 1% extra service fee for extending your campaign. One extra month will be added to your campaign duration. If you agree with this, please click confirm below.',
    cancel: 'Cancel',
    confirm: 'Confirm',
    pleaseApprove: 'Please Approve Transaction to Continue...',
    extending: 'Extending Campaign...',
    extended: 'Campaign Extended!',
    errorExtending: 'Error Extending Campaign',
  },
  Spanish: {
    extend: 'Extender Campaña',
    extendMsg:
      'Hay un 1% de cargo adicional por extender la campaña. Un mes adicional será añadido a la duración de la campaña. Si acepta esto, por favor haga clic en confirmar.',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    pleaseApprove: 'Por favor, aproveche la transacción para continuar...',
    extending: 'Extendiendo campaña...',
    extended: 'Campaña extendida!',
    errorExtending: 'Error extendiendo campaña',
  },
};
