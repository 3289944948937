import styled from 'styled-components';

export const AboutSection = styled.div`
  background-color: #b0b0b0;
  padding-top: 1.5rem;
`;

export const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  @media (min-width: 400px) {
    width: 95%;
  }
`;

export const AboutTextWrapper = styled.div`
  background: midnightblue;
  padding: 1rem;
  border-radius: 8px;
  width: 50%;
  @media (max-width: 1150px) {
    width: 100%;
  }
`;

export const AboutTitle = styled.h2`
  font-weight: 400;
  font-size: 2rem;
  text-align: center;
  color: #fff;
`;

export const AboutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 1.5rem;
  align-items: center;
  @media (max-width: 1150px) {
    flex-direction: column;
  }
`;

export const AboutSubtitle = styled.h3`
  font-weight: 400;
  text-align: center;
  font-size: 1.5rem;
  font-style: italic;
  color: #fff;
`;

export const AboutText = styled.p`
  font-weight: 400;
  text-align: justify;
  color: #fff;
  font-size: 1.2rem;
  padding: 2rem;
  @media (max-width: 500px) {
    text-justify: inter-word;
  }
  @media (max-width: 300px) {
    padding: 0.5rem;
  }
`;

export const AboutImageHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2.5rem;
  @media (min-width: 1151px) {
    margin-top: 0;
  }
`;

export const AboutImage = styled.img`
  margin: 0 auto;
  text-align: center;
`;
