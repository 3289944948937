import styled from 'styled-components';

export const CampaignInput = styled.input`
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  &[type='text'] {
    width: 100%;
  }
`;

export const CampaignSelect = styled.select`
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
`;

export const SingleContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 65% auto;
  @media only screen and (max-width: 1300px) {
    grid-template-columns: 1fr;
  }
`;

export const CampaignTerminatedMessage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 2;
`;

export const CampaignTerminatedMessageText = styled.h1`
  width: 100%;
  margin: 0 auto;
  margin-top: 6.25rem;
  margin-bottom: 6.25rem;
  text-align: center;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 400;
`;

export const SingleLeft = styled.div`
  position: relative;
  margin-top: 0;
  padding-left: 4rem;
  padding-right: 4rem;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  @media only screen and (max-width: 1060px) {
    padding-bottom: 3rem;
  }
  @media (max-width: 440px) {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
  }
`;

export const EditButtonHolder = styled.div`
  position: absolute;
  top: 0;
  right: 5px;
  button {
    background: transparent;
    font-size: 0.8rem;
  }
`;

export const SingleRight = styled.div`
  background-color: #444;
  color: #fff;
  padding: 1.5rem 1.5rem 12rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 440px) {
    align-items: flex-start;
  }
`;

export const ImageHolder = styled.div`
  width: 100%;
  text-align: center;
  img {
    width: 80%;
    max-width: 400px;
    min-width: 225px;
    height: auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    box-shadow: 5px 5px 20px;
  }
  @media (max-width: 440px) {
    text-align: left;
    img {
      width: 60%;
    }
  }
`;

export const SingleTitle = styled.h1`
  text-align: left;
  font-weight: 500;
  margin-top: 2rem;
`;

export const SingleDescription = styled.div`
  text-align: justify;
`;

export const DonationContainer = styled.div`
  display: grid;
  grid-template-columns: 70px 160px;
  @media (max-width: 440px) {
    text-align: left;
  }
`;

export const BoxLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BoxRight = styled.div`
  p {
    line-height: 6px;
  }
  @media (max-width: 440px) {
    text-align: left;
    padding-right: 0.3rem;
  }
`;

export const CampaignAddressHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  margin-top: 1rem;
  position: relative;
  @media (max-width: 500px) {
    font-size: 0.8rem;
  }
  @media (max-width: 440px) {
    font-size: 0.7rem;
  }
  @media (max-width: 330px) {
    font-size: 0.6rem;
    margin-bottom: 20px;
  }
`;

export const CopyButtonHolder = styled.div`
  position: absolute;
  top: -12px;
  right: -30px;
  @media (max-width: 330px) {
    left: -40px;
    top: 15px;
    margin-bottom: 20px;
  }
`;

export const SocialDiv = styled.div`
  text-align: center;
  @media (max-width: 440px) {
    text-align: left;
  }
`;
