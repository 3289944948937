import React from 'react';
import {
  InstructionVidOverlay,
  InstructionCard,
  InstructionVidCardCloseHolder,
  VideoHolder,
} from './EditorInstructionStyles';
import CloseIcon from '@mui/icons-material/Close';

const EditorInstruction = ({ onClose }) => {
  return (
    <InstructionVidOverlay>
      <InstructionCard>
        <InstructionVidCardCloseHolder>
          <CloseIcon
            style={{
              color: '#fff',
              position: 'absolute',
              top: '-35px',
              right: '10px',
              fontSize: '2.5rem',
              cursor: 'pointer',
            }}
            onClick={onClose}
          />
        </InstructionVidCardCloseHolder>
        <VideoHolder className='video-container'>
          <iframe
            width='560'
            height='315'
            src='https://www.youtube-nocookie.com/embed/ApTNhAHuEio'
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          ></iframe>
        </VideoHolder>
      </InstructionCard>
    </InstructionVidOverlay>
  );
};

export default EditorInstruction;
