import React, { useState, useEffect } from 'react';
import {
  LegalHeaderSection,
  LegalBodySection,
  LegalContainer,
  LegalTitle,
  LegalSubTitle,
  LegalTextHeading,
  LegalText,
} from './pageStyles/LegalStyles';
import { useLanguage } from '../contexts/LanguageContext';
import { Helmet } from 'react-helmet';

const RiskDisclaimer = (props) => {
  const { language, getLanguageContent } = useLanguage();
  const [content, setContent] = useState(getLanguageContent('RiskTrans'));

  useEffect(() => {
    setContent(getLanguageContent('RiskTrans'));
  }, [language]);
  return (
    <>
      <Helmet>
        <title>I WILL FUND | {content.riskTopTitle}</title>
        <meta name='description' content={content.descMeta} />
        <meta name='theme-color' content='#191970' />
      </Helmet>
      <LegalHeaderSection>
        <LegalContainer>
          <LegalTitle>{content.riskDisclaimer}</LegalTitle>
          <LegalSubTitle>{content.aboutRisks}</LegalSubTitle>
        </LegalContainer>
      </LegalHeaderSection>
      <LegalBodySection>
        <LegalContainer>
          <LegalTextHeading>{content.riskDisclaimer}</LegalTextHeading>
          <LegalTextHeading>{content.updated}</LegalTextHeading>
          <LegalText>
            {content.considerFactors}
            <br />
            <br />
            {content.pleaseNote}
            <br />
            <br />
            {content.subject}
            <br />
            <br />
            {content.tokens}
          </LegalText>
          <LegalTextHeading>{content.priceVolatility}</LegalTextHeading>
          <LegalText>
            {content.tokenPrice}
            <br />
            <br />
            {content.asResult}
          </LegalText>
          <LegalTextHeading>{content.cryptoWallets}</LegalTextHeading>
          <LegalText>
            {content.tokensStored}
            <br />
            <br />
            {content.soleResponsible}
            <br />
            <br />
            {content.noCopies}
          </LegalText>
          <LegalTextHeading>{content.protocols}</LegalTextHeading>
          <LegalText>{content.tokensRecorded}</LegalText>
          <LegalTextHeading>{content.malfunction}</LegalTextHeading>
          <LegalText>{content.breakdown}</LegalText>
          <LegalTextHeading>{content.miningAttacks}</LegalTextHeading>
          <LegalText>{content.someProtocols}</LegalText>
          <LegalTextHeading>{content.hacking}</LegalTextHeading>
          <LegalText>
            {content.expropriation}
            <br />
            <br />
            {content.furthermore}
            <br />
            <br />
            {content.noControl}
          </LegalText>
          <LegalTextHeading>{content.laws}</LegalTextHeading>
          <LegalText>
            {content.legal}
            <br />
            <br />
            {content.difficult}
            <br />
            <br />
            {content.notResponsibleForLoss}
          </LegalText>
          <LegalTextHeading>{content.tax}</LegalTextHeading>
          <LegalText>
            {content.taxCharacter}
            <br />
            <br />
            {content.liability}
          </LegalText>
          <LegalTextHeading>{content.unanticipated}</LegalTextHeading>
          <LegalText>
            {content.additionToRisks}
            <br />
            <br />
            {content.notExhaustive}
          </LegalText>
        </LegalContainer>
      </LegalBodySection>
    </>
  );
};

export default RiskDisclaimer;
