import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';
import { useDApp } from '../contexts/web3';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  FormInput,
  FormLabel,
  SettingsForm,
  AddressHolder,
} from './pageStyles/SettingsStyles';
import { useLanguage } from '../contexts/LanguageContext';
import { Helmet } from 'react-helmet';

const Settings = (props) => {
  const { account, jwt } = useDApp();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(account);
    toast.success('Address copied to clipboard');
  };
  const [receiveEmails, setReceiveEmails] = useState(false);
  const { language, getLanguageContent } = useLanguage();
  const [content, setContent] = useState(getLanguageContent('SettingsTrans'));

  useEffect(() => {
    setContent(getLanguageContent('SettingsTrans'));
  }, [language]);

  useEffect(async () => {
    if (!account) return;
    if (!jwt) return;
    try {
      const resp = await fetch(`/api/wallet/${account}/settings`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      const data = await resp.json();
      if (data) {
        setName(data.name);
        setEmail(data.email);
        setReceiveEmails(data.receiveEmails);
      }
    } catch (err) {
      console.log(err);
    }
  }, [account, jwt]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const applySettings = async () => {
    if (!email) {
      toast.error(content.enterEmail);
      return;
    }
    try {
      const resp = await fetch(`/api/wallet/${account}/settings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({
          name,
          email,
          receiveEmails,
        }),
      });
      const data = await resp.json();
      console.log(data);
      toast.success(content.settingsApplied);
    } catch (error) {
      console.log(error);
      toast.error(content.somethingHappened);
    }
  };

  return (
    <>
      <Helmet>
        <title>I WILL FUND | {content.settingsTopTitle}</title>
        <meta name='description' content={content.descMeta} />
        <meta name='theme-color' content='#191970' />
      </Helmet>
      <Container
        component={'div'}
        style={{ marginBottom: '2rem', marginTop: '2rem' }}
      >
        {!account ? (
          ''
        ) : (
          <span style={{ marginBottom: '15px' }}>
            <Jazzicon diameter={100} seed={jsNumberForAddress(account)} />
          </span>
        )}
        <FormLabel>{content.walletAddress}</FormLabel>
        <AddressHolder>
          {account}
          <Button onClick={copyToClipboard}>
            <ContentCopyIcon style={{ color: '#888' }} />
          </Button>
        </AddressHolder>
        <SettingsForm>
          <FormLabel>{content.name}</FormLabel>
          <FormInput
            placeholder={content.namePlaceholder}
            onChange={handleNameChange}
            value={name}
          />
          <FormLabel>{content.email}</FormLabel>
          <FormInput
            placeholder={content.emailPlaceholder}
            onChange={handleEmailChange}
            value={email}
          />
          <FormGroup>
            <FormControlLabel
              style={{ marginLeft: '10px' }}
              control={<Checkbox checked={receiveEmails ?? false} />}
              onClick={() => setReceiveEmails(!receiveEmails)}
              label={
                receiveEmails ? <>{content.uncheck}</> : <>{content.receive}</>
              }
            />
          </FormGroup>
          <Button
            variant='contained'
            style={{
              color: '#fff',
              background: 'darkorange',
              marginTop: '15px',
            }}
            onClick={applySettings}
          >
            {content.save}
          </Button>
        </SettingsForm>
      </Container>
    </>
  );
};

export default Settings;
