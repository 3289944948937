import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import DialogBox from '../Dialog/DialogBox';
import { usePriceApi } from '../../contexts/priceApi';
import './CardStyles.css';
import parse from 'html-react-parser';
import { excerpt } from '../../Util/Excerpt';
import moment from 'moment';
import { FacebookIcon, LinkedinIcon, TwitterIcon } from 'react-share';
import { useLanguage } from '../../contexts/LanguageContext';

const CardComponent = ({ campaign, onDonated }) => {
  const [progressColor, setProgressColor] = useState('primary');
  const { price } = usePriceApi();
  const [disabled, setDisabled] = useState(false);
  const { language, getLanguageContent } = useLanguage();
  const [content, setContent] = useState(getLanguageContent('CardCompTrans'));

  useEffect(() => {
    setContent(getLanguageContent('CardCompTrans'));
  }, [language]);

  let url = `http://localhost:3000/campaign/${campaign.id}`;

  useEffect(() => {
    changeProgressColor(campaign?.percent);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    disableButton();
  }, []);

  const disableButton = () => {
    if (campaign.closed) {
      setDisabled(true);
    }
  };

  const changeProgressColor = (per) => {
    // console.log(per);
    if (per > 110) {
      setProgressColor('warning');
    } else if (per >= 50) {
      setProgressColor('success');
    } else {
      setProgressColor('primary');
    }
  };

  const showDate = (d) => {
    return moment(d).format('MM/DD/YYYY');
  };

  const amountLeft = () => {
    let content = {
      English: {
        remaining: 'remaining',
        goal: 'Goal Reached',
      },
      Spanish: {
        remaining: 'que falta',
        goal: 'Meta alcanzada',
      },
    };
    language === 'es'
      ? (content = content.Spanish)
      : (content = content.English);

    // const left = campaign?.tokensNeeded - campaign?.totalFunded;
    const left = campaign?.tokensLeft;
    // console.log(left, campaign?.details?.totalFunded, campaign.tokensNeeded);
    if (left > 0) {
      return (
        <>
          {left.toFixed(8)} BNB{' '}
          <small>
            {' '}
            (~
            {(left * price).toLocaleString(undefined, {
              currency: 'usd',
              style: 'currency',
            })}{' '}
            USD)
          </small>{' '}
          {content.remaining}
        </>
      );
    } else if (campaign.type === 1) {
      return <div style={{ height: '20px' }}> </div>;
    } else {
      return <>{content.goal}</>;
    }
  };

  return (
    <Card style={{ width: '90%', maxWidth: '340px', marginBottom: '15px' }}>
      <CardContent key={campaign.id} className='cardz'>
        <div>
          {campaign.comingSoon ? (
            <>
              <div style={{ position: 'relative' }}>
                <img
                  className='card-img'
                  src={campaign.image}
                  alt={campaign.title}
                />

                <span
                  style={{
                    position: 'absolute',
                    bottom: '10px',
                    left: '0',
                    right: '0',
                    color: '#fff',
                    textTransform: 'uppercase',
                    background: 'rgba(25,25,112,0.8)',
                    width: '100%',
                    textAlign: 'center',
                    padding: '5px',
                  }}
                >
                  {content.comeSoon}
                </span>
              </div>
            </>
          ) : campaign.type === 1 ? (
            <>
              <div style={{ position: 'relative' }}>
                <img
                  className='card-img'
                  src={campaign.image}
                  alt={campaign.title}
                />

                <span
                  style={{
                    position: 'absolute',
                    bottom: '10px',
                    left: '0',
                    right: '0',
                    color: '#fff',
                    textTransform: 'uppercase',
                    background: 'rgba(247,147,26,0.8)',
                    width: '100%',
                    textAlign: 'center',
                    padding: '5px',
                  }}
                >
                  {content.orgBanner}
                </span>
              </div>
            </>
          ) : (
            <>
              <img
                className='card-img'
                src={campaign.image}
                alt={campaign.title}
              />
            </>
          )}
          <div
            style={{
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant='h6'
              component='div'
              style={{
                marginTop: 0,
                marginBottom: 0,
                fontWeight: 400,
                minHeight: '4rem',
              }}
              title={campaign.title}
            >
              {parse(excerpt(campaign.title, 45, ' ', '...'))}
            </Typography>
            {campaign.type === 1 ? (
              ''
            ) : (
              <>
                <p
                  style={{
                    border: '2px solid midnightblue',
                    color: 'midnightblue',
                    padding: '5px',
                    textAlign: 'center',
                    borderRadius: '8px',
                  }}
                >
                  {content.state}: <strong>{campaign.stateSelected}</strong>
                </p>
              </>
            )}

            <div style={{ textAlign: 'center' }}>
              <div>
                <p style={{ fontSize: '1rem' }}>
                  {campaign.type === 1 ? (
                    <strong>{campaign?.totalFunded} BNB Donated</strong>
                  ) : (
                    <strong>
                      {campaign?.totalFunded} BNB / {campaign.tokensNeeded} BNB
                    </strong>
                  )}
                </p>
              </div>
              {campaign.type !== 1 ? (
                <>
                  <p style={{ fontSize: '.85rem' }}>
                    <LinearProgress
                      variant='determinate'
                      value={
                        +campaign?.percent > 100
                          ? 100
                          : isNaN(+campaign?.percent) || +campaign?.percent <= 0
                          ? 0
                          : +campaign?.percent
                      }
                      color={progressColor}
                      sx={{
                        color: `${progressColor}`,
                        height: '10px',
                        borderRadius: '8px',
                        marginTop: '.5rem',
                        transform: `${
                          campaign?.percent > 100 && 'translateX(0%)'
                        }`,
                      }}
                      max={100}
                      min={0}
                    />
                    <strong style={{ fontSize: '.8rem', marginTop: '.65rem' }}>
                      {amountLeft()}
                    </strong>{' '}
                  </p>
                </>
              ) : (
                <>
                  <div style={{ height: '18px' }}></div>
                </>
              )}
            </div>
            {campaign.type !== 1 ? (
              <>
                <p
                  style={{
                    fontSize: '1.2rem',
                    fontFamily: 'Roboto',
                    textAlign: 'justify',
                    minHeight: '100px',
                    background: '#e8e8e8',
                    borderRadius: '4px',
                    padding: '.3rem',
                    marginBottom: 0,
                  }}
                >
                  {campaign.shortDescription.length > 1 &&
                    parse(excerpt(campaign.shortDescription, 135, ' ', '...'))}
                </p>
              </>
            ) : (
              <>
                <p
                  style={{
                    fontSize: '1.2rem',
                    fontFamily: 'Roboto',
                    textAlign: 'justify',
                    minHeight: '190px',
                    background: '#e8e8e8',
                    borderRadius: '4px',
                    padding: '.3rem',
                    marginBottom: 0,
                  }}
                >
                  {campaign.shortDescription.length > 1 &&
                    parse(excerpt(campaign.shortDescription, 135, ' ', '...'))}
                </p>
              </>
            )}

            <p
              style={{ fontSize: '.85rem', textAlign: 'center', marginTop: 0 }}
            >
              <strong>
                <br />
                {campaign.type === 1 ? (
                  <>
                    <p>Campaign Start: {showDate(campaign.planStart)}</p>
                  </>
                ) : (
                  <>
                    {showDate(campaign.planStart)} to{' '}
                    {showDate(campaign.planEnd)}
                  </>
                )}
              </strong>
            </p>
            <div className='px-7'>
              <div className='w-11/12 bg-gray-200 h-5 mb-6 rounded-3xl '>
                <div
                  className='rounded-3xl bg-gradient-to-r from-secondary to-green-500 h-5'
                  style={{ width: `${campaign?.percent}%` }}
                ></div>
              </div>

              <Grid container style={{ textAlign: 'center' }}>
                {campaign.comingSoon ? (
                  <Grid item xs={12}>
                    <Link
                      to={`/campaign/${campaign.id}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <Button
                        variant='contained'
                        className='campaign-card-buttons'
                        style={{
                          color: '#fff',
                          background: '#f7931a',
                          marginLeft: '3px',
                          width: '100%',
                        }}
                      >
                        {content.moreInfo}
                      </Button>
                    </Link>
                  </Grid>
                ) : (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{ marginTop: '3px', marginBottom: '3px' }}
                    >
                      <DialogBox
                        language={language}
                        disabled={disabled ? 'disabled' : ''}
                        style={{ background: 'midnightblue' }}
                        campaign={campaign}
                        onDonated={onDonated}
                        buttonText={
                          campaign.closed ? (
                            <>{content.closed}</>
                          ) : (
                            'IWill.Fund!'
                          )
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{ marginTop: '3px', marginBottom: '3px' }}
                    >
                      <Link
                        to={`/campaign/${campaign.id}`}
                        style={{ textDecoration: 'none' }}
                      >
                        <Button
                          variant='contained'
                          className='campaign-card-buttons'
                          style={{
                            color: '#fff',
                            background: '#f7931a',
                            marginLeft: '3px',
                          }}
                        >
                          {content.moreInfo}
                        </Button>
                      </Link>
                    </Grid>
                  </>
                )}
              </Grid>

              <div>
                {campaign.type !== 1 ? (
                  <>
                    <br />
                    {campaign.receiveIfGoalNotMet ? (
                      <small
                        style={{
                          color: 'red',
                          fontStyle: 'italic',
                          fontSize: '.75rem',
                          textAlign: 'center',
                        }}
                      >
                        *{content.campReceived}
                      </small>
                    ) : (
                      <small
                        style={{
                          color: '#999',
                          fontStyle: 'italic',
                          fontSize: '.75rem',
                          textAlign: 'center',
                        }}
                      >
                        *{content.campRefund}
                      </small>
                    )}
                  </>
                ) : (
                  <>
                    <div style={{ height: '20px' }}></div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div style={{ textAlign: 'center' }}>
          <hr />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <FacebookIcon url={url} round={true} size={42} />
            <LinkedinIcon url={url} round={true} size={42} />
            <TwitterIcon url={url} round={true} size={42} />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default CardComponent;
