import styled from 'styled-components';

export const ProfileCard = styled.div`
  border-radius: 8px;
  min-width: 50%;
  background-color: #333;
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 2rem;
  color: #fff;
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 4rem;
  @media (min-width: 815px) {
    height: 525px;
  }
  @media (min-width: 937px) {
    height: 475px;
  }
  @media (min-width: 1105px) {
    height: 450px;
  }
  @media (min-width: 1151px) {
    height: 625px;
  }
  @media (min-width: 1192px) {
    height: 600px;
  }
  @media (min-width: 1374px) {
    height: 550px;
  }
  @media (min-width: 1481px) {
    height: 525px;
  }
  @media (min-width: 1750px) {
    height: 500px;
  }
  @media (min-width: 1868px) {
    height: 475px;
  }
`;

export const ProfileCardsSingle = styled.div`
  border-radius: 8px;
  min-width: 100%;
  background-color: #333;
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 2rem;
  color: #fff;
  position: relative;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 2rem;
`;

export const ProfileTitle = styled.h2`
  font-size: 1.75rem;
  font-weight: 400;
  text-align: right;
  color: #fff;
  margin-top: 1rem;
`;

export const ProfileText = styled.p`
  font-size: 1.2rem;
  text-align: justify;
  text-justify: inter-word;
  color: #fff;
`;

export const ProfileImageHolder = styled.div`
  position: absolute;
  top: -2rem;
  left: -0.5rem;
`;

export const ProfileImage = styled.img`
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  width: 30%;
`;

export const ProfileSocialHolders = styled.div`
  text-align: center;
`;

export const ProfileSocialLink = styled.a`
  color: #fff;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;
