export default {
  English: {
    campcalc: 'Campaign Calculator',
    calcText:
      'To help you decide how much you may need to make your campaign successful, fill in the form below to calculate how many tokens you will need.',
    calcText2: 'How much do you need for your campaign?',
    donate: 'Donate',
    campAmountReq: 'Campaign Amount must be $100 and above',
    taxText:
      'If you know what your taxes will be, You can set it here. Otherwise leave it at 0%',
    serviceFee: 'Service Fee',
    receiveText: 'Receive Donation if Goal Not Met:',
    creationGas: 'Creation Gas Fee',
    should: 'You Should ask for',
    or: 'or',
    apply: 'Apply',
    cancel: 'Cancel',
  },
  Spanish: {
    campcalc: 'Calculadora de Campaña',
    calcText:
      'Para ayudarte a decidir cuánto necesitarás para que tu campaña sea exitosa, llena el siguiente formulario para calcular cuántos tokens necesitarás.',
    calcText2: '¿Cuánto necesitas para tu campaña?',
    donate: 'Donar',
    campAmountReq: 'La cantidad de la campaña debe ser de $100 o más',
    taxText:
      'Si sabes cuáles serán tus impuestos, puedes establecerlo aquí. De lo contrario, déjalo en 0%',
    serviceFee: 'Gastos de Servicio',
    receiveText: 'Recibir Donación si la meta no se alcanza:',
    creationGas: 'Gastos de Creación en Gas',
    should: 'Debes pedir',
    or: 'o',
    apply: 'Aplicar',
    cancel: 'Cancelar',
  },
};
