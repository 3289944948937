export default {
  English: {
    termsTopTitle: 'TERMS & CONDITIONS',
    descMeta: 'Terms & Conditions Page',
    terms: 'Terms of Service',
    legalDef: ' Legal definitions, conditions and terms.',
    termsUse: 'Terms of Use',
    pleaseRead:
      'PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITE',
    who: 'Who we are and how to contact us',
    iWillFund: 'IWill.Fund',
    isOperated: 'is a site operated by ChainBlock Labs',
    llc: ' LLC, a limited company registered in Georgia (US) herein referred to as IWill.Fund',
    contact: 'To contact us, please email contact@iwill.fund',
    usingSite:
      'By using our site, you confirm that you have read and acknowledged the',
    risk: 'Risk Disclaimer',
    andAccept:
      'and that you accept these terms of use and that you agree to comply with them.',
    notAgree: 'If you do not agree to these terms, you must not use our site.',
    weRecommend:
      ' We recommend that you print a copy of these terms for future reference.',
    difi: 'Decentralized',
    although: 'Although IWill.Fund',
    isDifi: ' is Decentralized,',
    rights:
      'reserves the right to terminate any campaign that promotes or encourages illegal activity, terrorism, hate, or violence of any kind.',
    otherTerms: 'There are other terms that may apply to you',
    additionalTerms:
      'These terms of use refer to the following additional terms, which also apply to your use of our site:',
    privacyCookies: 'Our Privacy and Cookies Policy, Click here.',
    furthermore:
      'FURTHERMORE, PLEASE NOTE YOUR ENTRY INTO THE EULA (AND USE OF OUR SERVICES) IS CONDITIONAL ON YOUR ENTRY INTO IWILL.FUND',
    userAgree: 's USER AGREEMENT AND PRIVACY POLICY',
    wideByLaw: 'TO THE WIDEST EXTENT PERMITTED BY LAW, IWILL.FUND',
    shallNot:
      'SHALL NOT BE A PARTY TO NOR RESPONSIBLE FOR ANY MATTER ARISING OUT OF OR IN CONNECTION WITH YOUR ENTERING INTO AN AGREEMENT WITH SMART CONTRACT METHOD CALLS, INCLUDING ANY TRANSACTION MADE PURSUANT TO THE AGREEMENT BETWEEN YOU AND IWILL.FUND',
    mayMakeChanges: ' We may make changes to these terms',
    weAmend:
      'We amend these terms from time to time. Every time you wish to use our site, please check these terms to ensure you understand the terms that apply at that time.',
    moreChanges: 'We may make changes to our site',
    weUpdate:
      "We may update and change our site from time to time to reflect changes to our services, our users' needs and our business priorities.",
    weSuspend: 'We may suspend or withdraw our site',
    siteAvailable:
      'Our site is made available free of charge but does employ service fees for campaign funds claimed.',
    noGuarantee:
      'We do not guarantee that our site, or any content on it, will always be available or be uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of our site for business and operational reasons. We will try to give you reasonable notice of any suspension or withdrawal.',
    youAreResponsible:
      'You are also responsible for ensuring that all persons who access our site through your internet connection are aware of these terms of use and other applicable terms and conditions, and that they comply with them.',
    moreResponsible:
      ' It is your responsibility to determine whether your access and use of our site is in line with all legal and regulatory obligations that are applicable to you. Our site is directed to people residing in United States and we do not represent that content available on or through our site is appropriate for use or available in your particular location.',
    weTransfer: 'We may transfer this agreement to someone else',
    transferRights:
      'We may transfer our rights and obligations under these terms to another organization. We will always tell you in writing if this happens and we will ensure that the transfer will not affect your rights under the contract.',
    useMaterial: 'How you may use material on our site',
    youAreOwner:
      'We are the owner or the licensee of all intellectual property rights in our site, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.',
    print:
      'You may print off one copy, and may download extracts, of any page(s) from our site for your personal use and you may draw the attention of others within your organization to content posted on   our site.',
    noModify:
      'You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.',
    ourStatus:
      'Our status (and that of any identified contributors) as the authors of content on our site must always be acknowledged.',
    noUse:
      'You must not use any part of the content on our site for commercial purposes without obtaining a license or written agreement to do so from us or our licensors.',
    ifYouPrint:
      'If you print off, copy or download any part of our site in breach of these terms of use, your right to use our site will cease immediately and you must, at our option, return or destroy any    copies of the materials you have made.',
    doNotRely: 'Do not rely on information on this site',
    weTryUpdate:
      'Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that the content on our site is accurate, complete or up to date.',
    notResponsibleWebsites: 'We are not responsible for websites we link to',
    sitesContainLinks:
      'Where our site contains links to other sites, services and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them.',
    noControl:
      'We have no control over the contents of those sites, quality of services, or resources.',
    customerOrBusiness: 'Whether you are a consumer or a business user:',
    weDoNotExclude:
      'We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.',
    differentLimitations:
      'Different limitations and exclusions of liability will apply to liability arising as a result of the supply of any services to you, which will be set out in the specific terms governing your use of any such services.',
    ifBusinessUser: 'If you are a business user',
    weExcludeImplied:
      'We exclude all implied conditions, warranties, representations or other terms that may apply to our site or any content on it.',
    willNotBeLiable:
      'We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:',
    inability: 'use of, or inability to use, our site; or',
    reliance: 'use of or reliance on any content displayed on our site.',
    inParticular: 'In particular, we will not be liable for:',
    loss: 'loss of profits, sales, business, or revenue;',
    interruption: 'business interruption;',
    lossSavings: 'loss of anticipated savings;',
    lossOpportunity: 'loss of business opportunity, goodwill or reputation; or',
    damage: 'any indirect or consequential loss or damage.',
    ifConsumer: 'If you are a consumer user',
    pleaseNote:
      'Please note that we only provide our site for domestic and private use. You agree not to use our site for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business',
    personal: 'How we may use your personal information',
    weWillOnlyUse:
      'We will only use your personal information as set out in our Privacy policy.',
    weAreNotResponsible:
      'We are not responsible for viruses and you must not introduce them.',
    weDoNotGuarantee:
      'We do not guarantee that our site will be secure or free from bugs or viruses.',
    configuring:
      'You are responsible for configuring your information technology, computer programs and platform to access our site. You should use your own virus protection software.',
    misuse:
      'You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. You must not attempt to gain unauthorized   access to our site, the server on which our site is stored or any server, computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the relevant US law. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our site will cease immediately.',
    rules: 'Rules about linking to our site',
    youMayLink:
      'You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.',
    mustNotEstablish:
      'You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.',
    siteNotOwnedByYou:
      'You must not establish a link to our site in any website that is not owned by you.',
    doNotFrame:
      'Our site must not be framed on any other site, nor may you create a link to any part of our site other than the home page.',
    whichCountry: "Which country's laws apply to any disputes?",
    ifConsumerPleaseNote:
      'If you are a consumer, please note that these terms of use, their subject matter and their formation, are governed by law of Georgia, (US). You and we both agree that the courts of Georgia (US) will have exclusive jurisdiction.',
    govByLawOfState:
      'If you are a business, these terms of use, their subject matter and their formation (and any non-contractual disputes or claims) are governed by the law of Georgia. We both agree to the exclusive jurisdiction of the courts of Georgia (US).',
    ourTradeMarks: 'Our trade marks',
    youAreNotPermitted:
      'You are not permitted to use our trademarks without our approval.',
  },
  Spanish: {
    termsTopTitle: 'TÉRMINOS Y CONDICIONES',
    descMeta: 'Términos y condiciones de la página web',
    terms: 'Términos de Servicio',
    legalDef: 'Definiciones legales, condiciones y términos.',
    termsUse: 'Términos de Uso',
    pleaseRead:
      'LEA ESTOS TÉRMINOS Y CONDICIONES CUIDADOSAMENTE ANTES DE UTILIZAR ESTE SITIO',
    who: 'Quiénes somos y cómo contactarnos',
    iWillFund: 'IWill.Fund',
    isOperated: 'es un sitio operado por ChainBlock Labs',
    llc: ' LLC, una sociedad limitada registrada en Georgia (EE.UU) aquí referida como IWill.Fund',
    contact:
      'Para Contactarnos, por favor envíe un correo electrónico a contact@iwill.fund',
    usingSite:
      'Utilizando este sitio, usted acepta los términos de uso y condiciones de este sitio y estas confomre con el',
    risk: 'Descargo de responsabilidad de riesgo',
    andAccept:
      'y acepta que usted es responsable de todos los riesgos que puedan surgir al utilizar este sitio.',
    notAgree:
      'Si usted no está de acuerdo con estos términos y condiciones, por favor no utilice este sitio.',
    weRecommend:
      'Le recomendamos que lea estos términos y condiciones antes de utilizar este sitio.',
    difi: 'Descentralizado',
    although: 'Aunque IWill.Fund',
    isDifi: 'es una empresa que opera en una dirección de descentralización,',
    rights:
      'temenos el derecho de terminar cualquier campaña que promueva odio, violencia, terrorismo o cualquier cosa contra la ley.',
    otherTerms: 'Hay otros terminos que se aplican a usted.',
    additionalTerms:
      'Estos términos de uso se refieren a los siguientes términos adicionales, que también se aplican a su uso de nuestro sitio:',
    privacyCookies: 'Nuestra Política de Privacidad y Cookies, Haga clic aquí.',
    furthermore:
      'ADEMÁS, TENGA EN CUENTA QUE SU ENTRADA EN EL EULA (Y EL USO DE NUESTROS SERVICIOS) ESTÁ CONDICIONAL A SU ENTRADA EN IWILL.FUND',
    userAgree: 'ACUERDO DE USUARIO Y POLÍTICA DE PRIVACIDAD',
    wideByLaw: 'EN LA MEDIDA MÁXIMA PERMITIDA POR LA LEY, IWILL.FOND',
    shallNot:
      'SHALL NOT BE A PARTY TO NOR RESPONSIBLE FOR ANY MATTER ARISING OUT OF OR IN CONNECTION WITH YOUR ENTERING INTO AN AGREEMENT WITH SMART CONTRACT METHOD CALLS, INCLUDING ANY TRANSACTION MADE PURSUANT TO THE AGREEMENT BETWEEN YOU AND IWILL.FUND',
    makeMakeChanges: 'Podemos hacer cambios a estos términos',
    weAmend:
      'Modificamos estos términos de vez en cuando. Cada vez que desee utilizar nuestro sitio, consulte estos términos para asegurarse de que comprende los términos que se aplican en ese momento.',
    moreChanges: 'Podemos hacer cambios en nuestro sitio',
    weUpdate:
      'Podemos actualizar y cambiar nuestro sitio de vez en cuando para reflejar los cambios en nuestros servicios, las necesidades de nuestros usuarios y nuestras prioridades comerciales',
    weSuspend: 'Podemos suspender o retirar nuestro sitio',
    siteAvailable:
      'Nuestro sitio está disponible de forma gratuita, pero emplea tarifas de servicio para los fondos de campaña reclamados.',
    noGuarantee:
      'No garantizamos que nuestro sitio, o cualquier contenido en él, estará siempre disponible o sin interrupciones. Podemos suspender, retirar o restringir la disponibilidad de todo o parte de nuestro sitio por motivos comerciales y operativos. Trataremos de darle un aviso razonable de cualquier suspensión o retiro.',
    youAreResponsible:
      'También es responsable de garantizar que todas las personas que accedan a nuestro sitio a través de su conexión a Internet conozcan estos términos de uso y otros términos y condiciones aplicables, y que los cumplan.',
    moreResponsible:
      'Es su responsabilidad determinar si su acceso y uso de nuestro sitio está en línea con todas las obligaciones legales y reglamentarias que le son aplicables. Nuestro sitio está dirigido a personas que residen en los Estados Unidos y no declaramos que el contenido disponible en o a través de nuestro sitio sea apropiado para su uso o esté disponible en su ubicación particular.',
    weTransfer: 'Podemos transferir este acuerdo a otra persona',
    transferRights:
      'Podemos transferir nuestros derechos y obligaciones bajo estos términos a otra organización. Siempre le informaremos por escrito si esto sucede y nos aseguraremos de que la transferencia no afecte sus derechos en virtud del contrato.',
    useMaterial: 'Cómo puede usar el material en nuestro sitio',
    youAreOwner:
      'Somos propietarios o licenciatarios de todos los derechos de propiedad intelectual de nuestro sitio y del material publicado en él. Esas obras están protegidas por leyes y tratados de derechos de autor en todo el mundo. Todos los derechos están reservados.',
    print:
      'Puede imprimir una copia y puede descargar extractos de cualquier página de nuestro sitio para su uso personal y puede llamar la atención de otras personas dentro de su organización sobre el contenido publicado en nuestro sitio.',
    noModify:
      'No debe modificar las copias en papel o digitales de ningún material que haya impreso o descargado de ninguna manera, y no debe usar ilustraciones, fotografías, secuencias de video o audio o gráficos por separado del texto que lo acompaña.',
    ourStatus:
      'Nuestro estado (y el de cualquier contribuyente identificado) como autores del contenido de nuestro sitio siempre debe ser reconocido.',
    noUse:
      'No debe utilizar ninguna parte del contenido de nuestro sitio con fines comerciales sin obtener una licencia o un acuerdo por escrito para hacerlo de parte nuestra o de nuestros licenciantes.',
    ifYouPrint:
      'Si imprime, copia o descarga cualquier parte de nuestro sitio en incumplimiento de estos términos de uso, su derecho a usar nuestro sitio cesará de inmediato y deberá, a nuestro criterio, devolver o destruir cualquier copia de los materiales que haya realizado.',
    doNotRely: 'No confiar en la información de este sitio',
    weTryUpdate:
      'Aunque hacemos esfuerzos razonables para actualizar la información en nuestro sitio, no hacemos representaciones ni garantías, ya sean expresas o implícitas, de que el contenido de nuestro sitio sea preciso, completo o actualizado',
    notResponsibleWebsites:
      'No somos responsables de los sitios web a los que enlazamos',
    siteContainsLinks:
      'Cuando nuestro sitio contiene enlaces a otros sitios, servicios y recursos proporcionados por terceros, estos enlaces se proporcionan únicamente para su información. Dichos enlaces no deben interpretarse como nuestra aprobación de esos sitios web vinculados o la información que puede obtener de ellos.',
    noControl:
      'No tenemos control sobre el contenido de esos sitios, la calidad de los servicios o los recursos.',
    customerOrBusiness: 'Ya sea que sea un consumidor o un usuario comercial:',
    weDoNotExclude:
      'No excluimos ni limitamos de ninguna manera nuestra responsabilidad hacia usted donde sería ilegal hacerlo. Esto incluye la responsabilidad por muerte o lesiones personales causadas por nuestra negligencia o la negligencia de nuestros empleados, agentes o subcontratistas y por fraude o tergiversación fraudulenta.',
    differentLimitations:
      'Se aplicarán diferentes limitaciones y exclusiones de responsabilidad a la responsabilidad que surja como resultado del suministro de cualquier servicio, que se establecerán en los términos específicos que rigen el uso de dichos servicios.',
    ifBusinessUser: 'Si es un usuario empresarial',
    weExcludeImplied:
      'Excluimos todas las condiciones implícitas, garantías, representaciones u otros términos que puedan aplicarse a nuestro sitio o cualquier contenido en él.',
    willNotBeLiable:
      'No seremos responsables ante usted por ninguna pérdida o daño, ya sea por contrato, agravio (incluida la negligencia), incumplimiento del deber legal o de otro modo, incluso si es previsible, que surja de o en relación con:',
    inability: 'uso de, o incapacidad para usar, nuestro sitio; o',
    reliance:
      'uso o confianza en cualquier contenido que se muestra en nuestro sitio',
    inParticular: 'En particular, no seremos responsables de:',
    loss: 'pérdida de ganancias, ventas, negocios o ingresos',
    interruption: 'interrupción del negocio;',
    lossSavings: 'pérdida de ahorros anticipados',
    lossOpportunity:
      'pérdida de oportunidad comercial, buena voluntad o reputación; o',
    damage: 'cualquier pérdida o daño indirecto o consecuente.',
    ifConsumer: 'Si eres un usuario consumidor',
    pleaseNote:
      'Tenga en cuenta que solo proporcionamos nuestro sitio para uso doméstico y privado. Usted acepta no utilizar nuestro sitio para ningún propósito comercial o comercial, y no tenemos ninguna responsabilidad ante usted por cualquier pérdida de ganancias, pérdida de negocios, interrupción de negocios o pérdida de negocios',
    personal: 'Cómo podemos usar su información personal',
    weWillOnlyUse:
      'Solo utilizaremos su información personal como se establece en nuestra Política de privacidad.',
    weAreNotResponsible:
      'No somos responsables de los virus y no debes introducirlos.',
    weDoNotGuarantee:
      'No garantizamos que nuestro sitio sea seguro o esté libre de errores o virus.',
    configuring:
      'Usted es responsable de configurar su tecnología de la información, los programas informáticos y la plataforma para acceder a nuestro sitio. Debe utilizar su propio software de protección antivirus.',
    misuse:
      'No debe hacer un mal uso de nuestro sitio introduciendo a sabiendas virus, troyanos, gusanos, bombas lógicas u otro material que sea malicioso o tecnológicamente dañino. No debe intentar obtener acceso no autorizado a nuestro sitio, el servidor en el que está almacenado nuestro sitio o cualquier servidor, computadora o base de datos conectada a nuestro sitio. No debe atacar nuestro sitio mediante un ataque de denegación de servicio o un ataque de denegación de servicio distribuido. Al infringir esta disposición, estaría cometiendo un delito penal según la legislación estadounidense pertinente. Informaremos de cualquier incumplimiento de este tipo a las autoridades competentes encargadas de hacer cumplir la ley y cooperaremos con esas autoridades al revelarles su identidad. En caso de tal incumplimiento, su derecho a utilizar nuestro sitio cesará inmediatamente.',
    rules: 'Reglas sobre la vinculación a nuestro sitio',
    youMayLink:
      'Puede vincular a nuestra página de inicio, siempre que lo haga de una manera justa y legal y que no dañe nuestra reputación ni se aproveche de ella.',
    mustNotEstablish:
      'No debe establecer un enlace de tal manera que sugiera cualquier forma de asociación, aprobación o respaldo de nuestra parte donde no exista.',
    siteNotOwnedByYou:
      'No debe establecer un enlace a nuestro sitio en ningún sitio web que no sea de su propiedad.',
    doNotFrame:
      'Nuestro sitio no debe estar enmarcado en ningún otro sitio, ni puede crear un enlace a ninguna parte de nuestro sitio que no sea la página de inicio.',
    whichCountry: '¿Las leyes de qué país se aplican a cualquier disputa?',
    ifConsumerPleaseNote:
      'Si usted es un consumidor, tenga en cuenta que estos términos de uso, su contenido y su formación se rigen por la ley de Georgia (EE. UU.). Usted y nosotros acordamos que los tribunales de Georgia (EE. UU.) tendrán jurisdicción exclusiva.',
    govByLawOfState:
      'Si usted es una empresa, estos términos de uso, su contenido y su formación (y cualquier disputa o reclamo no contractual) se rigen por la ley de Georgia. Ambos aceptamos la jurisdicción exclusiva de los tribunales de Georgia (EE. UU.).',
    ourTradeMarks: 'Nuestros marcas comerciales',
    youAreNotPermitted:
      'No tiene permitido usar nuestras marcas comerciales sin nuestra aprobación.',
  },
};
