export default {
  English: {
    closeEarly: 'Close Early',
    ccEarly: 'Close Campaign Early',
    closeEarlyText:
      'There is a 1% extra service fee for closing a campaign early. If you wish to proceed, please click confirm below. Once you approve this transaction, the campaign will be listed in the claimables tab.',
    cancel: 'Cancel',
    confirm: 'Confirm',
    pleaseApprove: 'Please Approve Transaction to Continue...',
    closingEarly: 'Closing Early...',
    closedEarly: 'Campaña Closed Early!',
    errorClosingEarly: 'Error Closing Early',
  },
  Spanish: {
    closeEarly: 'Cerrar Antes',
    ccEarly: 'Cerrar Campaña Antes',
    closeEarlyText:
      'Hay un 1% de cargo adicional por cerrar una campaña antes. Si desea proceder, por favor haga clic en confirmar a continuación. Una vez que apruebe esta transacción, la campaña se mostrará en la pestaña reclamables.',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    pleaseApprove: 'Por favor, aproveche la transacción para continuar...',
    closingEarly: 'Cerrando temprano...',
    closedEarly: 'Campaña cerrada temprano!',
    errorClosingEarly: 'Error cerrando temprano',
  },
};
