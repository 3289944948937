module.exports = {
  bsc: {
    wss: {
      testnet: 'wss://crimson-purple-wildflower.bsc-testnet.discover.quiknode.pro/0a72bbf19b1572ede064556ee0073ef67af631ce/',
      // testnet: 'wss://bsc.getblock.io/testnet/?api_key=ea14e96a-e780-4d5a-be1c-50035edc45f2',
      mainnet: '',
    },
    https: {
      testnet: 'https://crimson-purple-wildflower.bsc-testnet.discover.quiknode.pro/0a72bbf19b1572ede064556ee0073ef67af631ce/',
      // testnet: 'https://bsc.getblock.io/testnet/?api_key=ea14e96a-e780-4d5a-be1c-50035edc45f2',
      mainnet: '',
    }
  }
};