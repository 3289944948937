import React, { useState, useEffect } from 'react';
import { useDApp } from '../../../contexts/web3';
import { Overlay, OverlayMessage } from './OverlayStyles';
import { useLanguage } from '../../../contexts/LanguageContext';

const OverlayScreen = () => {
  const { walletState } = useDApp();
  const { language, getLanguageContent } = useLanguage();
  const [content, setContent] = useState(getLanguageContent('OverlayTrans'));

  useEffect(() => {
    setContent(getLanguageContent('OverlayTrans'));
  }, [language]);

  return (
    <>
      {walletState === 'signing' ? (
        <Overlay>
          <OverlayMessage>{content.signIn}</OverlayMessage>
        </Overlay>
      ) : (
        ''
      )}
    </>
  );
};

export default OverlayScreen;
