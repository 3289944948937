export default {
  English: {
    claimedSuccessfully: 'Claimed Successfully',
    claimedAllSuccessfully: 'Claimed all successfully',
    claimingFunds: 'Claiming Funds',
  },
  Spanish: {
    claimedSuccessfully: 'Recompensa Recibida exitosamente',
    claimedAllSuccessfully: 'Recompensade todas recibidas exitosamente',
    claimingFunds: 'Recuperando fondos',
  },
};
