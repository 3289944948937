import styled from 'styled-components';

export const MobileMenu = styled.div`
  display: none;
  @media (max-width: 1200px) {
    display: flex;
  }
`;

export const DesktopMenu = styled.div`
  z-index: 50;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin-left: 15px;
  margin-right: 15px;
  @media (max-width: 1200px) {
    display: none;
  }
`;
