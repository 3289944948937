import styled from 'styled-components';

export const FaqContainer = styled.div`
  padding: 1.5rem 1.5rem 4rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #333;
`;

export const FaqHeader = styled.h2`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
`;
