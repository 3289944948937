export default {
  English: {
    settingsTopTitle: 'SETTINGS',
    descMeta: 'Settings Page',
    walletAddress: 'Wallet Address',
    name: 'Name',
    namePlaceholder: 'Please Enter a Name',
    email: 'Email Address',
    emailPlaceholder: 'Please Enter your Email Address',
    uncheck: ' Uncheck to Not Receive Emails',
    recieve: 'Receive Emails',
    save: 'SAVE CHANGES',
    enterEmail: 'Please enter email',
    somethingHappened:
      'Something happened when saving to server. Try again soon.',
    settingsApplied: 'Settings applied',
  },
  Spanish: {
    settingsTopTitle: 'CONFIGURACIÓN',
    descMeta: 'Página de configuración',
    walletAddress: 'Dirección de la Cartera',
    name: 'Nombre',
    namePlaceholder: 'Por favor ingrese un Nombre',
    email: 'Dirección de Correo Electrónico',
    emailPlaceholder: 'Ingrese su Dirección de Correo Electrónico',
    uncheck: ' Desmarque para No Recibir Correos Electrónicos',
    recieve: ' Recibir Correos Electrónicos',
    save: 'GUARDAR CAMBIOS',
    enterEmail: 'Por favor ingrese un correo electrónico',
    somethingHappened:
      'Algo salió mal al guardar en el servidor. Inténtelo de nuevo pronto.',
    settingsApplied: 'Configuración aplicada',
  },
};
