import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {
  SingleContainer,
  SingleLeft,
  SingleRight,
  ImageHolder,
  SingleTitle,
  SingleDescription,
  DonationContainer,
  BoxLeft,
  BoxRight,
  CampaignAddressHolder,
  CopyButtonHolder,
  EditButtonHolder,
  CampaignTerminatedMessage,
  CampaignTerminatedMessageText,
  SocialDiv,
} from './pageStyles/CampaignsPageStyles';
import styled from 'styled-components';
import DialogBox from '../components/Dialog/DialogBox';
import {
  useDApp,
  getCampaignDetails,
  campaignAbi,
  explorer,
  network,
} from '../contexts/web3';
import moment from 'moment';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import shortenAddress from '../Util/ShortenAddress';
import { EDITOR_JS_TOOLS } from '../Util/tools';
import { toast } from 'react-toastify';
import ReactEditorJS from '../components/EditorJs/EditorJs';
import Box from '@material-ui/core/Box';
import CampaignButtonTerminateDialog from '../components/CampaignButtonDialog/CampaignButtonTerminateDialog';
import CampaignButtonExtendDialog from '../components/CampaignButtonDialog/CampaignButtonExtendDialog';
import CampaignButtonCloseEarlyDialog from '../components/CampaignButtonDialog/CampaignButtonCloseEarlyDialog';
import EditIcon from '@mui/icons-material/Edit';
import ScrollToTop from '../Util/ScrollToTop';
import { FacebookIcon, LinkedinIcon, TwitterIcon } from 'react-share';
import { Helmet } from 'react-helmet';
import 'moment/locale/es';
import { useLanguage } from '../contexts/LanguageContext';
import CampaignModel from '../Util/Classes/CampaignModel';

const ProgressWrapper = styled.div`
  background-color: #d8d8d8;
  border-radius: 20px;
  position: relative;
  margin: 15px 0;
  height: 30px;
  width: 300px;
  @media (max-width: 440px) {
    width: 250px;
  }
`;

const ProgressDone = styled.div`
  box-shadow: 0 3px 3px -5px midnightblue, 0 2px 5px midnightblue;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
`;

const Campaign = (props) => {
  const { id } = useParams();
  const [campaign, setCampaign] = useState({});
  const [progressColor, setProgressColor] = useState('');
  const { web3R, account, isAdmin } = useDApp();
  const [donators, setDonators] = useState([]);
  const [percent, setPercent] = useState(0);
  const [disable, setDisable] = useState(false);
  const [cursorOff, setCursorOff] = useState('auto');
  const [disableColor, setDisableColor] = useState('#191970!important');
  const [description, setDescription] = useState(null);
  const editorCore = useRef(null);
  const { language, getLanguageContent } = useLanguage();
  const [content, setContent] = useState(getLanguageContent('CampaignTrans'));

  useEffect(() => {
    setContent(getLanguageContent('CampaignTrans'));
  }, [language]);

  let navigate = useNavigate();

  const handleInitialize = useCallback((instance) => {
    console.log(instance);
    editorCore.current = instance;
  }, []);

  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(campaign?.contractAddress ?? fakeAddress);
    toast.success(content.copied);
  };

  useEffect(() => {
    disableButton();
  }, []);

  const disableButton = () => {
    if (moment() > moment(campaign.planEnd)) {
      setDisable(true);
      setCursorOff('none');
      setDisableColor('#eee!important');
    }
  };

  useEffect(() => {
    const body = document.querySelector('#root');
    body.scrollIntoView(
      {
        behavior: 'smooth',
      },
      500
    );
  }, []);

  useEffect(() => {
    if (!id) return null;
    getCampaign();
  }, [id]);

  useEffect(async () => {
    // if (!campaign && !listener) return;
    if (
      !campaign.contractAddress ||
      campaign.contractAddress === '0x0000000000000000000000000000000000000000'
    )
      return;

    const c = new web3R.eth.Contract(campaignAbi, campaign.contractAddress);
    const curBlock = await web3R.eth.getBlockNumber();
    //attach listener to campaign
    const list = c.events.FundingReceived(
      {
        // fromBlock: 0,
        fromBlock: 'latest',
      },
      async (error, data) => {
        // console.log(error, data);
        if (!error) {
          //get block info
          const block = await web3R.eth.getBlock(data.blockNumber);

          const tmp = {
            jazzicon: jsNumberForAddress(data.returnValues._donator),
            address: shortenAddress(data.returnValues._donator),

            amount:
              +web3R.utils.fromWei(data.returnValues._amount) +
              +web3R.utils.fromWei(data.returnValues._fee || '0'),
            timestamp: block.timestamp,
          };
          // console.log(tmp);
          // setDonators((prev) => [...prev, tmp]);
          //set donators and sort the concatenated array by timestamp
          setDonators((prev) =>
            [...prev, tmp].sort((a, b) => b.timestamp - a.timestamp)
          );

          if (data.blockNumber > curBlock) {
            let ncamp = campaign.dbInfo;
            await getCampaignDetails(web3R, ncamp);
            ncamp = new CampaignModel(ncamp);
            setCampaign(ncamp);
            //set percent
            if (ncamp.totalFunded > 0) {
              setPercent(
                +((ncamp.totalFunded / ncamp.tokensNeeded) * 100).toFixed(2)
              );
            }
          }
        } else {
          console.log(error);
        }
      }
    );

    return () => {
      //unlisten to it
      // console.log(list);
      if (list?.unsubscribe) {
        console.log('unsubscribe to campaign');
        list.unsubscribe();
      }
    };
  }, [campaign.contractAddress]);

  const getCampaign = async () => {
    try {
      setDonators([]);
      const response = await fetch(`/api/campaign/${id}`);
      const camp = await response.json();
      setDescription(camp.description);
      //fetch the contract details
      await getCampaignDetails(web3R, camp);
      const cm = new CampaignModel(camp);
      // console.log(camp);
      // setCampaign(camp);
      setCampaign(cm);
      if (cm.totalFunded > 0) {
        setPercent(+((cm.totalFunded / cm.tokensNeeded) * 100).toFixed(2));
      }
      const ar = [];
      for (const donator of camp.donations) {
        const tmp = {
          jazzicon: jsNumberForAddress(donator.wallet),
          address: shortenAddress(donator.wallet),
          amount: donator.amount,
          timestamp: donator.timestamp,
        };
        ar.push(tmp);
      }

      // console.log(tmp);
      // setDonators((prev) => [...prev, tmp]);
      //set donators and sort the concatenated array by timestamp
      setDonators((prev) =>
        [...prev, ...ar].sort((a, b) => b.timestamp - a.timestamp)
      );
      console.log(cm, cm.status());
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    changeProgressColor();
  }, [percent]);

  const desc = campaign.description;

  const changeProgressColor = () => {
    console.log(percent);
    if (percent > 110) {
      setProgressColor('darkorange');
    } else if (percent >= 50) {
      setProgressColor('green');
    } else {
      setProgressColor('midnightblue');
    }
  };

  const fakeAddress = '0x1111111111111111111111111111111111111111';

  const uploadBefore = () => {
    //disable button
    // setLoading(true);
  };

  const uploadAfter = () => {
    // setLoading(false);
  };

  const uploadError = (error) => {
    toast.error(error.message);
  };

  const tools = EDITOR_JS_TOOLS(uploadBefore, uploadAfter, uploadError);

  const handleEdit = (e) => {
    e.preventDefault();
    let path = `/edit-campaign/${id}/`;
    navigate(path);
  };

  const onSuccess = () => {
    getCampaign();
  };

  const showEarlyButton = () => {
    return (
      account &&
      campaign?.creator === account?.toLowerCase?.() &&
      campaign.active &&
      campaign.funded
    );
  };

  const showExtendButton = () => {
    return (
      account &&
      campaign?.creator === account?.toLowerCase?.() &&
      !campaign.funded &&
      campaign.active &&
      moment.utc().add(7, 'days') > moment.utc(campaign?.planEnd)
    );
  };

  const showTerminateButton = () => {
    return (
      account &&
      !campaign.terminated &&
      (campaign?.creator === account?.toLowerCase?.() || isAdmin) &&
      !campaign.closed
    );
  };

  let url = `http://localhost:3000/campaign/${campaign.id}`;

  return (
    <>
      <ScrollToTop>
        <Helmet>
          <title>I WILL FUND | {content.campTopTitle}</title>
          <meta name={content.description} content={content.metaContent} />
          <meta name='theme-color' content='#191970' />
        </Helmet>
        <SingleContainer>
          {campaign?.terminated && (
            <CampaignTerminatedMessage>
              <CampaignTerminatedMessageText>
                {content.noLongerActive}
              </CampaignTerminatedMessageText>
            </CampaignTerminatedMessage>
          )}
          <SingleLeft>
            <ImageHolder>
              <img src={campaign?.image} alt={campaign?.title} />
            </ImageHolder>

            <SingleTitle>{campaign?.title}</SingleTitle>
            {typeof campaign?.description === 'string' && (
              <SingleDescription
                dangerouslySetInnerHTML={{ __html: desc }}
              ></SingleDescription>
            )}
            {/* <div id='editorviewjs'></div> */}
            <ReactEditorJS
              language={language}
              onInitialize={handleInitialize}
              readOnly={true}
              tools={tools}
              value={description}
            />
            {campaign?.creator === account?.toLowerCase?.() && (
              <EditButtonHolder>
                <Button onClick={handleEdit} type='button'>
                  {content.edit}{' '}
                  <EditIcon
                    fontSize='inherit'
                    style={{ marginLeft: '5px', marginBottom: '3px' }}
                  />
                </Button>
              </EditButtonHolder>
            )}
          </SingleLeft>
          <SingleRight>
            <h3 style={{ marginBottom: '-8px' }}>{content.campaignAddress}</h3>
            <CampaignAddressHolder>
              {campaign?.contractAddress ? (
                <>
                  <a
                    style={{ color: 'white', textDecoration: 'none' }}
                    href={
                      explorer[network] +
                      '/address/' +
                      campaign?.contractAddress
                    }
                    target='_blank'
                  >
                    {campaign?.contractAddress}
                  </a>
                </>
              ) : (
                fakeAddress
              )}
              <CopyButtonHolder>
                <Button onClick={copyToClipboard}>
                  <ContentCopyIcon
                    style={{
                      color: '#888',
                      position: 'absolute',
                      top: '0',
                      right: '0',
                    }}
                  />
                </Button>
              </CopyButtonHolder>
            </CampaignAddressHolder>
            {campaign.type !== 1 && (
              <>
                <Progress
                  done={percent > 100 ? '100' : percent}
                  progressColor={progressColor}
                />
              </>
            )}

            <div>
              <p style={{ fontSize: '1rem' }}>
                {campaign.type === 1 ? (
                  <>
                    <strong>Total Funded: {campaign?.totalFunded} BNB</strong>
                  </>
                ) : (
                  <strong>
                    {campaign?.totalFunded} BNB / {campaign?.tokensNeeded} BNB
                  </strong>
                )}
              </p>
            </div>
            <p>
              {content.category}{' '}
              <span className='single-cat'>{campaign?.category}</span>
            </p>
            <p>
              {content.start}:{' '}
              {campaign?.planStart
                ? campaign?.planStart.format('MM/DD/YYYY')
                : ''}{' '}
              <br />
              {campaign?.type === 0 && (
                <>
                  {content.end}: {campaign?.planEnd.format('MM/DD/YYYY')} <br />
                  {campaign?.closed ? (
                    <>{content.closed}</>
                  ) : (
                    <>{content.closes}</>
                  )}{' '}
                  {campaign?.planEnd.locale(language).fromNow()}
                </>
              )}
            </p>
            {campaign?.comingSoon ? (
              <>
                <div
                  style={{
                    textTransform: 'uppercase',
                    color: '#fff',
                    border: 'dashed 1px darkorange',
                    padding: '1px 10px',
                    borderRadius: '8px',
                    letterSpacing: '1.1px',
                  }}
                >
                  <h3>{content.comeSoon}</h3>
                </div>
              </>
            ) : (
              <DialogBox
                language={language}
                disabled={disable}
                campaign={campaign}
                buttonText={
                  campaign?.closed ? (
                    <>{content.campaignClosed}</>
                  ) : (
                    'IWILL.FUND!'
                  )
                }
                style={{
                  color: '#fff',
                  background: { disableColor },
                  fontSize: '1.5rem',
                  margin: '1.2rem 0',
                  borderRadius: '8px',
                  paddingLeft: '2rem',
                  paddingRight: '2rem',
                  pointerEvents: { cursorOff },
                }}
              />
            )}
            <br />
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='space-around'
              alignItems='center'
              flexWrap='wrap'
            >
              {showEarlyButton() && (
                <div>
                  <CampaignButtonCloseEarlyDialog
                    language={language}
                    campaign={campaign}
                    onSuccess={onSuccess}
                  />
                </div>
              )}
              {showExtendButton() && (
                <div>
                  <CampaignButtonExtendDialog
                    language={language}
                    campaign={campaign}
                    onSuccess={onSuccess}
                  />
                </div>
              )}
              {showTerminateButton() && (
                <div>
                  <CampaignButtonTerminateDialog
                    language={language}
                    campaign={campaign}
                    onSuccess={onSuccess}
                  />
                </div>
              )}
            </Box>

            <h4>{content.aboutAuthor}:</h4>

            <div style={{ textAlign: 'center' }}>
              <Jazzicon
                diameter={50}
                seed={jsNumberForAddress(campaign?.creator || fakeAddress)}
              />
              <span>
                <br />
                {shortenAddress(campaign?.creator || fakeAddress)}
              </span>
            </div>
            {/* <SocialDiv> */}
            <h4>{content.share}</h4>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <FacebookIcon
                url={url}
                round={true}
                size={42}
                style={{ marginRight: '5px' }}
              />
              <LinkedinIcon
                url={url}
                round={true}
                size={42}
                style={{ marginRight: '5px' }}
              />
              <TwitterIcon url={url} round={true} size={42} />
            </div>

            {donators?.length > 0 && (
              <>
                <h4>{content.recentDonations}</h4>
              </>
            )}
            {donators.map(
              (donate, index) =>
                index < 5 && (
                  <div key={index} style={{ textAlign: 'center' }}>
                    <DonationContainer>
                      <BoxLeft>
                        <Jazzicon diameter={50} seed={donate.jazzicon} />
                      </BoxLeft>
                      <BoxRight>
                        <p>{donate.address} </p>
                        <p>
                          BNB: <br />
                          <br />
                          <br />
                          {donate.amount}
                          <br />
                          <br />
                          <span
                            style={{ fontSize: '.6rem', marginTop: '.5rem' }}
                          >
                            {moment
                              .unix(donate.timestamp)
                              .locale(language)
                              .fromNow()}
                          </span>
                          <br />
                        </p>
                      </BoxRight>
                    </DonationContainer>
                    <hr />
                  </div>
                )
            )}
          </SingleRight>
        </SingleContainer>
      </ScrollToTop>
    </>
  );
};

const Progress = ({ done, progressColor }) => {
  const [style, setStyle] = React.useState({});

  const newStyle = {
    opacity: 1,
    width: `${done}%`,
    background: `${progressColor}`,
  };

  useEffect(() => {
    setStyle(newStyle);
  }, [done, progressColor]);

  return (
    <>
      <ProgressWrapper>
        <ProgressDone style={style}></ProgressDone>
      </ProgressWrapper>
    </>
  );
};

export default Campaign;
