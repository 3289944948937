import styled from 'styled-components';

export const LeftPanel = styled.div`
  overflow: hidden;
  background-color: #444;
  color: #fff;
  padding: 1rem 1.5rem 3rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  @media only screen and (max-width: 959px) {
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;
  }
`;

export const RightPanel = styled.div`
  padding: 2rem 2rem 3rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  background: #333;
  @media only screen and (max-width: 750px) {
    flex-direction: column;
  }
`;

export const SearchContainer = styled.div`
  position: relative;
`;

export const SearchForm = styled.form``;

export const FormHeader = styled.h1`
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
`;

export const FormInput = styled.input`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0.5rem;
  width: 100%;
  font-size: 1rem;
  color: #333;
  margin-bottom: 15px;
  &[type='date'] {
    font-family: 'Roboto', sans-serif;
    color: #333;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
`;

export const MinMaxTokenInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
`;

export const SearchButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  margin-top: 30px;
  @media (max-width: 950px) {
    margin-top: 1rem;
  }
`;

export const CatSelect = styled.select`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0.5rem;
  width: 95%;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  color: #333;
  margin-bottom: 15px;
  width: 100%;
  @media (max-width: 950px) {
    width: 52%;
    margin-right: 0.5rem;
  }
  @media (max-width: 470px) {
    width: 100%;
  }
`;

export const StateSelect = styled.select`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0.5rem;
  width: 95%;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  color: #333;
  margin-bottom: 15px;
  width: 100%;
  @media (max-width: 950px) {
    width: 46%;
  }
  @media (max-width: 470px) {
    width: 100%;
  }
`;

export const StickySearch = styled.div``;

export const EndlessScroll = styled.div`
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
