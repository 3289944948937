import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Container from '@material-ui/core/Container';
import CalcSliderComponent from '../CampaignCalculator/CalcSliderComponent';
import Grid from '@material-ui/core/Grid';
import Switch from 'react-switch';
import { usePriceApi } from '../../contexts/priceApi';
import Coin from '../../assets/bnb.png';
import Dollar from '../../assets/dollar.png';
import {
  Form,
  AmountInput,
  CalcText,
  CalcHeader,
  HrStyle,
} from '../CampaignCalculator/CalculatorStyles';
import { useDApp } from '../../contexts/web3';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useLanguage } from '../../contexts/LanguageContext';

const CalculatorDialog = ({ setSelectedBnbValue, setToggle, title }) => {
  const [sliderValue, setSliderValue] = useState(0);
  const { price } = usePriceApi();
  const [dollarValue, setDollarValue] = useState('');
  const [bnbValue, setBnbValue] = useState('');
  const [totalDollarAmount, setTotalDollarAmount] = useState('');
  const [totalBnbAmount, setTotalBnbAmount] = useState('');
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [disableColor, setDisableColor] = useState('#e0e0e0');
  const [display, setDisplay] = useState('none');
  const { web3, account, walletState, campaignFactory, connect } = useDApp();
  const [gasFee, setGasFee] = useState(0);
  const { language, getLanguageContent } = useLanguage();
  const [content, setContent] = useState(getLanguageContent('CalcDialogTrans'));

  useEffect(() => {
    setContent(getLanguageContent('CalcDialogTrans'));
  }, [language]);

  const onApplyBnb = () => {
    if (parseInt(dollarValue) > 99) {
      console.log(+dollarValue);
      setSelectedBnbValue(+totalBnbAmount);
      setToggle(checked);
      setDisplay('none');
      enableFunc();
      handleClose();
    } else {
      setDisplay('block');
      setOpen(true);
      setError(errorMsg);
      disableFunc();
    }
  };

  const valueHandler = (v) => {
    setSliderValue(v);
  };

  useEffect(() => {
    NormalTaxAndFees();
  }, [bnbValue, sliderValue, checked, price]);

  const NormalTaxAndFees = () => {
    const bnb = +bnbValue || 0;
    // console.log(+price);
    // console.log(bnb, sliderValue, checked);
    if (bnb !== 0) {
      // const threePercent = bnb * 0.03;
      const govPercent = +sliderValue / 100;
      let serviceFee = 0.05;
      if (checked) {
        serviceFee += 0.03;
      }
      const gov = govPercent * bnb;
      const service = serviceFee * bnb;
      const totalBnb = bnb + gov + service + gasFee;
      // console.log(`[BNB] ${bnb} - [GOV] ${gov} - [SERVICE] ${service.toFixed(8)} - [GAS] ${gasFee} - [TOTAL] ${totalBnb}`);
      const totalDollar = totalBnb * +price;
      setTotalDollarAmount(totalDollar.toFixed(2));
      setTotalBnbAmount(totalBnb.toFixed(5));
    } else {
      setTotalDollarAmount('0.00');
      setTotalBnbAmount('0.00000');
    }
  };

  const errorMsg = content.atLeastOneHundred;

  const disableFunc = () => {
    setDisabled(true);
    setDisableColor('#e0e0e0');
  };

  const enableFunc = () => {
    setDisabled(false);
    setDisableColor('');
  };

  const calcGasFee = async () => {
    //generate an estimated cost for the campaign creation
    const gasFee = await campaignFactory.methods
      .createCampaign(
        account,
        web3.utils.toWei('1', 'ether'),
        moment.utc().unix(),
        moment.utc().add(1, 'month').unix(),
        true,
        '1234567890abcdefghijklmnopqrstuvwxyz',
        0
      )
      .estimateGas({ from: account });
    const convFee = gasFee / 10 ** 8;
    // const convFee = web3.utils.fromWei("" + gasFee);
    console.log('GAS FEE', convFee);
    return convFee;
  };

  const handleBnbChange = (e) => {
    const v = +e.target.value;
    // console.log("changing bnb");
    setBnbValue(e.target.value);
    if (v < 0) {
      setDollarValue('');
      setError(errorMsg);
      disableFunc();
    } else if (e.target.value === '') {
      disableFunc();
      setDollarValue('');
      setError('');
    } else {
      setError('');
      setDollarValue((v * price).toFixed(2));
      enableFunc();
    }
  };

  const handleUsdChange = (e) => {
    const v = +e.target.value;
    setDollarValue(e.target.value);
    if (v < 0) {
      setBnbValue('');
      setError(errorMsg);
      disableFunc();
    } else if (e.target.value === '') {
      disableFunc();
      setBnbValue('');
      setError('');
    } else {
      setError('');
      setBnbValue((v / price).toFixed(5));
      enableFunc();
    }
  };

  const handleClickOpen = async () => {
    if (!web3) {
      await connect();
      return false;
    }

    if (walletState !== 'connected') {
      toast.error(content.notConnected);
      return false;
    }

    calcGasFee()
      .then((fee) => setGasFee(fee))
      .catch((e) => console.log(e));

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant='contained'
        style={{
          background: '#f7931a',
          color: '#fff',
          marginTop: '5px',
          marginBottom: '10px',
          borderRadius: '6px',
        }}
        onClick={handleClickOpen}
      >
        {title}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Container>
            <div>
              <Form
                style={{
                  minWidth: '275px',
                  maxWidth: '900px',
                  padding: '5px',
                }}
              >
                <CalcHeader>{content.campcalc}</CalcHeader>
                <CalcText style={{ marginBottom: '2.5rem' }}>
                  {content.calcText}
                </CalcText>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={12} xs={12}>
                    <CalcText>
                      {content.calcText2}
                      <br /> {content.calcText3}
                    </CalcText>
                    <div style={{ textAlign: 'center', marginTop: '15px' }}>
                      <span
                        style={{
                          padding: '5px',
                          color: 'red',
                          fontStyle: 'italic',
                          fontSize: '1rem',
                          textAlign: 'center',
                          marginTop: '10px',
                        }}
                      >
                        {error && error}
                      </span>
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sm={12}
                    xs={12}
                    style={{ marginBottom: '5px' }}
                  >
                    <div style={{ marginBottom: '5px', position: 'relative' }}>
                      <AmountInput
                        onChange={handleUsdChange}
                        type='number'
                        min='0'
                        placeholder={
                          dollarValue < 0 || dollarValue === ''
                            ? `${content.donate} USD`
                            : ''
                        }
                        value={dollarValue}
                      />
                      <img
                        src={Dollar}
                        style={{
                          width: '35px',
                          position: 'absolute',
                          top: '3px',
                          left: '3px',
                        }}
                        alt='coin'
                      />
                    </div>
                    <div
                      style={{
                        position: 'relative',
                        width: '100%',
                      }}
                    >
                      <AmountInput
                        onChange={handleBnbChange}
                        type='number'
                        min='0'
                        placeholder={
                          bnbValue < 0 || bnbValue === '' ? '  BNB Tokens' : ''
                        }
                        value={bnbValue}
                      />
                      <img
                        src={Coin}
                        style={{
                          width: '35px',
                          position: 'absolute',
                          top: '38px',
                          left: '3px',
                        }}
                        alt='coin'
                      />
                    </div>
                  </Grid>
                </Grid>
                {error ? (
                  ''
                ) : (
                  <div
                    style={{
                      width: '100%',
                      margin: '0 auto',
                      textAlign: 'center',
                    }}
                  >
                    <span
                      style={{
                        color: '#333',
                        fontSize: '1rem',
                        fontStyle: 'italic',
                      }}
                    >
                      * {content.campAmountReq}
                    </span>
                  </div>
                )}

                <HrStyle />
                <Grid container spacing={2} style={{ marginBottom: '15px' }}>
                  <Grid item md={6} sm={12} xs={12}>
                    <CalcText>{content.taxText} </CalcText>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <CalcSliderComponent onValueChanged={valueHandler} />
                  </Grid>
                </Grid>
                <HrStyle />
                <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                  <Grid item md={6} sm={12}>
                    <CalcText>{content.serviceFee} 5%</CalcText>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <CalcText>
                      {bnbValue > 0 ? (
                        <span>
                          <span>{(bnbValue * 0.05).toFixed(5)} BNB</span> <br />
                          <span>${(dollarValue * 0.05).toFixed(2)}</span>
                        </span>
                      ) : (
                        ''
                      )}{' '}
                    </CalcText>
                  </Grid>
                </Grid>
                <HrStyle />
                <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                  <Grid item md={6} sm={12} xs={12}>
                    <label>
                      <CalcText>{content.receiveText} 3%</CalcText>
                      <Switch
                        color='warning'
                        type='checkbox'
                        checked={checked}
                        onChange={(e) => setChecked(!checked)}
                        aria-label={'secondary checkbox'}
                      />
                    </label>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <CalcText>
                      {bnbValue > 0 && checked === true ? (
                        <div>
                          <span>{(bnbValue * 0.03).toFixed(5)} BNB</span> <br />
                          <span>${(dollarValue * 0.03).toFixed(2)}</span>
                        </div>
                      ) : (
                        ''
                      )}
                    </CalcText>
                  </Grid>
                </Grid>
                <HrStyle />
                <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                  <Grid item md={6} sm={12}>
                    <CalcText>{content.creationGas}</CalcText>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <CalcText>
                      <div>
                        <span>{gasFee.toFixed(5)} BNB</span> <br />
                        <span>${(gasFee * price).toFixed(2)}</span>
                      </div>
                    </CalcText>
                  </Grid>
                </Grid>
                <CalcText style={{ textAlign: 'center' }}>
                  {content.should} <strong>${totalDollarAmount}</strong>{' '}
                  {content.or} <strong>{totalBnbAmount}</strong> BNB Tokens
                </CalcText>
                <Button
                  variant='contained'
                  color='secondary'
                  style={{
                    background: { disableColor },
                    color: '#fff',
                    fontSize: '1.4rem',
                    width: '100%',
                    marginTop: '10px',
                  }}
                  onClick={onApplyBnb}
                  disabled={disabled}
                >
                  {content.apply}
                </Button>
                <Button
                  variant='contained'
                  style={{
                    background: 'midnightblue',
                    color: '#fff',
                    fontSize: '1.4rem',
                    width: '100%',
                    marginTop: '10px',
                  }}
                  onClick={handleClose}
                >
                  {content.cancel}
                </Button>
              </Form>
            </div>
          </Container>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CalculatorDialog;
